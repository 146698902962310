import { NavLink, Switch, useRouteMatch, Route } from "react-router-dom";
import { useState, useEffect } from 'react'

import styles from "./config.module.css"

import AdministradoresIcon from "../../assets/configuration/Administradores.svg"
import CategoriasIcon from "../../assets/configuration/Categorias.svg"
import ChecklistsIcon from "../../assets/configuration/Checklists.svg"
import SitesIcon from "../../assets/configuration/Sites.svg"
import TiposIcon from "../../assets/configuration/Tipos.svg"
import SectionIcon from "../../assets/configuration/section.png"

import Header from "../../components/Header/header";

import Sites from "./Sites";
import Admins from "./Admins";
import Types from "./Types";
import Categories from "./Categories";
import Checklists from "./Checklists";
import Section from "./Section";

import { api } from '../../services/api'

import { BsFillExclamationTriangleFill } from 'react-icons/bs';


export default function ConfigHome() {

    let { path } = useRouteMatch();
    const [isAdmin, setIsAdmin] = useState(true)
    const [mocs, setMocs] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const identifyResponse = await api.get('identify');
                setIsAdmin(identifyResponse.data.isAdmin);

                const mocsResponse = await api.get('moc');
                setMocs(mocsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {isAdmin ?
                <>
                    <Header />

                    <div style={{ display: "flex" }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.menuTitle}>
                                <span>Configurações</span>
                            </div>

                            <div className={styles.menuNav}>
                                <NavLink exact to='/configurações/admins' className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img className={styles.itemImg} src={AdministradoresIcon}></img>
                                    Administradores
                                </NavLink>

                                <NavLink exact to='/configurações/sites' className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img className={styles.itemImg} src={SitesIcon}></img>
                                    Sites
                                </NavLink>

                                <NavLink exact to='/configurações/categorias' className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img className={styles.itemImg} src={CategoriasIcon}></img>
                                    Categorias
                                </NavLink>

                                <NavLink exact to='/configurações/tipos' className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img className={styles.itemImg} src={TiposIcon}></img>
                                    Tipos
                                </NavLink>

                                <NavLink exact to='/configurações/checklists' className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img className={styles.itemImg} src={ChecklistsIcon}></img>
                                    Checklists
                                </NavLink>
                                <NavLink exact to='/configurações/setores' style={{ paddingLeft: '0.5rem', height: '31px' }} className={styles.itemMenu} activeClassName={styles.selectedItem} >
                                    <img style={{ width: '2rem', height: '2rem', marginRight: '2px' }} className={styles.itemImg} src={SectionIcon}></img>
                                    Setores
                                </NavLink>
                            </div>

                        </div>

                        <div className={styles.sideMain}>
                            <Switch>
                                <Route path={`${path}/admins`} component={Admins} />
                                <Route path={`${path}/sites`} component={() => <Sites mocs={mocs} />} />
                                <Route path={`${path}/categorias`} component={() => <Categories mocs={mocs} />} />
                                <Route path={`${path}/tipos`} component={() => <Types mocs={mocs} />} />
                                <Route path={`${path}/checklists`} component={Checklists} />
                                <Route path={`${path}/setores`} component={Section} />
                            </Switch>
                        </div>
                    </div>
                </> :
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "2rem" }}>
                    <BsFillExclamationTriangleFill color="red" size={30} /> <p style={{ marginLeft: "1rem" }} > Acesso negado</p>
                </div>}
        </>
    )
}