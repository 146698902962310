import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import Label from "../../../components/Label/label"
import NavPages from "../../../components/NavPages/navPages"
import Title from "../../../components/Title/title"

import styles from "../new-moc.module.css"

import Swal from "sweetalert2"

import { SelectPicker } from "rsuite"
import { Checkbox } from "rsuite"
import FileUploader from "../../../components/fileUploader"
import Button from "../../../components/Button"

import { api } from "../../../services/api"

import { useNewMoc } from "../../../providers/new-moc"
import handleValidation from "../../../utils/handleValidation"


export default function NewMoc() {
    const { titulo, setTitulo } = useNewMoc()
    const { solicitante, setSolicitante } = useNewMoc()
    const { data, setData } = useNewMoc()
    const { custo, setCusto } = useNewMoc()
    const { fiscal, setFiscal } = useNewMoc()
    const { selectedSiteName, setSelectedSiteName } = useNewMoc()
    const { selectedCategoriaName, setSelectedCategoriaName } = useNewMoc()
    const { selectedTipoName, setSelectedTipoName } = useNewMoc()
    const { site, setSite } = useNewMoc()
    const { categoria, setCategoria } = useNewMoc()
    const { tipo, setTipo } = useNewMoc()
    const { descricao, setDescricao } = useNewMoc()
    const { anexos, setAnexos } = useNewMoc()
    const { fotos, setFotos } = useNewMoc()
    const { hse, setHse } = useNewMoc(false)

    const [sites, setSites] = useState([])
    const [categorias, setCategorias] = useState([])
    const [tipos, setTipos] = useState([])
    const [name, setName] = useState([])

    /* Errors */

    const [tituloError, setTituloError] = useState('')
    const [dataError, setDataError] = useState('')
    const [custoError, setCustoError] = useState('')
    const [fiscalError, setFiscalError] = useState('')
    const [siteError, setSiteError] = useState('')
    const [categoriaError, setCategoriaError] = useState('')
    const [tipoError, setTipoError] = useState('')
    const [descricaoError, setDescricaoError] = useState('')

    const history = useHistory()

    useEffect(async () => {
        await getData()
        await getIdentify()
    }, [])

    async function getIdentify() { // identifica usuario logado e pega seus dados
        let response = await api.get('identify')
        setSolicitante(response.data.userId)
        setName(response.data.name)
    }


    async function getData() {
        let categoriesResponse = await api.get('/categories')
        let typesResponse = await api.get('/types')
        let sitesResponse = await api.get('/sites')

        let categoriesArray = []
        let sitesArray = []
        let typesArray = []

        sitesResponse.data.map(x => {
            sitesArray.push({ label: x.name, value: x.id })
        })

        typesResponse.data.map(x => {
            typesArray.push({ label: x.name, value: x.id })
        })

        categoriesResponse.data.map(x => {
            categoriesArray.push({ label: x.name, value: x.id })
        })

        setSites(sitesArray)
        setCategorias(categoriesArray)
        setTipos(typesArray)
    }

    function handleCheckChange() {
        setHse(prev=>!prev)
    }

    

    function handleSelectedSite(value) {
        let filteredSites = sites.filter(x => x.value == value)

        setSelectedSiteName(filteredSites[0].label)
        setSite(value)
    }

    function handleSelectedTipo(value) {
        let filteredTipos = tipos.filter(x => x.value == value)

        setSelectedTipoName(filteredTipos[0].label)
        setTipo(value)
    }

    function handleSelectedCategoria(value) {
        let filteredCategorias = categorias.filter(x => x.value == value)

        setSelectedCategoriaName(filteredCategorias[0].label)
        setCategoria(value)
    }

    const handleSubmit = () => {
        const errorStateControls = {
            titulo: [titulo, setTituloError],
            data: [data, setDataError],
            custo: [custo, setCustoError],
            fiscal: [fiscal, setFiscalError],
            site: [site, setSiteError],
            categoria: [categoria, setCategoriaError],
            tipo: [tipo, setTipoError],
            descricao: [descricao, setDescricaoError],
        };
        if(!handleValidation(errorStateControls)){
            return;
        }

        history.push('/novo-moc/checklist');
    }

    
    return (
        <>

            <NavPages title="Novo MOC" />

            <div className="mainDiv">
                <Title>Informações básicas</Title>

                <div className={styles.inputDiv}>
                    <div style={{ width: '100%' }} className="inputContainer">
                        <Label htmlFor="">Título do MOC</Label>
                        <input value={titulo} onChange={(event) => {
                            setTituloError('')
                            setTitulo(event.target.value)
                        }} onBlur={()=>setTitulo(prev=>prev.trim())} type="text" />
                        {tituloError ? (
                            <div className="inputError">{tituloError}</div>
                        ) : null}
                    </div>
                    <div style={{ width: '25%' }} className="inputContainer">
                        <Label htmlFor="">Solicitante</Label>
                        <input disabled value={name} type="text" />

                    </div>
                    <div style={{ width: '25%' }} className="inputContainer">
                        <Label htmlFor="">Data de abertura</Label>
                        <input value={data} onChange={(event) => {
                            setDataError('')
                            setData(event.target.value)
                        }} type="date" />
                        {dataError ? (
                            <div className="inputError">{dataError}</div>
                        ) : null}
                    </div>
                </div>

                <div className={styles.inputDiv}>
                    <div style={{ width: '40%' }} className="inputContainer">
                        <Label htmlFor="">Custo previsto</Label>
                        <input value={custo} onChange={(event) => {
                            setCustoError('')
                            setCusto(event.target.value)
                        }} type="number" />
                        {custoError ? (
                            <div className="inputError">{custoError}</div>
                        ) : null}
                    </div>
                    <div style={{ width: '40%' }} className="inputContainer">
                        <Label htmlFor="">Fiscal year de implementação</Label>
                        <input value={fiscal} onChange={(event) => {
                            setFiscalError('')
                            setFiscal(event.target.value)
                        }} type="number" />
                        {fiscalError ? (
                            <div className="inputError">{fiscalError}</div>
                        ) : null}
                    </div>
                    <div style={{ width: '25%' }} className="inputContainer">
                        <Label htmlFor="">Site</Label>
                        <SelectPicker
                            appearance="default"
                            style={{ width: '100%' }}
                            placeholder="&nbsp;"
                            data={sites}
                            value={site}
                            onChange={(value) => {
                                setSiteError('')
                                handleSelectedSite(value)
                            }}
                        />
                        {siteError ? (
                            <div className="inputError">{siteError}</div>
                        ) : null}

                    </div>
                    <div style={{ width: '25%' }} className="inputContainer">
                        <Label htmlFor="">Categoria</Label>
                        <SelectPicker
                            appearance="default"
                            style={{ width: '100%' }}
                            placeholder="&nbsp;"
                            data={categorias}
                            value={categoria}
                            onChange={(value) => {
                                setCategoriaError('')
                                handleSelectedCategoria(value)
                            }}
                        />
                        {categoriaError ? (
                            <div className="inputError">{categoriaError}</div>
                        ) : null}

                    </div>
                    <div style={{ width: '20%' }} className="inputContainer">
                        <Label htmlFor="">Tipo</Label>
                        <SelectPicker
                            appearance="default"
                            style={{ width: '100%' }}
                            placeholder="&nbsp;"
                            data={tipos}
                            value={tipo}
                            onChange={(value) => {
                                setTipoError('')
                                handleSelectedTipo(value)
                            }}
                        />
                        {tipoError ? (
                            <div className="inputError">{tipoError}</div>
                        ) : null}

                    </div>
                </div>

                <div className="inputContainer" style={{ width: '100%', marginTop: '1%' }} >
                    <Label htmlFor="">Descrição da mudança:</Label>
                    <textarea value={descricao} onChange={(event) => {
                        setDescricaoError('')
                        setDescricao(event.target.value)
                    }} onBlur={()=>setDescricao(prev=>prev.trim())} name="" id="" cols="30" rows="5" />

                    {descricaoError ? (
                        <div className="inputError">{descricaoError}</div>
                    ) : null}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1rem' }}>
                        <Checkbox
                            value={hse}
                            checked={hse}
                            onChange={handleCheckChange}
                        >Desejo apoio do HSE no preenchimento dos checklists</Checkbox>
                    </div>
                </div>

                <div className={styles.attachmentsDiv}>
                    <div className={styles.pdfDiv}>
                        <Label>Anexos</Label>
                        <FileUploader
                            type="pdf"
                            isOptional={true}
                            addedFiles={anexos}
                            setAddedFiles={setAnexos}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1%' }}>
                    <Button onClick={handleSubmit}>Próximo</Button>
                </div>

            </div>
        </>
    )
}
