import { Route, Switch, useRouteMatch } from "react-router-dom";
import Header from "../../components/Header/header";
import ChecklistAnswers from "./checklist-answers";
import Checklists from "./checklists-main";


export default function ChecklistsHome() {
    let { path } = useRouteMatch();

    return (
        <>
            <Header />

            <div>
                <Switch>
                    <Route path={`${path}/`} exact component={Checklists} />
                    <Route path={`${path}/responder-checklist`} component={ChecklistAnswers} />
                </Switch>
            </div>


        </>
    )
}