import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { api, addAttachment } from "../../../services/api"
import moment from "moment"

import styles from "../capexApproval/capexApproval.module.css"

import Title from "../../Title/title"
import Table from "rc-table"
import Label from "../../Label/label"
import CapexFileUploader from "../../fileUploaderCapex"
import { AutoComplete } from "rsuite"
import Button from "../../Button"
import Modal from "react-modal"
import AddButton from "../../addButton"

import deleteIcon from '../../../assets/delete.svg'
import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"

export default function CapexEdit({ id }) {

    const [activeCapexApproval, setActiveCapexApproval] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)

    const [items, setItems] = useState([])
    const [capexId, setCapexId] = useState('')

    const [logs, setLogs] = useState(false)

    const [description, setDescription] = useState('')
    const [cost, setCost] = useState('')
    const [capexApprover, setCapexApprover] = useState(null)
    const [comments, setComments] = useState('')
    const [attachments, setAttachments] = useState(null)

    const [descriptionError, setDescriptionError] = useState('')
    const [costError, setCostError] = useState('')
    const [capexApproverError, setCapexApproverError] = useState('')
    const [commentsError, setCommentsError] = useState('')
    const [attachmentsError, setAttachmentsError] = useState('')
    const [addedItemsError, setAddedItemsError] = useState('')

    const [addedFiles, setAddedFiles] = useState([])
    const [previousAddedFiles, setPreviousAddedFiles] = useState([])

    const [addedItems, setAddedItems] = useState([])
    const [previousAddedItems, setPreviousAddedItems] = useState([])

    const [users, setUsers] = useState([])
    const [userData, setUserData] = useState([])
    const [capexApproverId, setCapexApproverId] = useState('')


    const [moc, setMoc] = useState({})

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(async () => {
        await getData()
        await getUsersData()
    }, [])

    async function getData() {
        let responseMoc = await api.get(`moc/${id}`)
        setMoc(responseMoc.data)

        let responseApprovals = await api.get(`capex/${id}`)

        setComments(responseApprovals.data[0].commentary)
        setCapexId(responseApprovals.data[0].id)
        setCapexApproverId(responseApprovals.data[0].Users.id)
        setCapexApprover(responseApprovals.data[0].Users.name)

        let responseAddedAttatchments = await api.get(`/capexAttactments/${responseApprovals.data[0].id}`)
        setPreviousAddedFiles(responseAddedAttatchments.data)
        setAddedFiles(responseAddedAttatchments.data)

        let responseDescriptionsCapex = await api.get(`capex/descriptions/${responseApprovals.data[0].id}`)
        let itemsArray = []
        responseDescriptionsCapex.data.map(x => {
            itemsArray.push({ id: x.id, description: x.description, cost: x.expense })
        })
        setAddedItems(itemsArray)
        setPreviousAddedItems(itemsArray)
    }

    async function getUsersData() {
        let responseUsers = await api.get('users')

        setUserData(responseUsers.data)

        let usersArray = []
        responseUsers.data.map(user => {
            usersArray.push({ label: user.name, value: user.name })
        })

        setUsers(usersArray)
    }

    function totalCost() {
        let totalCost = 0
        addedItems.map(item => {
            totalCost = totalCost + Number(item.cost)
        })
        return totalCost
    }


    function handleCapexApprover(value) {
        let filtered = userData.filter(x => x.name == value)

        if (filtered.length != 0) {
            setCapexApproverId(filtered[0].id)
        }

        setCapexApprover(value)
    }

    function validationItem() {
        let validation = true

        if (description.trim() == '') {
            setDescriptionError('Digite uma descrição')
            validation = false
        }

        if (cost.trim() == '') {
            setCostError('Digite um custo')
            validation = false
        }
        return validation
    }

    function addItem() {
        if (validationItem()) {
            let addedItemsArray = [...addedItems]

            addedItemsArray.push({ description: description, cost: cost })

            setAddedItems(addedItemsArray)
            setAddedItemsError('')
            setDescription('')
            setCost('')
        }
    }

    function removeItem(el) {
        let newAddedItems = addedItems.filter(x => x.description != el)
        setAddedItems(newAddedItems)
    }

    const columns = [
        {
            title: "Descrição",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 550,
            render: (row) => (<div>{row.description}</div>)
        },
        {
            title: "Custo",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 180,
            render: (row) => (<div>{`R$ ${row.cost}`}</div>)
        },
        {
            title: "",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 5,
            render: (row) => (<div onClick={() => removeItem(row.description)} style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}><img style={{ display: 'block', width: '1.2rem', height: '1.2rem' }} src={deleteIcon} alt='' /></div>)
        },

    ]

    function validation() {
        let validation = true

        if (addedItems.length == 0) {
            setAddedItemsError('Adicione pelo menos um item')
            validation = false
        }

        if (comments.trim() == '') {
            setCommentsError('Digite um comentário')
            validation = false
        }

        if (capexApprover == null) {
            setCapexApproverError('Selecione um aprovador Capex')
            validation = false
        }

        return validation
    }

    async function submitCapex() {

        if (validation()) {
            try {
                let response = await api.put(`capex/${capexId}`, {
                    "mocId": id,
                    "capexApprover": capexApproverId,
                    "commentary": comments
                })

                if (response.status == 200) {
                    previousAddedItems.map(async previousItem => {
                        if (addedItems.filter(x => x.id == previousItem.id).length == 0) {
                            let responseDelete = await api.delete(`capexdescriptions/${previousItem.id}`)
                        }
                    })

                    addedItems.map(async (item) => {
                        if (previousAddedItems.filter(x => x.id == item.id).length == 0) {
                            let responseAdd = await api.post('capexdescriptions', {
                                "capexId": capexId,
                                "expense": item.cost,
                                "description": item.description
                            })
                        }
                    })


                    previousAddedFiles.map(async previousFile => {
                        if (addedFiles.filter(x => x.id == previousFile.id).length == 0) {
                            let responseDelete = await api.delete(`capexAttactments/${previousFile.id}`)
                        }
                    })

                    addedFiles.map(async (file) => {
                        if (previousAddedFiles.filter(x => x.id == file.id).length == 0) {
                            let responseUpload = await addAttachment('capexAttactments/upload', {
                                "capexId": capexId,
                                "file": file
                            })
                        }
                    })

                    Swal.fire({
                        title: 'Capex criado com sucesso!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: "#009EBE",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/";
                        }
                    })
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: `${error.response.data.errors[0].msg}`
                })
            }
        }
    }

    // modal logs

    async function getModalData() {
        let response = await api.get(`/capex/logs/${capexId}`)

        let logsArray = []
        response.data.map(x => {
            logsArray.push({ action: x.action, aprovador: x.Users.name, createdAt: x.createdAt, justification: x.justification })
        })

        setLogs(logsArray)
    }

    async function handleOpenModal() {
        setModalOpen(true)
        await getModalData()
    }

    function handleCloseModal() {
        setModalOpen(false)
    }

    const columnsLog = [
        {
            title: "Aprovador:",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 250,
            render: (row) => (<div>{row.aprovador}</div>)
        },
        {
            title: "Avaliação:",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.action}</div>)
        },
        {
            title: "Data:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{moment(row.createdAt).format("DD/MM/YYYY")}</div>)
        },
        {
            title: "Justificativa:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.justification}</div>)
        },
    ]

    return (
        <>
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Editar aprovação de CAPEX</Title>
                    <div onClick={() => setActiveCapexApproval(prevActiveCapexApproval => !prevActiveCapexApproval)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {activeCapexApproval == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>
                {activeCapexApproval ? (


                    <div className={styles.enableDiv} style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                                    <div className="flex">
                                        <div style={{ width: '100%' }} className="inputContainer">
                                            <Label htmlFor="">Descrição:</Label>
                                            <input type="text"
                                                value={description}
                                                onChange={(event) => {
                                                    setDescriptionError('')
                                                    setDescription(event.target.value)
                                                }}
                                            />
                                            {descriptionError ?
                                                <div className="inputError">{descriptionError}</div>
                                                : null}
                                        </div>
                                        <div style={{ width: '40%' }} className="inputContainer">
                                            <Label htmlFor="">Custo:</Label>
                                            <div className="flex">
                                                <input type="number"
                                                    value={cost}
                                                    onChange={(event) => {
                                                        setCostError('')
                                                        setCost(event.target.value)
                                                    }}
                                                />
                                                <AddButton onClick={() => addItem()}></AddButton>
                                            </div >
                                            {costError ?
                                                <div className="inputError">{costError}</div>
                                                : null}
                                        </div>
                                    </div>

                                    {addedItemsError ?
                                        <div className="inputError">{addedItemsError}</div>
                                        : null}

                                    {
                                        addedItems.length != 0 ?
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                                                <Table
                                                    data={addedItems}
                                                    sticky={true}
                                                    columns={columns}
                                                    showHeader={false}
                                                    emptyText="Nenhuma ação disponível"
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6rem' }}>
                                                    <div className={styles.tableRow}>
                                                        <div style={{ width: '100%' }}>Total:</div>
                                                        <div style={{ width: '38%' }}>R$ {totalCost()}</div>
                                                    </div>
                                                    <div className={styles.tableRow}>
                                                        <div style={{ width: '100%' }}>Estimativa inicial:</div>
                                                        <div style={{ width: '38%' }}>R$ {moc.initialBudget}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                            null
                                    }
                                </div>

                                <div style={{ width: '100%' }} className="inputContainer">
                                    <Label htmlFor="">Comentários:</Label>
                                    <textarea
                                        value={comments}
                                        onChange={(event) => {
                                            setCommentsError('')
                                            setComments(event.target.value)
                                        }}
                                        rows='6'
                                    />
                                    {commentsError ?
                                        <div className="inputError">{commentsError}</div>
                                        : null}
                                </div>

                                <div style={{ width: '100%' }} className="inputContainer">
                                    <Label htmlFor="">Anexos:</Label>
                                    <CapexFileUploader
                                        addedFiles={addedFiles}
                                        setAddedFiles={setAddedFiles}
                                    />
                                </div>

                                <div style={{ width: '100%' }} className="inputContainer">
                                    <Label htmlFor="">Aprovador CAPEX:</Label>
                                    <AutoComplete
                                        data={users}
                                        value={capexApprover}
                                        onChange={(value) => {
                                            setCapexApproverError('')
                                            handleCapexApprover(value)
                                        }}
                                    />
                                    {capexApproverError ?
                                        <div className="inputError">{capexApproverError}</div>
                                        : null}
                                </div>



                                <div className={styles.enableDiv}>
                                    <Button
                                        onClick={async () => await handleOpenModal()}
                                        titulo="Nova ação"
                                    >Logs do CAPEX</Button>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => submitCapex()}>Submeter</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                )
                    :
                    null}
                <Modal isOpen={modalOpen} onRequestClose={handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    ariaHideApp={false}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(44, 42, 42, 0.707)'
                        },
                        content: {
                            position: 'absolute',
                            width: '50rem',
                            height: '25rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            border: 'none',
                            background: '#fff',
                            overflow: 'auto',
                            borderRadius: 'none',
                            WebkitOverflowScrolling: 'touch',
                            outline: 'none',
                            padding: 'none',
                        }
                    }}>
                    <div className="headerModal">
                        <h3>Logs de aprovações de CAPEX </h3>
                        <h2 onClick={handleCloseModal}></h2>
                    </div>


                    <div style={{ padding: '2rem 2rem 0 2rem' }}>
                        <Table
                            sticky={true}
                            data={logs}
                            columns={columnsLog}
                            showHeader={true}
                            emptyText="Nenhum log disponível"
                        />
                    </div>
                    <div style={{ position: 'absolute', bottom: '1rem', right: '2rem' }}>
                        <Button onClick={() => handleCloseModal()}>Fechar</Button>
                    </div>
                </Modal>
            </div>
        </>
    )
}