import React from "react";

import style from "./title.module.css";


export default function Title (props) {

    return (
        <strong className={style.title} {...props}></strong>
    )
}