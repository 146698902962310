import React from "react"

import styles from './styles/styles.css'
import table from './styles/table.css'

import 'rsuite/dist/rsuite.min.css'

import Home from "./pages/home/home";
import Routes from "./routes";


export default function App() {
  return (
      <Routes/>
  );
}
