import Table from "rc-table";
import { useEffect, useState } from "react";
import { Checkbox, Radio, RadioGroup, SelectPicker } from "rsuite";
import Label from "../../../components/Label/label";
import NavPages from "../../../components/NavPages/navPages";
import Title from "../../../components/Title/title";

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"

import styles from "./approve-moc.module.css"
import Button from "../../../components/Button";
import { useLocation } from "react-router-dom";
import { api } from "../../../services/api";
import moment from "moment";
import Swal from "sweetalert2";
import Steps from "../../../components/steps";
import CapexApproval from "../../capex/capex-approval/index";
import PDFButton from "../../../components/PDFButton";
import { FaTimesCircle, FaFilePdf, FaFileExcel } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip';
import ViewPic from '../../../components/viewPic'
import { useEditMoc } from "../../../providers/edit-moc";
import exportToPDF from "../../../utils/exportToPdf";
import LoadingContent from "../../../components/loadingContent/loadingContent";
import { printPage } from "../../../utils/pdfExport";

export default function ApproveMoc() {
    const [activeBasic, setActiveBasic] = useState(false)
    const [activeComplement, setActiveComplement] = useState(false)

    const [titulo, setTitulo] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [data, setData] = useState('')
    const [custo, setCusto] = useState('')
    const [fiscal, setFiscal] = useState('')
    const [site, setSite] = useState('')
    const [categoria, setCategoria] = useState('')
    const [tipo, setTipo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [isApproved, setIsApproved] = useState('')
    const [needCapexApproval, setNeedCapexApproval] = useState('')
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const [hse, setHse] = useState(false)

    const [check1, setCheck1] = useState('')
    const [check2, setCheck2] = useState('')
    const [check3, setCheck3] = useState('')

    const [aprovar, setAprovar] = useState(true)
    const [justificativa, setJustificativa] = useState('')
    const [modeloMoc, setModeloMoc] = useState('')
    const [participar, setParticipar] = useState(false)

    const { setUploadFotos } = useEditMoc()
    const { setIsOpenModalPic } = useEditMoc()

    const [disableDivsOpacity, setDisableDivsOpacity] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    /* Errors */

    const [modeloError, setModeloError] = useState('')
    const [justificativaError, setJustificativaError] = useState('')

    const location = useLocation()

    const id = location.state.mocId

    const title = location.state.title

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(async () => {
        await getData()
        setIsOpenModalPic(false)
    }, [])

    async function getData() {
        try {
            setIsLoading(true)

            let responseBasic = await api.get(`/moc/${id}`)

            let responseAttachments = await api.get(`mocAttactments/${id}`)

            let fotos = []
            let anexos = []

            responseAttachments.data.map(attachment => {
                if (attachment.type == 'image/png' || attachment.type == 'image/jpeg') {
                    fotos.push(attachment)
                } else {
                    anexos.push(attachment)
                }
            })



            setFotos(fotos)
            setAnexos(anexos)

            setTitulo(responseBasic.data.title)
            setData(moment(responseBasic.data.openDate).format('YYYY-MM-DD'))
            setCusto(responseBasic.data.initialBudget)
            setFiscal(responseBasic.data.fiscalYear)
            setHse(responseBasic.data.hseSupport)
            setDescricao(responseBasic.data.description)
            setSite(responseBasic.data.Sites.name)
            setCategoria(responseBasic.data.Categories.name)
            setTipo(responseBasic.data.Types.name)
            setModeloMoc(responseBasic.data.modelMoc)
            setNeedCapexApproval(responseBasic.data.needCapexApproval)
            setIsApproved(responseBasic.data.isApproved)

            let responseChecklist = await api.get(`/supportChecklist/${id}`)

            setCheck1(responseChecklist.data.questionOne)
            setCheck2(responseChecklist.data.questionTwo)
            setCheck3(responseChecklist.data.questionThree)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }

    }

    function handleRadioChange(value) {
        setAprovar(value)
    }

    function handleCheckChange(e) {
        if (participar == false) {
            setParticipar(true)
        } else {
            setParticipar(false)
        }
    }
    function validation() {
        let isValid = true

        if (!modeloMoc) {
            setModeloError('Selecione um modelo')
            isValid = false
        }

        if (!aprovar && !justificativa.length) {
            setJustificativaError('Digite uma justificativa/comentário')
            isValid = false
        }

        return isValid
    }

    async function handleSubmit() {
        try {
            if (validation()) {
                const response = await api.post('/approvals',
                    {
                        "mocId": id,
                        "isApproved": aprovar,
                        "support": participar,
                        "modelMoc": modeloMoc,
                        "justification": justificativa
                    }
                )

                if (response.status == 200) {
                    Swal.fire({
                        text: "Avaliação do checklist de pré-moc concluída com sucesso",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#009EBE",
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/minhas-aprovações";
                        }
                    })
                }
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data.errors[0].msg}`
            })
        }

    }

    function openImg(photo) {
        if(!photo){
            return;
        }
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }


    const selectData = [
        { label: 'Completo', value: "Completo" },
        { label: 'Simplificado', value: "Simplificado" }
    ]

    const columnsAttachments = [
        {
            title: "Anexos",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<a href={row}>{row}</a>)
        }
    ]

    async function startExporting() {
        setActiveBasic(true)
        setActiveComplement(true)
        setDisableDivsOpacity(true)
        await Swal.fire({
            text: 'O carregamento do arquivo pode demorar alguns minutos.',
            icon: 'info',
            confirmButtonColor: "#009EBE",
            confirmButtonText: 'Gerar PDF',
            allowOutsideClick: false,
            didClose: () => {window.print()}
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        setDisableDivsOpacity(false)
    }
    
    return (
        <>
            {isApproved == true && needCapexApproval == true ? (
                <div id="PDFExport">
                    <CapexApproval
                        id={id}
                        title={title}
                        readOnly={false}
                    />
                </div>) :
                <>
                    <Steps
                        id={id}
                    />

                    <NavPages title="Minhas Aprovações" secondTitle="Aprovar MOC" />


                    <LoadingContent isLoading={isLoading}>
                        <div className="mainDiv">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1%' }}>
                                <Button onClick={startExporting}>
                                    Exportar para PDF</Button>
                            </div>

                            <div id="PDFExport">
                                <div className={styles.basicInfoDiv}>

                                    <div className={styles.basicInfoTitle}>
                                        <Title>Informações básicas</Title>

                                        <div onClick={() => setActiveBasic(prevActiveBasic => !prevActiveBasic)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            {activeBasic == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                        </div>
                                    </div>

                                    {activeBasic ?
                                        <div className={`${styles.basicInfo} ${disableDivsOpacity ? '' : styles.opacity}`}>
                                            <div className={styles.inputDiv}>
                                                <div style={{ width: '100%' }} className="inputContainer">
                                                    <Label htmlFor="">Título do MOC</Label>
                                                    <input value={titulo} disabled type="text" />
                                                </div>
                                                <div style={{ width: '25%' }} className="inputContainer">
                                                    <Label htmlFor="">Solicitante</Label>
                                                    <input disabled type="text" />
                                                </div>
                                                <div style={{ width: '25%' }} className="inputContainer">
                                                    <Label htmlFor="">Data de abertura</Label>
                                                    <input value={data} disabled type="date" />
                                                </div>
                                            </div>

                                            <div className={styles.inputDiv}>
                                                <div style={{ width: '40%' }} className="inputContainer">
                                                    <Label htmlFor="">Custo previsto</Label>
                                                    <input value={custo} disabled type="text" />
                                                </div>
                                                <div style={{ width: '40%' }} className="inputContainer">
                                                    <Label htmlFor="">Fiscal year de implementação</Label>
                                                    <input value={fiscal} disabled type="text" />
                                                </div>
                                                <div style={{ width: '25%' }} className="inputContainer">
                                                    <Label htmlFor="">Site</Label>
                                                    <SelectPicker
                                                        readOnly
                                                        appearance="default"
                                                        style={{ width: '100%' }}
                                                        placeholder={site}
                                                        value={site}
                                                    />

                                                </div>
                                                <div style={{ width: '25%' }} className="inputContainer">
                                                    <Label htmlFor="">Categoria</Label>
                                                    <SelectPicker
                                                        readOnly
                                                        appearance="default"
                                                        style={{ width: '100%' }}
                                                        placeholder={categoria}
                                                    />

                                                </div>
                                                <div style={{ width: '20%' }} className="inputContainer">
                                                    <Label htmlFor="">Tipo</Label>
                                                    <SelectPicker
                                                        readOnly
                                                        appearance="default"
                                                        style={{ width: '100%' }}
                                                        placeholder={tipo}

                                                    />
                                                </div>
                                            </div>

                                            <div className="inputContainer" style={{ width: '100%', marginTop: '1%' }} >
                                                <Label htmlFor="">Descrição da mudança:</Label>
                                                <textarea value={descricao} disabled name="" id="" cols="30" rows="5"></textarea>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1rem' }}>
                                                    <Checkbox disabled >Desejo apoio do HSE no preenchimento dos checklists</Checkbox>
                                                </div>
                                            </div>

                                            <div className={styles.attachmentsDiv}>
                                                {anexos.length != 0 || fotos.length != 0 ?
                                                    <>
                                                        <div className={styles.attachmentsDiv}>
                                                            {anexos.length != 0 ?
                                                                <>
                                                                    <div className={styles.imgHeader}>
                                                                        <span>Anexos</span>
                                                                    </div>
                                                                    <div className={styles.imgDiv}>
                                                                        {anexos.map(x => {
                                                                            return (
                                                                                <div className={styles.imgContainer}>
                                                                                    {x.type.split('/')[1] == 'pdf' ?
                                                                                        <a href={x.link}><FaFilePdf data-tip data-for={`pdf${x.id}`} color='#000' size={70} />
                                                                                            <ReactTooltip place="top" effect="solid" id={`pdf${x.id}`}>
                                                                                                <span>{x.name}</span>

                                                                                            </ReactTooltip>
                                                                                        </a> :
                                                                                        <a href={x.link}>
                                                                                            <FaFileExcel data-tip data-for={`excel${x.id}`} color='#000' size={70} />
                                                                                            <ReactTooltip place="top" effect="solid" id={`excel${x.id}`}>
                                                                                                <span>{x.name}</span>
                                                                                            </ReactTooltip>
                                                                                        </a>}
                                                                                </div>
                                                                            )

                                                                        })
                                                                        }
                                                                    </div>
                                                                </> :
                                                                <div className={styles.pdfDiv}>
                                                                    <Table
                                                                        sticky={true}
                                                                        showHeader={false}
                                                                        emptyText="Nenhum anexo disponível"
                                                                    />
                                                                </div>}

                                                            <div className={styles.imgHeader}>
                                                                <span>Imagens</span>
                                                            </div>
                                                            {fotos.length != 0 ?
                                                                <div className={styles.imgDiv}>
                                                                    {fotos.map(x => {
                                                                        return (
                                                                            <div className={styles.imgContainer}>
                                                                                <img className={styles.img} src={x.link} alt="" onClick={() => openImg(x.link)} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div> :
                                                                <div className={styles.pdfDiv}>
                                                                    <Table
                                                                        sticky={true}
                                                                        showHeader={false}
                                                                        emptyText="Nenhum anexo disponível"
                                                                    />
                                                                </div>}
                                                        </div>
                                                    </>
                                                    :
                                                    <div className={styles.pdfDiv}>
                                                        <Table
                                                            sticky={true}
                                                            columns={columnsAttachments}
                                                            showHeader={true}
                                                            emptyText="Nenhum anexo disponível"
                                                        />
                                                    </div>}
                                                <ViewPic />
                                            </div>
                                        </div>

                                        : null
                                    }
                                </div>

                                <div style={{ marginTop: '1%' }} className={styles.basicInfoDiv}>
                                    <div className={styles.basicInfoTitle}>
                                        <Title>Informações complementares - Checklist de pré-moc</Title>

                                        <div onClick={() => setActiveComplement(prevActiveComplement => !prevActiveComplement)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            {activeComplement == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                        </div>
                                    </div>

                                    {activeComplement ?
                                        <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                            <div className={`${styles.questionDiv} ${disableDivsOpacity ? '' : styles.opacity}`}>

                                                <div className={styles.questionBox}>
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}>1)</span> <span>Essa mudança envolve atividades de alto risco relacionadas as Regras que Salvam Vidas ou alterações ou revisões que impactem nas Licenças ou Permits do site?</span>
                                                    </div>

                                                    <RadioGroup name="radioList" inline value={check1}>
                                                        <Radio value={'true'}>Sim</Radio>
                                                        <Radio value={'false'}>Não</Radio>
                                                        <Radio value={'null'}>Não aplicável</Radio>
                                                    </RadioGroup>
                                                </div>

                                                <div className={styles.questionBox}>
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}>2)</span> <span>Serão contratos terceiros ou prestadores de serviço para executar atividades dentro do site que envolvam atividades relacionadas a: LOTO, Segurança com Eletricidade, Trabalho em Altura, Içamento de Cargas, Espaço Confinados, Operações de Máquinas Pesadas, Aplicação de Produtos Químicos, Asbestos (amianto) ou qualquer risco de impacto direto a vida das pessoas?</span>
                                                    </div>

                                                    <RadioGroup name="radioList" inline value={check2}>
                                                        <Radio value={'true'}>Sim</Radio>
                                                        <Radio value={'false'}>Não</Radio>
                                                        <Radio value={'null'}>Não aplicável</Radio>
                                                    </RadioGroup>
                                                </div>

                                                <div className={styles.questionBox}>
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}>3)</span> <span>Após a finalização desta mudança, será introduzido nas atividades operacionais algum alto risco que se relaciona com as Regras que Salvam Vidas?</span>
                                                    </div>

                                                    <RadioGroup name="radioList" inline value={check3}>
                                                        <Radio value={'true'}>Sim</Radio>
                                                        <Radio value={'false'}>Não</Radio>
                                                        <Radio value={'null'}>Não aplicável</Radio>
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </div> : null}
                                </div>

                                <div style={{ marginTop: '1%' }} className={styles.basicInfoDiv}>
                                    <Title>Aprovação do HSE</Title>

                                    <div style={{ width: '35rem' }} className={styles.formDiv}>
                                        <div>
                                            <Label>Aprova a criação do MOC?</Label>
                                            <RadioGroup name="radioList" inline value={aprovar} onChange={(value) => {
                                                setJustificativaError('')
                                                handleRadioChange(value)
                                            }}>
                                                <Radio value={true}>Aprovar</Radio>
                                                <Radio value={false}>Rejeitar</Radio>
                                            </RadioGroup>
                                        </div>

                                        <div style={{ width: '30%' }} className="inputContainer">
                                            <Label htmlFor="">Modelo do MOC</Label>
                                            <SelectPicker
                                                appearance="default"
                                                style={{ width: '100%' }}
                                                placeholder="&nbsp;"
                                                data={selectData}
                                                value={modeloMoc}
                                                onChange={(value) => {
                                                    setModeloError('')
                                                    setModeloMoc(value)
                                                }}
                                            />
                                            {modeloError ? (
                                                <div className="inputError">{modeloError}</div>
                                            ) : null}

                                        </div>
                                    </div>

                                    <div className={styles.formDiv}>
                                        <div className="inputContainer" style={{ width: '100%' }}>
                                            <Label>Justificativas/Comentários</Label>
                                            <textarea name="" id="" cols="30" rows="5" value={justificativa} onChange={(event) => {
                                                setJustificativaError('')
                                                setJustificativa(event.target.value)
                                            }} onBlur={()=> setJustificativa(prev=>prev.trim())}></textarea>
                                            {justificativaError ? (
                                                <div className="inputError">{justificativaError}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <Checkbox
                                        value={participar}
                                        onChange={() => handleCheckChange()}
                                    >Desejo participar do preenchimento dos checklists e plano de ações</Checkbox>
                                </div>

                            </div>

                            <div style={{ marginTop: '2%' }} className="buttonFlex">
                                <Button onClick={() => handleSubmit()}>Submeter</Button>
                            </div>
                        </div>
                    </ LoadingContent>
                </>}
        </>
    )
}