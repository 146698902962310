import { useState, useEffect } from 'react'

import TitleConfigs from '../../../components/TitleConfigs'
import styles from './categories.module.css'

import categoryIconBlue from '../../../assets/configuration/categoriasBlue.svg'
import Label from '../../../components/Label/label'
import AddButton from '../../../components/addButton'
import { api } from '../../../services/api'
import Swal from "sweetalert2";
import Table from 'rc-table'
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'

import { RiPencilFill } from 'react-icons/ri';
import { FaTrashAlt } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from "react-icons/fa";


export default function Categories({mocs}) {
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])

    const [categoryError, setCategoryError] = useState('')

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get('/categories')
        setCategories(response.data)
    }



    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "",
            dataIndex: "name",
            key: "name",
            align: "left",
            width: 1000,
            render: (value, row) => Number(editId) === Number(row.id) ? <input style={{ margin: "-0.27rem", boxShadow: "none", }} className='inputEdit' type="text" value={categoryNameEdit} onChange={event => setCategoryNameEdit(event.target.value)} /> : <p>{value}</p>
        },
        {
            title: "",
            dataIndex: "",
            key: "name",
            align: "left",
            width: 50,
            render: (value, row) => (<div className='iconTableDiv'>
                <FaCheck className="actionBtn displayNone" onClick={(event) => submitEdit(event, row.id)} style={{ cursor: "pointer", width:'0.9rem', height:'0.9rem' }} />
                <FaTimes className="actionBtn displayNone" onClick={onCancelEdit} style={{ cursor: "pointer", width:'0.9rem', height:'0.9rem' }} />
                <img className="actionBtn" onClick={(event) => onEdit(event, row.id)} style={{ width: '0.9rem', height: '0.9rem', cursor: 'pointer' }} src={editIcon} alt='' />
                <img className="actionBtn" onClick={() => handleRemoveCategory(row.id)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>),
        },
    ]

    const [editId, setEditId] = useState('Id');
    const [inEdit, setInEdit] = useState([]);
    const [categoryNameEdit, setCategoryNameEdit] = useState('');
    const [categoryNameEditBefore, setCategoryNameEditBefore] = useState('');

    async function onEdit(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = false
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId(id)

        api.get(`categories/${id}`).then(response => {

            setCategoryNameEdit(response.data.name)
            setCategoryNameEditBefore(response.data.name)
        })
    }

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId('Id')
        setInEdit({ name: '' })
    }

    async function submitEdit(evt, id) {

        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        if (categoryNameEdit.trim() === '') {
            Toast.fire({
                icon: 'error',
                title: "Preencha o campo editável",
            })
            return false
        }

        if (categoryNameEditBefore == categoryNameEdit) {
            onCancelEdit(evt)
            return
        }

        try {

            let response = await api.put(`/categories/${id}`,
                {
                    "name": categoryNameEdit,
                })

            if (response.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: "Registro atualizado!",
                })
                for (const input of tdInputs) {
                    input.disabled = true
                }

                for (const btn of tdActionButtons) {
                    btn.classList.toggle("displayNone")
                }

                setEditId('Id')
                setInEdit({ name: '' })
                getData()
            } 
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }




    async function handleRemoveCategory(id) {
        const isCategoryInUse = mocs.some(({Categories})=>Categories.id === id);
        if(isCategoryInUse){
            Toast.fire({
                icon: 'error',
                text: 'Não é possível excluir, pois existem MOCs registrados associados a esta categoria.',
                timer: 5000
            })
            return;
        }
        try {
            Swal.fire({
                title: 'Deletar categoria?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                cancelButtonColor: "#636e72",
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await api.delete(`/categories/${id}`)

                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            text: 'Categoria deletada'
                        })
                        getData()
                    }
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }


    function validation() {
        let validation = true
        if (category === '') {
            validation = false
            setCategoryError('Digite uma categoria')
        }

        return validation
    }

    async function addCategory() {
        if (validation()) {
            try {
                let response = await api.post('categories', {
                    "name": category
                })
                
                if (response.status == 200) {
                    Toast.fire({
                        icon: 'success',
                        text: 'Categoria cadastrada'
                    })

                    setCategory('')
                    getData()
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    text: `${error.response.data.errors[0].msg}`
                })
            }

        }


    }

    return (
        <>
            <TitleConfigs titulo='Categorias' icon={categoryIconBlue} tamanho={{ width: "1.3rem" }} />

            <div className={styles.mainDiv}>
                <div style={{ width: '30rem' }} className="inputContainer">
                    <Label htmlFor="">Categoria</Label>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <input value={category} onChange={(event) => {
                            setCategoryError('')
                            setCategory(event.target.value)
                        }}
                            type="text" />
                        <AddButton onClick={() => addCategory()} />
                    </div>
                    {categoryError ? (
                        <div className="inputError">{categoryError}</div>
                    ) : null}
                </div>
                <div style={{ width: '30rem' }}>
                    {categories.length !== 0 ?

                        <Table
                            sticky={true}
                            columns={columns}
                            data={categories}
                            showHeader={false}
                        />
                        :
                        null
                    }
                </div>
            </div>

        </>
    )
}