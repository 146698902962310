import { useEffect, useState } from "react"

import styles from './basicInfo.module.css'

import Title from "../../Title/title"
import Label from "../../../components/Label/label";

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"
import { Checkbox, SelectPicker } from "rsuite";
import ReactTooltip from 'react-tooltip';
import { api, addAttachment } from "../../../services/api";
import moment from "moment";
import { useEditMoc } from "../../../providers/edit-moc";
import FileUploader from "../../../components/fileUploaderEdit"
import Swal from "sweetalert2";
import Spinner from "../../spinner";
import handleValidation from "../../../utils/handleValidation";

import MocParticipants from "../../mocparticipants";
import Button from "../../Button";

export default function BasicInfo({ id }) {
    const { titulo, setTitulo } = useEditMoc()
    const { solicitante, setSolicitante } = useEditMoc()
    const { data, setData } = useEditMoc()
    const { custo, setCusto } = useEditMoc()
    const { fiscal, setFiscal } = useEditMoc()
    const { selectedSiteName, setSelectedSiteName } = useEditMoc()
    const { selectedCategoriaName, setSelectedCategoriaName } = useEditMoc()
    const { selectedTipoName, setSelectedTipoName } = useEditMoc()
    const { site, setSite } = useEditMoc()
    const { categoria, setCategoria } = useEditMoc()
    const { tipo, setTipo } = useEditMoc()
    const { descricao, setDescricao } = useEditMoc()

    const { hse, setHse } = useEditMoc()
    const { uploadfotos, setUploadFotos } = useEditMoc()
    const { isOpenModalPic, setIsOpenModalPic } = useEditMoc()
    const { addedFiles, setAddedFiles } = useEditMoc()

    const { previousAddedFiles, setPreviousAddedFiles } = useEditMoc()
    const [selectedUsersName, setSelectedUsersName] = useState([])
    const [sites, setSites] = useState([])
    const [categorias, setCategorias] = useState([])
    const [tipos, setTipos] = useState([])
    const [name, setName] = useState([])
    const [users, setUsers] = useState([])
    const [stages, setStages] = useState([])
    const [section, setSection] = useState([])

    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])

    const [activeBasic, setActiveBasic] = useState(true)
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
    const [arrayMocParticipants, setArrayMocParticipants] = useState([])

    /* Errors */

    const [tituloError, setTituloError] = useState('')
    const [dataError, setDataError] = useState('')
    const [custoError, setCustoError] = useState('')
    const [fiscalError, setFiscalError] = useState('')
    const [siteError, setSiteError] = useState('')
    const [categoriaError, setCategoriaError] = useState('')
    const [tipoError, setTipoError] = useState('')
    const [descricaoError, setDescricaoError] = useState('')

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(async () => {
        await getData()
        setIsOpenModalPic(false)
    }, [])

    function openImg(photo) {
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }

    function handleSelectedSite(value) {
        let filteredSites = sites.filter(x => x.value == value)

        setSelectedSiteName(filteredSites[0].label)
        setSite(value)
    }

    function handleSelectedSolicitante(value) {
        let filteredusers = users.filter(x => x.value == value)

        setSelectedUsersName(filteredusers[0].label)
        setSolicitante(value)
    }


    function handleSelectedTipo(value) {
        let filteredTipos = tipos.filter(x => x.value == value)

        setSelectedTipoName(filteredTipos[0].label)
        setTipo(value)
    }

    function handleSelectedCategoria(value) {
        let filteredCategorias = categorias.filter(x => x.value == value)

        setSelectedCategoriaName(filteredCategorias[0].label)
        setCategoria(value)
    }
    function handleCheckChange() {
        if (hse == false) {
            setHse(true)
        } else {
            setHse(false)
        }
    }

    async function getData() {
        let categoriesResponse = await api.get('/categories')
        let typesResponse = await api.get('/types')
        let sitesResponse = await api.get('/sites')
        const usersResponse = await api.get('/users')


        let categoriesArray = []
        let sitesArray = []
        let typesArray = []
        let usersArray = []

        sitesResponse.data.forEach(x => {
            sitesArray.push({ label: x.name, value: x.id })
        })

        typesResponse.data.forEach(x => {
            typesArray.push({ label: x.name, value: x.id })
        })

        usersResponse.data.forEach(x => {
            usersArray.push({ label: x.name, value: x.id })
        })

        categoriesResponse.data.forEach(x => {
            categoriesArray.push({ label: x.name, value: x.id })
        })

        setSites(sitesArray)
        setCategorias(categoriesArray)
        setTipos(typesArray)
        setUsers(usersArray)

        let responseBasic = await api.get(`/moc/${id}`)
        setTitulo(responseBasic.data.title)
        setData(moment(responseBasic.data.openDate).format('YYYY-MM-DD'))
        setCusto(responseBasic.data.initialBudget)
        setName(responseBasic.data.Users.name)
        setSolicitante(responseBasic.data.idSolicitante)
        setFiscal(responseBasic.data.fiscalYear)
        setHse(responseBasic.data.hseSupport)
        setDescricao(responseBasic.data.description)
        setSite(responseBasic.data.idSite)
        setCategoria(responseBasic.data.idCategory)
        setTipo(responseBasic.data.idType)

        const responseAddedItens = await api.get(`/mocAttactments/${id}`)
        setPreviousAddedFiles(responseAddedItens.data)
        setAddedFiles(responseAddedItens.data)

        const responseMocparticipants = await api.get(`/mocparticipants/${id}`)
        setArrayMocParticipants(responseMocparticipants.data)

        const findAllStages = await api.get('/stages')
        const findAllSection = await api.get('/section')

        const stagesArray = []
        const sectionArray = []

        findAllStages.data.forEach(x => {
            if (responseBasic.data.modelMoc === 'Simplificado') {
                if (x.name === 'Simplificado') {
                    stagesArray.push({ label: x.name, value: x.id });
                }
            } else if (responseBasic.data.modelMoc === 'Completo') {
                if (x.name === 'Comissionamento' || x.name === 'Detalhamento' || x.name === 'Pré-Projeto') {
                    stagesArray.push({ label: x.name, value: x.id });
                }
            }
        })

        findAllSection.data.forEach(x => {
            return sectionArray.push({ label: x.name, value: x.id })
        })
        setSection(sectionArray)
        setStages(stagesArray)


    }

    async function handleSubmit() {
        const errorStateControls = {
            titulo: [titulo, setTituloError],
            data: [data, setDataError],
            custo: [custo, setCustoError],
            fiscal: [fiscal, setFiscalError],
            site: [site, setSiteError],
            categoria: [categoria, setCategoriaError],
            tipo: [tipo, setTipoError],
            descricao: [descricao, setDescricaoError],
        };
        if (!handleValidation(errorStateControls)) {
            return;
        }

        try {
            setIsLoadingSubmit(true)
            const responseMoc = await api.put(`/moc/${id}`,
                {
                    "idSolicitante": solicitante,
                    "idSite": site,
                    "idCategory": categoria,
                    "idType": tipo,
                    "title": titulo,
                    "openDate": data,
                    "initialBudget": custo,
                    "fiscalYear": fiscal,
                    "description": descricao,
                    "hseSupport": hse,
                    "isApproved": null,
                    "justification": null,
                    "needCapexApproval": null
                }
            )

            if (responseMoc.status !== 200) {
                return
            }

                    if (anexos.length != 0) {
                        for(const anexo of anexos){
                            await addAttachment('mocAttactments/upload', {
                                "mocId": responseMoc.data.id,
                                "file": anexo
                            })
                        }
                    }

                    for(const previousFile of previousAddedFiles){
                        if (addedFiles.filter(x => x.id == previousFile.id).length == 0) {
                            await api.delete(`mocAttactments/${previousFile.id}`)
                        }
                    }

                    for(const file of addedFiles){
                        if (previousAddedFiles.filter(x => x.id == file.id).length == 0) {
                            await addAttachment('mocAttactments/upload', {
                                "mocId": id,
                                "file": file
                            })
                        }
                    }

                    if (fotos.length != 0) {
                        for(const image of fotos){
                            await addAttachment('mocAttactments/upload', {
                                "mocId": responseMoc.data.id,
                                "file": image
                            })
                        }
                    }

                    Swal.fire({
                        title: "MOC editado com sucesso!",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#009EBE",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                    })
                    setIsLoadingSubmit(false)

                
            
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `Ocorreu um erro.Tente novamente mais tarde`
            })
            setIsLoadingSubmit(false)
        }

    }

    return (
        <div>
            {isLoadingSubmit == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Informações básicas</Title>

                    <div onClick={() => setActiveBasic(prevActiveBasic => !prevActiveBasic)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {activeBasic == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>

                {activeBasic ?
                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <div className={styles.inputDiv}>
                            <div style={{ width: '100%' }} className="inputContainer">
                                <Label htmlFor="">Título do MOC</Label>
                                <input value={titulo} onChange={(e) => { setTituloError(''); setTitulo(e.target.value) }} onBlur={() => setTitulo(prev => prev.trim())} type="text" />
                                {tituloError ? (
                                    <div className="inputError">{tituloError}</div>
                                ) : null}
                            </div>
                            <div style={{ width: '25%' }} className="inputContainer">
                                <Label htmlFor="">Solicitante</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    data={users}
                                    value={solicitante}
                                    defaultValue={solicitante}
                                    onChange={(value) => {
                                        handleSelectedSolicitante(value)
                                    }}
                                />
                            </div>
                            <div style={{ width: '25%' }} className="inputContainer">
                                <Label htmlFor="">Data de abertura</Label>
                                <input value={data} onChange={(e) => { setDataError(''); setData(e.target.value) }} type="date" />
                                {dataError ? (<div className="inputError">{dataError}</div>) : null}
                            </div>
                        </div>

                        <div className={styles.inputDiv}>
                            <div style={{ width: '40%' }} className="inputContainer">
                                <Label htmlFor="">Custo previsto</Label>
                                <input value={custo} onChange={(e) => { setCustoError(''); setCusto(e.target.value) }} type="text" />
                                {custoError ? (<div className="inputError">{custoError}</div>) : null}
                            </div>
                            <div style={{ width: '40%' }} className="inputContainer">
                                <Label htmlFor="">Fiscal year de implementação</Label>
                                <input value={fiscal} onChange={(e) => { setFiscalError(''); setFiscal(e.target.value) }} type="text" />
                                {fiscalError ? (<div className="inputError">{fiscalError}</div>) : null}
                            </div>
                            <div style={{ width: '25%' }} className="inputContainer">
                                <Label htmlFor="">Site</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    data={sites}
                                    value={site}
                                    defaultValue={site}
                                    onChange={(value) => {
                                        setSiteError('')
                                        handleSelectedSite(value)
                                    }}
                                />
                                {siteError ? (<div className="inputError">{siteError}</div>) : null}
                            </div>
                            <div style={{ width: '25%' }} className="inputContainer">
                                <Label htmlFor="">Categoria</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    data={categorias}
                                    value={categoria}
                                    defaultValue={'ssss'}
                                    onChange={(value) => {
                                        setCategoriaError('')
                                        handleSelectedCategoria(value)
                                    }}
                                />
                                {categoriaError ? (<div className="inputError">{categoriaError}</div>) : null}
                            </div>
                            <div style={{ width: '20%' }} className="inputContainer">
                                <Label htmlFor="">Tipo</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    defaultValue={'Julius'}
                                    data={tipos}
                                    value={tipo}
                                    onChange={(value) => {
                                        setTipoError('')
                                        handleSelectedTipo(value)
                                    }}
                                />
                                {tipoError ? (<div className="inputError">{tipoError}</div>) : null}
                            </div>
                        </div>

                        <div className="inputContainer" style={{ width: '100%', marginTop: '1%' }} >
                            <Label htmlFor="">Descrição da mudança:</Label>
                            <textarea value={descricao} onChange={(e) => { setDescricaoError(''); setDescricao(e.target.value) }} onBlur={() => setDescricao(prev => prev.trim())} name="" id="" cols="30" rows="5"></textarea>
                            {descricaoError ? (<div className="inputError">{descricaoError}</div>) : null}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1rem' }}>
                                <Checkbox value={hse}
                                    onChange={() => handleCheckChange()}>Desejo apoio do HSE no preenchimento dos checklists</Checkbox>
                            </div>
                        </div>
                        <Label>Anexos</Label>
                        <div className={styles.attachmentsDiv}>
                            <div className={styles.pdfDiv}>
                                <FileUploader
                                    type="pdf"
                                    isOptional={true}
                                    addedFiles={addedFiles}
                                    setAddedFiles={setAddedFiles}
                                />
                            </div>
                        </div>
                        <div style={{  marginTop: '1rem'}} className={styles.basicInfoTitle}>
                            <Title>Adiconar participantes</Title>
                        </div>
                        <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                            <MocParticipants
                                sections={section}
                                stages={stages}
                                mocId={id}
                                arrayMocParticipants={arrayMocParticipants}
                                setArrayMocParticipants={setArrayMocParticipants}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '4%' }}>
                            <Button onClick={handleSubmit}>Salvar</Button>
                        </div>
                        
                    </div>
                    :
                    null
                }

            </div>
        </div>
    )
}