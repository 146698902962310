import Cookie from 'js-cookie';

const storage = {};
// set o token no cookie
try {
  if (!window.localStorage) {
    throw Error('no local storage');
  }

  storage.set = (key, value) =>  Cookie.set(key, JSON.stringify(value), { expires: 3 });
  storage.get = (key) => {
    const item = Cookie.get(key);
    try {

      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  };
  storage.remove = key => Cookie.remove(key);
} catch (e) {
  storage.set = Cookie.set;
  storage.get = Cookie.getJSON;
  storage.remove = Cookie.remove;
}

export default storage;