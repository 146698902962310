import { useState, useEffect } from 'react'

import TitleConfigs from '../../../components/TitleConfigs'
import styles from './styles.module.css'

import sectionIconBlue from '../../../assets/configuration/section-blue.png'
import Label from '../../../components/Label/label'
import AddButton from '../../../components/addButton'
import { api } from '../../../services/api'
import Swal from "sweetalert2";
import Table from 'rc-table'
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'

import { FaCheck } from 'react-icons/fa';
import { FaTimes } from "react-icons/fa";


export default function Section() {
    const [section, setSection] = useState('')
    const [sections, setSections] = useState([])

    const [sectionError, setSectionError] = useState('')

    useEffect(() => {
        getSections()
    }, [])

    async function getSections() {
        const responseSection = await api.get('/section')
        setSections(responseSection.data)
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "",
            dataIndex: "name",
            key: "name",
            align: "left",
            width: 1000,
            render: (value, row) => Number(editId) === Number(row.id) ? <input style={{ margin: "-0.27rem", boxShadow: "none", }} className='inputEdit' type="text" value={sectionNameEdit} onChange={event => setSectionNameEdit(event.target.value)} /> : <p>{value}</p>
        },
        {
            title: "",
            dataIndex: "",
            key: "name",
            align: "left",
            width: 50,
            render: (value, row) => (<div className='iconTableDiv'>
                <FaCheck className="actionBtn displayNone" onClick={(event) => submitEdit(event, row.id)} style={{ cursor: "pointer", width: '0.9rem', height: '0.9rem' }} />
                <FaTimes className="actionBtn displayNone" onClick={onCancelEdit} style={{ cursor: "pointer", width: '0.9rem', height: '0.9rem' }} />
                <img className="actionBtn" onClick={(event) => onEdit(event, row.id)} style={{ width: '0.9rem', height: '0.9rem', cursor: 'pointer' }} src={editIcon} alt='' />
                <img className="actionBtn" onClick={() => handleRemoveSection(row.id)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>),
        },
    ]

    const [editId, setEditId] = useState('Id');
    const [inEdit, setInEdit] = useState([]);
    const [sectionNameEdit, setSectionNameEdit] = useState('');
    const [sectionNameEditBefore, setSectionNameEditBefore] = useState('');

    async function onEdit(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = false
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId(id)

        api.get(`section/${id}`).then(response => {

            setSectionNameEdit(response.data.name)
            setSectionNameEditBefore(response.data.name)
        })
    }

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId('Id')
        setInEdit({ name: '' })
    }

    async function submitEdit(evt, id) {

        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        if (sectionNameEdit.trim() === '') {
            Toast.fire({
                icon: 'error',
                title: "Preencha o campo editável",
            })
            return false
        }

        if (sectionNameEditBefore == sectionNameEdit) {
            onCancelEdit(evt)
            return
        }

        try {

            const response = await api.put(`/section/${id}`,
                {
                    "name": sectionNameEdit,
                })

            if (response.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: "Registro atualizado!",
                })
                for (const input of tdInputs) {
                    input.disabled = true
                }

                for (const btn of tdActionButtons) {
                    btn.classList.toggle("displayNone")
                }

                setEditId('Id')
                setInEdit({ name: '' })
                getSections()
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }

    async function handleRemoveSection(id) {
        try {
            Swal.fire({
                title: 'Deletar Setor?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                cancelButtonColor: "#636e72",
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await api.delete(`/section/${id}`)

                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            text: 'Setor deletado'
                        })
                        getSections()
                    }
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }


    function validation() {
        let validation = true
        if (section === '') {
            validation = false
            setSectionError('Digite um setor')
        }

        return validation
    }

    async function addSection() {
        const valid = validation();
        if (!valid) {
            return;
        }
        try {
            const response = await api.post('section', {
                "name": section
            })

            if (response.status == 200) {
                Toast.fire({
                    icon: 'success',
                    text: 'Setor cadastrado'
                })

                setSection('')
                getSections()
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }

    return (
        <>
            <TitleConfigs titulo='Setores' icon={sectionIconBlue} tamanho={{ width: "3rem", }} />

            <div className={styles.mainDiv}>
                <div style={{ width: '30rem' }} className="inputContainer">
                    <Label htmlFor="">Setores</Label>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <input value={section} onChange={(event) => {
                            setSectionError('')
                            setSection(event.target.value)
                        }}
                            type="text" />
                        <AddButton onClick={() => addSection()} />
                    </div>
                    {sectionError ? (
                        <div className="inputError">{sectionError}</div>
                    ) : null}
                </div>
                <div style={{ width: '30rem' }}>
                    {sections.length !== 0 ?

                        <Table
                            sticky={true}
                            columns={columns}
                            data={sections}
                            showHeader={false}
                        />
                        :
                        null
                    }
                </div>
            </div>

        </>
    )
}