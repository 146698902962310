import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import Title from "../../Title/title";
import Swal from "sweetalert2";

import { IoAttachSharp } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

import deleteIcon from "../../../assets/delete.svg";
import { RiCloseFill } from "react-icons/ri";
import Modal from "react-modal";
import Label from "../../Label/label";
import Button from "../../Button";

import styles from "./mocChecklist.module.css";
import {
  AutoComplete,
  Checkbox,
  Radio,
  RadioGroup,
  SelectPicker,
} from "rsuite";
import Table from "rc-table";
import moment from "moment";
import NewCapex from "../../newCapex";
import CapexApproval from "../capexApproval";
import CapexEdit from "../capexEdit/capexEdit";
import ModalActions from "../../modalAction";
import ModalAttachAction from "../../modalAttachAction";
import Spinner from "../../spinner";
import { FaInfoCircle } from "react-icons/fa";

const chunkArray = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

export default function MocSteps({
  id,
  stageId,
  readOnly,
  editCapex,
  viewMoc,
  openChecklist = false,
  activeToggle,
  editMoc,
  disableOpacity = false,
  evidenceObservation = ""
}) {
  // CHECKLISTS //

  const [activeChecklist, setActiveChecklist] = useState(
    !readOnly && openChecklist ? true : false
  );

  const [actionModalOpen, setActionModalOpen] = useState(false);

  const [addedChecklists, setAddedChecklists] = useState([]);
  const [mocStageAnswers, setMocStageAnswers] = useState([]);

  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);

  const [actionTitle, setActionTitle] = useState("");
  const [responsible, setResponsible] = useState("");
  const [responsibleId, setResponsibleId] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [selectedStage, setSelectedStage] = useState(null);

  const [actionTitleError, setActionTitleError] = useState("");
  const [responsibleError, setResponsibleError] = useState(null);
  const [expirationDateError, setExpirationDateError] = useState("");
  const [addStageError, setAddStageError] = useState("");

  const [isFinished, setIsFinished] = useState(false);
  const [isOpenActions, setIsOpenActions] = useState(false);

  const [openMoreActionModal, setOpenMoreActionModal] = useState(false);

  const [isLoadingSubmitAction, setIsLoadingSubmitAction] = useState(false);
  const [isLoadingChecklist, setIsLoadingChecklist] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [actionId, setActionId] = useState('');


  useEffect(() => {
    if (activeToggle) {
      setActiveChecklist(true);
      setActionActive(true);
      setActiveToogleCapex(true);
    }
  }, [activeToggle]);

  const [activeToogleCapex, setActiveToogleCapex] = useState(false);

  // ACTIONS //

  const [actionActive, setActionActive] = useState(false);

  const [actions, setActions] = useState([]);
  const [actionFinished, setActionFinished] = useState(true);
  const [needsCapex, setNeedsCapex] = useState(false);

  const [checklistType, setChecklistType] = useState("");

  const [moc, setMoc] = useState("");

  useEffect(async () => {
    await getDataChecklists();
    await getDataActions();
  }, []);

  async function getDataChecklists() {
    setIsLoadingChecklist(true)
    if (stageId === 1) {
      setChecklistType("Pré-Projeto");
    }

    if (stageId === 2) {
      setChecklistType("Detalhamento");
    }

    if (stageId === 3) {
      setChecklistType("Comissionamento");
    }

    if (stageId === 4) {
      setChecklistType("Simplificado");
    }

    let responseChecklists = await api.get(`checklists/stage/${stageId}`);
    let responseMocStageAnswers = await api.get(
      `mocstages/answers/${stageId}/${id}`
    );

    setAddedChecklists(responseChecklists.data);
    setMocStageAnswers(responseMocStageAnswers.data);

    let responseUsers = await api.get("users");

    let userArray = [];

    responseUsers.data.map((user) => {
      userArray.push({ label: user.name, value: user.name });
    });

    setUsers(userArray);
    setUserData(responseUsers.data);

    let validation = true;
    responseMocStageAnswers.data.map((checklist) => {
      checklist.Questions.map((question) => {
        if (question.Answer.length === 0) {
          validation = false;
        }
      });
    });
    setIsFinished(validation);

    let responseActions = await api.get(`/actions/stages/${stageId}/${id}`);

    if (responseActions.data.length > 0) {
      setIsOpenActions(true);
    }
    setIsLoadingChecklist(false)
  }

  async function getDataActions() {
    // responses actions
    setIsLoadingChecklist(true)

    let response = await api.get(`actions/stages/${stageId}/${id}`);
    setActions(response.data);

    // validation actions

    let validation = true;
    response.data.map((action) => {
      if (action.status === "Pendente") {
        validation = false;
      }
    });

    setActionFinished(validation);

    let responseMoc = await api.get(`moc/${id}`);
    setMoc(responseMoc.data);
    setIsLoadingChecklist(false)

  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  /* ------------------------------------------------- CHECKLISTS FUNCTIONS ------------------------------------------------- */

  let stages;

  if (stageId == 1) {
    stages = [
      { value: "Pré-Projeto", label: "Pré-Projeto" },
      { value: "Detalhamento", label: "Detalhamento" },
      { value: "Comissionamento", label: "Comissionamento" },
    ];
  }

  if (stageId == 2) {
    stages = [
      { value: "Detalhamento", label: "Detalhamento" },
      { value: "Comissionamento", label: "Comissionamento" },
    ];
  }

  if (stageId == 3) {
    stages = [{ value: "Comissionamento", label: "Comissionamento" }];
  }

  function handleCloseActionModal() {
    setActionModalOpen(false);
    cleanActionModal();
  }

  function cleanActionModal() {
    setActionTitle("");
    setResponsible("");
    setExpirationDate("");
    setSelectedStage(null);
    setCurrentChecklist({});
    setQuestionPriority("");
    setQuestionId("");
    setCurrentAnswer("");
    setQuestionName("");

    setActionTitleError("");
    setResponsibleError("");
    setExpirationDateError("");
    setAddStageError("");
  }

  function handleResponsible(value) {
    let filtered = userData.filter((x) => x.name == value);

    if (filtered.length != 0) {
      setResponsibleId(filtered[0].id);
    }

    setResponsible(value);
  }

  function modalValidation() {
    let validation = true;

    if (actionTitle.trim() === "") {
      validation = false;
      setActionTitleError("Digite um título para a ação");
    }

    if (responsible === "" || responsible === null) {
      validation = false;
      setResponsibleError("Selecione um responsável");
    }

    if (!responsibleId) {
      validation = false
      setResponsibleError('Selecione um usuário válido')
    }

    if (expirationDate.trim() === "") {
      validation = false;
      setExpirationDateError("Digite uma data");
    }

    if (moment(expirationDate).isBefore()) {
      validation = false;
      setExpirationDateError("Digite uma data futura");
    }

    if (stageId != 4) {
      if (selectedStage == null) {
        setAddStageError("Selecione em qual etapa a ação se encerra.");
        validation = false;
      }
    }

    return validation;
  }

  const [currentChecklist, setCurrentChecklist] = useState({});
  const [questionPriority, setQuestionPriority] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [questionName, setQuestionName] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");

  function handleActionModalOpen(
    checklist,
    questionPriority,
    questionId,
    questionName,
    answer
  ) {
    setCurrentChecklist(checklist);
    setQuestionPriority(questionPriority);
    setQuestionId(questionId);
    setQuestionName(questionName);
    setCurrentAnswer(answer);
    setActionModalOpen(true);
  }

  async function submitAction() {
    if (modalValidation()) {
      try {
        setIsLoadingSubmitAction(true);

        let stage;

        if (selectedStage === "Pré-Projeto") {
          stage = 1;
        }

        if (selectedStage === "Detalhamento") {
          stage = 2;
        }

        if (selectedStage === "Comissionamento") {
          stage = 3;
        }

        if (stageId === 4) {
          stage = 4;
        }

        let validation = true;

        let mocStageAnswersAux = [...mocStageAnswers];

        const check = mocStageAnswersAux.find(
          (check) => check.checklistId === currentChecklist.checklistId
        );

        let newAnswer = {};

        let response = await api.post("actions", {
          name: actionTitle,
          responsible: responsibleId,
          validity: expirationDate,
          stageId: stage,
          mocId: id,
          status: "Pendente",
          priority: questionPriority,
          questionId: questionId,
          checklistName: currentChecklist.checklistName,
        });

        if (response.status === 200) {
          if (
            currentChecklist.Questions.find((x) => x.id === questionId).Answer
              .length === 0
          ) {
            let response = await api.post(`mocstages/answer`, {
              question: questionName,
              answer: currentAnswer,
              questionId: questionId,
              mocId: id,
              checklistName: currentChecklist.checklistName,
              stageId: stageId,
              checklistId: currentChecklist.checklistId,
            });

            if (response.status === 200) {
              newAnswer = {
                answer: response.data.answer,
                checklistName: response.data.checklistName,
                id: response.data.id,
                mocId: response.data.mocId,
                question: response.data.question,
                stageId: response.data.stageId,
              };

              check.Questions.find(
                (question) => question.id === questionId
              ).Answer[0] = newAnswer;

              setMocStageAnswers(mocStageAnswersAux);

              mocStageAnswersAux.map((checklist) => {
                checklist.Questions.map((question) => {
                  if (question.Answer.length === 0) {
                    validation = false;
                  }
                });
              });

              setIsFinished(validation);

              Toast.fire({
                icon: "success",
                title: "Ação criada com sucesso",
              });
              await getDataActions();
            }
          } else {
            let answerId = currentChecklist.Questions.find(
              (x) => x.id === questionId
            ).Answer[0].id;
            let response = await api.put(`mocstages/answer/${answerId}`, {
              answer: currentAnswer,
            });

            if (response.status === 200) {
              newAnswer = {
                answer: response.data.answer,
                checklistName: response.data.checklistName,
                id: response.data.id,
                mocId: response.data.mocId,
                question: response.data.question,
                stageId: response.data.stageId,
              };

              check.Questions.find(
                (question) => question.id === questionId
              ).Answer[0] = newAnswer;

              setMocStageAnswers(mocStageAnswersAux);

              mocStageAnswersAux.map((checklist) => {
                checklist.Questions.map((question) => {
                  if (question.Answer.length === 0) {
                    validation = false;
                  }
                });
              });

              setIsFinished(validation);

              Toast.fire({
                icon: "success",
                title: "Ação criada com sucesso",
              });
              await getDataActions();
            }
          }

          handleCloseActionModal();
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: `${error.response.data.errors[0].msg}`,
        });
      }
      finally{
        setIsLoadingSubmitAction(false);
      }
    }
  }

  async function handleCheckChange(
    checklist,
    questionId,
    questionName,
    answer,
    questionPriority
  ) {
    setIsLoadingChecklist(false)
    if (answer === "Não adequado") {
      handleActionModalOpen(
        checklist,
        questionPriority,
        questionId,
        questionName,
        answer
      );
      return;
    }

    let validation = true;

    let mocStageAnswersAux = [...mocStageAnswers];

    const check = mocStageAnswersAux.find(
      (check) => check.checklistId === checklist.checklistId
    );

    let newAnswer = {};

    if (
      checklist.Questions.find((x) => x.id === questionId).Answer.length === 0
    ) {
      const response = await api.post(`mocstages/answer`, {
        question: questionName,
        answer: answer,
        questionId: questionId,
        mocId: id,
        checklistName: checklist.checklistName,
        stageId: stageId,
        checklistId: checklist.checklistId,
      });

      if (response.status === 200) {
        newAnswer = {
          answer: response.data.answer,
          checklistName: response.data.checklistName,
          id: response.data.id,
          mocId: response.data.mocId,
          question: response.data.questionId,
          stageId: response.data.stageId,
        };

        if (Object.keys(newAnswer).length) {
          check.Questions.find(
            (question) => question.id === questionId
          ).Answer[0] = newAnswer;
        }

        setMocStageAnswers(mocStageAnswersAux);

        mocStageAnswersAux.map((checklist) => {
          checklist.Questions.map((question) => {
            if (question.Answer.length === 0) {
              validation = false;
            }
          });
        });
        setIsFinished(validation);

        return;
      }
    } else {
      if (
        checklist.Questions.find((x) => x.id === questionId).Answer[0]
          .answer === "Não adequado"
      ) {
        Swal.fire({
          title: "Deletar ação correspondente?",
          text: "A mudança da resposta causará a deleção da ação correspondente",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009EBE",
          cancelButtonColor: "#636e72",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setIsLoadingChecklist(true)
            let actionsResponse = await api.get(
              `actions/stages/${stageId}/${id}`
            );

            let actionId = actionsResponse.data.find(
              (x) => x.questionId === questionId
            ).id;

            let answerId = checklist.Questions.find((x) => x.id === questionId)
              .Answer[0].id;

            const response = await api.put(`mocstages/answer/${answerId}`, {
              answer: answer,
            });

            if (response.status === 200) {
              await api.delete(`actions/${actionId}`);
              await getDataActions();

              newAnswer = {
                answer: response.data.answer,
                checklistName: response.data.checklistName,
                id: response.data.id,
                mocId: response.data.mocId,
                questionId: response.data.questionId,
                stageId: response.data.stageId,
              };

              if (Object.keys(newAnswer).length) {
                check.Questions.find(
                  (question) => question.id === questionId
                ).Answer[0] = newAnswer;
              }
              setMocStageAnswers(mocStageAnswersAux);

              mocStageAnswersAux.map((checklist) => {
                checklist.Questions.map((question) => {
                  if (question.Answer.length === 0) {
                    validation = false;
                  }
                });
              });
              setIsFinished(validation);

              return;
            }

            setIsLoadingChecklist(false)
          }
        });
      } else {
        let answerId = checklist.Questions.find((x) => x.id === questionId)
          .Answer[0].id;

        const response = await api.put(`mocstages/answer/${answerId}`, {
          answer: answer,
        });

        if (response.status === 200) {
          newAnswer = {
            answer: response.data.answer,
            checklistName: response.data.checklistName,
            id: response.data.id,
            mocId: response.data.mocId,
            questionId: response.data.questionId,
            stageId: response.data.stageId,
          };

          if (Object.keys(newAnswer).length) {
            check.Questions.find(
              (question) => question.id === questionId
            ).Answer[0] = newAnswer;
          }

          setMocStageAnswers(mocStageAnswersAux);
          mocStageAnswersAux.map((checklist) => {
            checklist.Questions.map((question) => {
              if (question.Answer.length === 0) {
                validation = false;
              }
            });
          });
          setIsFinished(validation);
          return;
        }
      }
    }
  }

  async function handleNotApplicable(checklist) {
    let validation = true;

    let mocStageAnswersAux = [...mocStageAnswers];

    const check = mocStageAnswersAux.find(
      (check) => check.checklistId === checklist.checklistId
    );

    let newAnswer = {};

    if (checklist.Questions.filter((x) => x.Answer.length !== 0).length === 0) {
      //  Se não há perguntas com resposta

      Swal.fire({
        title: "Deseja realmente mudar todas as respostas para não aplicável?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009EBE",
        cancelButtonColor: "#636e72",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoadingChecklist(true);
          const promise = checklist.Questions.map(async (question) => {
            let response = await api.post(`mocstages/answer`, {
              question: question.questions,
              questionId: question.id,
              answer: "Não aplicável",
              mocId: id,
              checklistName: checklist.checklistName,
              stageId: stageId,
              checklistId: checklist.checklistId,
            });

            newAnswer = {
              answer: response.data.answer,
              checklistName: response.data.checklistName,
              id: response.data.id,
              mocId: response.data.mocId,
              questionId: response.data.questionId,
              stageId: response.data.stageId,
            };

            if (Object.keys(newAnswer).length) {
              check.Questions.find((x) => x.id === question.id).Answer[0] =
                newAnswer;
            }
          });

          await Promise.all(promise);

          setMocStageAnswers(mocStageAnswersAux);

          mocStageAnswersAux.map((checklist) => {
            checklist.Questions.map((question) => {
              if (question.Answer.length === 0) {
                validation = false;
              }
            });
          });
          setIsFinished(validation);

          await getDataActions();
          setIsLoadingChecklist(false);
        }
      });
    } else {
      // Se há pelo menos uma pergunta com resposta
      let notAdequateQuestions = checklist.Questions.filter(
        (x) => x.Answer.length !== 0 && x.Answer[0].answer === "Não adequado"
      );

      let questionsArray = [];
      let questionsIdArray = [];

      if (notAdequateQuestions.length !== 0) {
        notAdequateQuestions.map((x, index) => {
          questionsArray.push(index + 1);
          questionsIdArray.push(x.id);
        });

        Swal.fire({
          title: "Uma ou mais perguntas possuem ações correspondentes",
          text: `A mudança da resposta causará a deleção da(s) ação(s) da(s) pergunta(s) "${questionsArray.toString()}" correspondente(s)`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009EBE",
          cancelButtonColor: "#636e72",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setIsLoadingChecklist(true);
            let actionsResponse = await api.get(
              `actions/stages/${stageId}/${id}`
            );

            questionsIdArray.map(async (questionId) => {
              actionsResponse.data.map(async (action) => {
                if (action.questionId === questionId) {
                  await api.delete(`actions/${action.id}`);
                }
              });
            });

            const promise = checklist.Questions.map(async (question) => {
              if (question.Answer.length === 0) {
                let response = await api.post(`mocstages/answer`, {
                  question: question.questions,
                  questionId: question.id,
                  answer: "Não aplicável",
                  mocId: id,
                  checklistName: checklist.checklistName,
                  stageId: stageId,
                  checklistId: checklist.checklistId,
                });

                newAnswer = {
                  answer: response.data.answer,
                  checklistName: response.data.checklistName,
                  id: response.data.id,
                  mocId: response.data.mocId,
                  questionId: response.data.questionId,
                  stageId: response.data.stageId,
                };

                if (Object.keys(newAnswer).length) {
                  check.Questions.find((x) => x.id === question.id).Answer[0] =
                    newAnswer;
                }
              } else {
                let response = await api.put(
                  `mocstages/answer/${question.Answer[0].id}`,
                  {
                    answer: "Não aplicável",
                  }
                );

                newAnswer = {
                  answer: response.data.answer,
                  checklistName: response.data.checklistName,
                  id: response.data.id,
                  mocId: response.data.mocId,
                  questionId: response.data.questionId,
                  stageId: response.data.stageId,
                };

                if (Object.keys(newAnswer).length) {
                  check.Questions.find((x) => x.id === question.id).Answer[0] =
                    newAnswer;
                }
              }
            });

            await Promise.all(promise);

            setMocStageAnswers(mocStageAnswersAux);

            mocStageAnswersAux.map((checklist) => {
              checklist.Questions.map((question) => {
                if (question.Answer.length === 0) {
                  validation = false;
                }
              });
            });
            setIsFinished(validation);

            /* await getDataChecklists(); */
            await getDataActions();
            setIsLoadingChecklist(false);
          }
        });
      } else {
        Swal.fire({
          title:
            "Deseja realmente mudar todas as respostas para não aplicável?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009EBE",
          cancelButtonColor: "#636e72",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setIsLoadingChecklist(true);
            const promise = checklist.Questions.map(async (question) => {
              if (question.Answer.length === 0) {
                let response = await api.post(`mocstages/answer`, {
                  question: question.questions,
                  questionId: question.id,
                  answer: "Não aplicável",
                  mocId: id,
                  checklistName: checklist.checklistName,
                  stageId: stageId,
                  checklistId: checklist.checklistId,
                });

                newAnswer = {
                  answer: response.data.answer,
                  checklistName: response.data.checklistName,
                  id: response.data.id,
                  mocId: response.data.mocId,
                  questionId: response.data.questionId,
                  stageId: response.data.stageId,
                };

                if (Object.keys(newAnswer).length) {
                  check.Questions.find(
                    (question) => question.id === question.Answer[0].id
                  ).Answer[0] = newAnswer;
                }
              } else {
                let response = await api.put(
                  `mocstages/answer/${question.Answer[0].id}`,
                  {
                    answer: "Não aplicável",
                  }
                );

                newAnswer = {
                  answer: response.data.answer,
                  checklistName: response.data.checklistName,
                  id: response.data.id,
                  mocId: response.data.mocId,
                  questionId: response.data.questionId,
                  stageId: response.data.stageId,
                };

                if (Object.keys(newAnswer).length) {
                  check.Questions.find((x) => x.id === question.id).Answer[0] =
                    newAnswer;
                }
              }
            });

            await Promise.all(promise);

            setMocStageAnswers(mocStageAnswersAux);

            mocStageAnswersAux.map((checklist) => {
              checklist.Questions.map((question) => {
                if (question.Answer.length === 0) {
                  validation = false;
                }
              });
            });
            setIsFinished(validation);

            /* await getDataChecklists(); */
            await getDataActions();
            setIsLoadingChecklist(false);
          }
        });
      }
    }
  }

  /* ------------------------------------------------- ACTIONS FUNCTIONS ------------------------------------------------- */

  const columns = [
    {
      title: "Ação:",
      dataIndex: "",
      key: "title",
      align: "left",
      width: 250,
      render: (row) => <div>{row.name}</div>,
    },
    {
      title: "Checklist:",
      dataIndex: "",
      key: "title",
      align: "left",
      width: 150,
      render: (row) => <div>{row.checklistName}</div>,
    },
    {
      title: "Etapa de conclusão:",
      dataIndex: "",
      key: "cod",
      align: "left",
      width: 150,
      render: (row) => {
        let etapa;
        if (row.stageId == 1) {
          etapa = "Pré-Projeto";
        }

        if (row.stageId == 2) {
          etapa = "Detalhamento";
        }

        if (row.stageId == 3) {
          etapa = "Comissionamento";
        }

        if (row.stageId == 4) {
          etapa = "Simplificado";
        }

        return <div>{etapa}</div>;
      },
    },
    {
      title: "Responsável:",
      dataIndex: "",
      key: "data",
      align: "left",
      width: 150,
      render: (row) => <div>{row.Users.name}</div>,
    },
    {
      title: "Validade:",
      dataIndex: "",
      key: "site",
      align: "left",
      width: 150,
      render: (row) => <div>{moment(row.validity).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "Realização:",
      dataIndex: "",
      key: "site",
      align: "left",
      width: 150,
      render: (row) => (
        <div>
          {row.realization ? moment(row.realization).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      title: "Status:",
      dataIndex: "",
      key: "site",
      align: "left",
      width: 150,
      render: (row) => {
        return (
          <SelectPicker
            value={row.status}
            data={dataType()}
            onChange={(value) => handleStatusChange(value, row.id)}
            searchable={false}
            cleanable={false}
            size="xs"
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "file",
      align: "right",
      width: 5,
      render: (value, row, id) => {
        return (
          <div
              onClick={() => handleOpenModalAction(row.id)}
            style={{
              display: "flex",
              cursor: "pointer !important",
              justifyContent: "center",
              pointerEvents: 'all',
              opacity: '1'
            }}
          >
            <IoAttachSharp  style={{
              cursor: "pointer",
            }} color='#009EBE' size={20} />
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "file",
      align: "right",
      width: 5,
      render: (value, row, id) => {
        return (
          <div
            onClick={() => handleRemoveAction(row.id)}
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
            }}
          >
            <img
              style={{ display: "block", width: "1.2rem", height: "1.2rem" }}
              src={deleteIcon}
              alt=""
            />
          </div>
        );
      },
    },

  ];

  function handleMoreAction() {
    setOpenMoreActionModal(true);
  }

  function dataType() {
    let statusData;

    if (checklistType == "Pré-Projeto") {
      statusData = [
        { label: "Pendente", value: "Pendente" },
        { label: "Realizado", value: "Realizado" },
        {
          label: "Finalizar em detalhamento",
          value: "Finalizar em detalhamento",
        },
        {
          label: "Finalizar em comissionamento",
          value: "Finalizar em comissionamento",
        },
      ];
    }

    if (checklistType == "Detalhamento") {
      statusData = [
        { label: "Pendente", value: "Pendente" },
        { label: "Realizado", value: "Realizado" },
        {
          label: "Finalizar em comissionamento",
          value: "Finalizar em comissionamento",
        },
      ];
    }

    if (checklistType == "Comissionamento" || checklistType == "Simplificado") {
      statusData = [
        { label: "Pendente", value: "Pendente" },
        { label: "Realizado", value: "Realizado" },
      ];
    }

    return statusData;
  }

  async function handleStatusChange(value, id) {
    let today = moment().format("YYYY-MM-DD");
    try {
      if (value == "Finalizar em detalhamento") {
        Swal.fire({
          title: 'Deseja realmente finalizar ação na etapa "Detalhamento"?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009EBE",
          cancelButtonColor: "#636e72",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let response = await api.put(`actions/${id}`, {
              stageId: 2,
              realization: null,
            });
            await getDataActions();
          }
        });
      } else if (value == "Finalizar em comissionamento") {
        Swal.fire({
          title: 'Deseja realmente finalizar ação na etapa "Comissionamento"?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009EBE",
          cancelButtonColor: "#636e72",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let response = await api.put(`actions/${id}`, {
              stageId: 3,
              realization: null,
            });
            await getDataActions();
          }
        });
      } else {
        let response = await api.put(`actions/${id}`, {
          status: value,
          realization: value === "Realizado" ? today : null,
        });
        await getDataActions();
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        text: `${error.response.data.errors[0].msg}`,
      });
    }
  }

  async function handleRemoveAction(id) {
    Swal.fire({
      title: "Deletar ação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009EBE",
      cancelButtonColor: "#636e72",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`actions/${id}`);
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: `${error.response.data.errors[0].msg}`,
            timer: 3000
          });
        }
        await getDataActions();
      }
    });
  }

  function handleOpenModalAction(id) {
    setIsLoadingChecklist(true)
    setModalAction(true)
    setActionId(id)
  }


  async function submitStage() {
    if (actionFinished) {
      Swal.fire({
        text:
          checklistType == "Comissionamento" || moc.modelMoc == "Simplificado"
            ? "Deseja realmente finalizar o MOC?"
            : `Finalizar ${checklistType}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#009EBE",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let response = await api.put(`/stage/moc/${stageId}/${id}`, {
              isCompleted: true,
            });

            if (response.status == 200) {
              Swal.fire({
                text: "Etapa finalizada com sucesso",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#009EBE",
                confirmButtonText: "Ok",
              }).then((result) => {
                window.location.reload();
                if (editMoc === true) {

                }
              });
            }
          } catch (error) {
            Toast.fire({
              icon: "error",
              text: `${error.response.data.errors[0].msg}`,
            });
          }
        }
      });
    }
  }

  return (
    <>
      {isLoadingChecklist == true ? (
        <div className="spinner">
          <Spinner animation="border" />
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div style={{ marginTop: "1%" }} className={styles.basicInfoDiv}>
          <div className={styles.basicInfoTitle}>
            <Title>
              {checklistType == "Simplificado"
                ? `Checklist simplificado`
                : `Checklist de ${checklistType}`}
            </Title>

            <div
              onClick={() => {
                setActiveChecklist(
                  (prevActiveChecklist) => !prevActiveChecklist
                );
              }}
              style={{
                color: "#009EBE",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {activeChecklist == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
          </div>

          <div className={!activeChecklist ? styles.hideDiv : ""}>
            <div
              className={readOnly && !disableOpacity ? styles.disable : ""}
              style={{
                borderTop: "1px solid #009EBE",
                marginTop: "0.25rem",
                paddingBottom: "1rem",
              }}
            >
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1% 0' }}>
                            <ButtonPlus
                                onClick={() => handleOpenModal()}
                                titulo="Importar checklist"
                            />
                        </div> */}
              {/* Antigo Import Checklists */}

              {addedChecklists.length !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {mocStageAnswers.map((checklist) => {
                    const chunkedArray = chunkArray(checklist.Questions, 26);
                    return (
                      <div className={styles.checklistDiv}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1.1rem",
                              color: "#009EBE",
                              fontWeight: "bold",
                            }}
                          >
                            {checklist.checklistName}
                          </span>
                          <Button
                            onClick={() => {
                              handleNotApplicable(checklist);
                            }}
                          >
                            Não aplicável
                          </Button>
                        </div>
                        <div className={styles.questionDiv}>
                          {checklist.Questions.map((question, index) => {
                            return (
                              <div className={styles.questionBox}>
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {index + 1})
                                  </span>{" "}
                                  <span>{question.questions}</span>
                                </div>

                                <RadioGroup
                                  value={
                                    question.Answer.length === 0
                                      ? null
                                      : question.Answer[0].answer
                                  }
                                  onChange={(value) => {
                                    setIsLoadingChecklist(true)
                                    handleCheckChange(
                                      checklist,
                                      question.id,
                                      question.questions,
                                      value,
                                      question.priority
                                    )
                                  }}
                                  name={`radio${question.id}`}
                                  inline
                                >
                                  <Radio value={"Adequado"}>Adequado</Radio>
                                  <Radio value={"Não adequado"}>
                                    Não adequado
                                  </Radio>
                                  <Radio value={"Não aplicável"}>
                                    Não aplicável
                                  </Radio>
                                </RadioGroup>
                              </div>
                            );
                          })}
                        </div>

                        <div style={{ height: "0", overflow: "hidden" }}>
                          {chunkedArray.map((questions, chunkedIndex) => {
                            return (
                              <div
                                id="PDFExport"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1rem",
                                }}
                              >
                                {chunkedIndex === 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "1rem",
                                    }}
                                  >
                                    <Title>
                                      {checklistType == "Simplificado"
                                        ? `Checklist simplificado`
                                        : `Checklist de ${checklistType}`}
                                    </Title>

                                    <span
                                      style={{
                                        fontSize: "1.1rem",
                                        color: "#009EBE",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {checklist.checklistName}
                                    </span>
                                  </div>
                                )}

                                <div className={styles.questionDiv}>
                                  {questions.map((question, index) => {
                                    const questionNumber =
                                      chunkedIndex !== 0
                                        ? chunkedIndex * 26
                                        : 0;
                                    return (
                                      <div className={styles.questionBox}>
                                        <div>
                                          <span style={{ fontWeight: "bold" }}>
                                            {questionNumber + (index + 1)})
                                          </span>{" "}
                                          <span>{question.questions}</span>
                                        </div>

                                        <RadioGroup
                                          value={
                                            question.Answer.length == 0
                                              ? null
                                              : question.Answer[0].answer
                                          }
                                          name="radioList"
                                          inline
                                        >
                                          <Radio value={"Adequado"}>
                                            Adequado
                                          </Radio>
                                          <Radio value={"Não adequado"}>
                                            Não adequado
                                          </Radio>
                                          <Radio value={"Não aplicável"}>
                                            Não aplicável
                                          </Radio>
                                        </RadioGroup>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <strong
                                    style={{
                                      marginRight: "1rem",
                                      fontSize: "1.10rem",
                                      marginTop: "0.25rem",
                                    }}
                                  >
                                    Página {chunkedIndex + 1}/
                                    {chunkedArray.length}
                                  </strong>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* Actions Divs */}
        {isOpenActions || isFinished ? (
          <div className={styles.basicInfoDiv} id="PDFExport">
            <div className={styles.basicInfoTitle}>
              <Title>
                {checklistType == "Simplificado"
                  ? `Plano de ações simplificado`
                  : `Plano de ações de ${checklistType}`}
              </Title>

              <div
                onClick={() => setActionActive((prevActive) => !prevActive)}
                style={{
                  color: "#009EBE",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {actionActive == false || editCapex ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowUp />
                )}
              </div>
            </div>

            {actionActive || editCapex ? (
              <div
                style={{
                  borderTop: "1px solid #009EBE",
                  marginTop: "0.25rem",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  className={readOnly && !disableOpacity ? styles.disable : ""}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      style={
                        readOnly && !disableOpacity
                          ? {
                            opacity: "0.5",
                            pointerEvents: "none",
                            marginTop: "0.3rem",
                          }
                          : { marginTop: "0.3rem" }
                      }
                      onClick={() => handleMoreAction()}
                    >
                      Adicionar Ação
                    </Button>
                  </div>
                  <Table
                    sticky={true}
                    columns={columns}
                    data={actions}
                    showHeader={true}
                    emptyText="Nenhuma ação disponível."
                  />
                    {evidenceObservation 
                        ?   <p className={styles.observation}>
                                <FaInfoCircle/> Acesse a aplicação para visualizar as evidências das ações. 
                            </p>
                        : ""
                    }
                </div>

                {checklistType == "Pré-Projeto" ||
                  checklistType == "Simplificado" ? (
                  <div>
                    {moc.needCapexApproval ? (
                      <>
                        <Checkbox checked={moc.needCapexApproval}>
                          MOC necessita de aprovação de CAPEX
                        </Checkbox>
                        {editCapex ? (
                          <CapexEdit id={id} />
                        ) : (
                          <CapexApproval
                            id={id}
                            readOnly={viewMoc}
                            activeToggle={activeToogleCapex}
                            disableOpacity={disableOpacity}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {readOnly && !disableOpacity ? (
                          <div className={styles.disable}>
                            <Checkbox
                              checked={needsCapex}
                              onChange={(value, checked) => {
                                setNeedsCapex(checked);
                              }}
                            >
                              MOC necessita de aprovação de CAPEX
                            </Checkbox>
                            {needsCapex ? <NewCapex mocId={id} /> : null}
                          </div>
                        ) : (
                          <div>
                            <Checkbox
                              checked={needsCapex}
                              onChange={(value, checked) => {
                                setNeedsCapex(checked);
                              }}
                            >
                              MOC necessita de aprovação de CAPEX
                            </Checkbox>
                            {needsCapex ? <NewCapex mocId={id} /> : null}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : null}

                <div
                  className={readOnly && !disableOpacity ? styles.disable : ""}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1%",
                  }}
                >
                  <Button
                    style={
                      !actionFinished || !isFinished || needsCapex
                        ? { opacity: "0.5", pointerEvents: "none" }
                        : {}
                    }
                    title={
                      !actionFinished || !isFinished || needsCapex
                        ? "A conclusão só é permitida quando não houver nenhuma ação pendente e os checklists estiverem respondidos ou o capex aprovado"
                        : ""
                    }
                    onClick={() => submitStage()}
                  >
                    {checklistType == "Simplificado"
                      ? `Finalizar MOC simplificado`
                      : `Finalizar ${checklistType}`}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={actionModalOpen}
        onRequestClose={handleCloseActionModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(44, 42, 42, 0.707)",
          },
          content: {
            position: "absolute",
            width: "50rem",
            height: "25rem",
            top: "50%",
            transform: "translateY(-50%)",
            marginLeft: "auto",
            marginRight: "auto",
            border: "none",
            background: "#fff",
            overflow: "auto",
            borderRadius: "none",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "none",
          },
        }}
      >
        {isLoadingSubmitAction == true ? (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div></div>
        )}

        <div className="headerModal">
          <h3>Adicionar ação</h3>
          <h2 onClick={handleCloseActionModal}>
            <RiCloseFill />
          </h2>
        </div>

        <div style={{ padding: "2rem 2rem 2rem 2rem" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ width: "100%" }} className="inputContainer">
              <Label htmlFor="">Ação:</Label>
              <input
                type="text"
                value={actionTitle}
                onChange={(event) => {
                  setActionTitleError("");
                  setActionTitle(event.target.value);
                }}
              />
              {actionTitleError ? (
                <div className="inputError">{actionTitleError}</div>
              ) : null}
            </div>

            <div style={{ display: "flex", gap: "1rem" }}>
              <div style={{ width: "100%" }} className="inputContainer">
                <Label htmlFor="">Responsável:</Label>
                <AutoComplete
                  style={{ width: "100%" }}
                  data={users}
                  value={responsible}
                  onChange={(label, value) => {
                    setResponsibleError("");
                    setResponsibleId("")
                    handleResponsible(label);
                  }}
                />
                {responsibleError ? (
                  <div className="inputError">{responsibleError}</div>
                ) : null}
              </div>

              <div style={{ width: "100%" }} className="inputContainer">
                <Label htmlFor="">Validade:</Label>
                <input
                  type="date"
                  value={expirationDate}
                  onChange={(event) => {
                    setExpirationDateError("");
                    setExpirationDate(event.target.value);
                  }}
                />
                {expirationDateError ? (
                  <div className="inputError">{expirationDateError}</div>
                ) : null}
              </div>

              {stageId != 4 && (
                <div style={{ width: "100%" }} className="inputContainer">
                  <Label htmlFor="">Ação encerra em:</Label>
                  <SelectPicker
                    appearance="default"
                    style={{ width: "100%" }}
                    placeholder="&nbsp;"
                    data={stages}
                    value={selectedStage}
                    onChange={(value) => {
                      setAddStageError("");
                      setSelectedStage(value);
                    }}
                    searchable={false}
                    cleanable={false}
                  />
                  {addStageError ? (
                    <div className="inputError">{addStageError}</div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={isLoadingSubmitAction ? styles.disable : ""}
          style={{ position: "absolute", bottom: "1rem", right: "2rem" }}
        >
          <Button onClick={() => submitAction()}>Submeter</Button>
        </div>
      </Modal>
      <ModalActions
        func={() => getDataActions()}
        id={id}
        stageId={stageId}
        open={openMoreActionModal}
        onRequestClose={setOpenMoreActionModal}
      />
      <ModalAttachAction
        id={actionId}
        open={modalAction}
        onRequestClose={setModalAction}
        setLoading={setIsLoadingChecklist}
      />
    </>
  );
}
