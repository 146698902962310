import { useEffect, useState } from "react"

import { api } from "../../../services/api"
import Title from "../../Title/title"
import Swal from "sweetalert2";

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"
import styles from './validation.module.css'
import {  SelectPicker } from "rsuite";
import Table from "rc-table";
import moment from "moment";

export default function Actions({ id, codMoc, activeToggle }) {
    const [active, setActive] = useState(true)

    const [actions, setActions] = useState([])

    useEffect(() => {
        if (activeToggle) {
            setActive(true)
        }
    }, [activeToggle])

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get(`responsible/actions`)

        setActions(response.data)
    }


    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "Ação:",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 250,
            render: (row) => (<div>{row.name}</div>)
        },
        {
            title: "Etapa de conclusão:",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.Stages.name}</div>)
        },
        {
            title: "Responsável:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.Users.name}</div>)
        },
        {
            title: "Realização:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.realization ? moment(row.realization).format("DD/MM/YYYY") : '-'}</div>)
        },
        {
            title: "Checklist:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.checklistName} </div>)
        },
        {
            title: "Status:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => {
                return (
                    <SelectPicker
                        value={row.status}
                        data={statusData}
                        onChange={(value) => handleStatusChange(value, row.id)}
                        searchable={false}
                        cleanable={false}
                        placeholder="Selecione"
                        size="xs"
                    />
                )

            }
        },

    ]

    const statusData = [
        { label: 'Pendente', value: 'Pendente' },
        { label: 'Realizado', value: 'Realizado' },
    ]

    async function handleStatusChange(value, id) {
        let today = moment().format('YYYY-MM-DD')

        try {
            await api.put(`actions/${id}`, {
                "status": value,
                "realization": value === 'Realizado' ? today : null
            })

            await getData()
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }

    }


    return (
        <>
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Minhas Ações</Title>

                    <div onClick={() => setActive(prevActive => !prevActive)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {active == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>

                {active ?
                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <Table
                            sticky={true}
                            columns={columns}
                            data={actions}
                            showHeader={true}
                            emptyText="Nenhuma ação disponível"
                        />

                    </div>



                    : null}
            </div>
        </>
    )
}