import styles from './buttonPlus.module.css'

import Plus from '../../assets/plus.svg'

export default function ButtonPlus({ ...props }) {
    return (
        <>
            <button className={styles.buttonPlus} {...props}>
                <img src={Plus} alt="" style={{width:'1rem'}} />
                <span>{props.titulo}</span>
            </button>
        </>
    )
}