import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import logo from "../../assets/home/logoSyngenta.svg"
import user from "../../assets/home/user.png"
import dashs from "../../assets/home/dashs.svg"
import plus from "../../assets/home/plus.svg"
import search from "../../assets/home/search.svg"

import { api } from '../../services/api'
import Cookie from 'js-cookie';

import styles from "./home.module.css"

export default function Home() {
    const history = useHistory()

    const [name, setName] = useState('')
    const [isAdmin, setIsAdmin] = useState('')
    const [isApprover, setIsApprover] = useState('')
    const [loginMenu, setLoginMenu] = useState(false)

    async function getIdentify() { // identifica usuario logado e pega seus dados
        let response = await api.get('identify')
        setName(response.data.name)
        setIsAdmin(response.data.isAdmin)
        setIsApprover(response.data.isApprover)
    }

    const [approvalsNumber, setApprovalsNumber] = useState(0)
    const [checklistsNumber, setChecklistsNumber] = useState(0)
    const [actionsNumber, setActionsNumber] = useState(0)
    const [validationsNumber, setValidationsNumber] = useState(0)

    async function getData() {
        let responseChecklists = await api.get('mocs/checklist')
        let responseActions = await api.get('responsible/actions')
        let responseValidations = await api.get('mocs/validation')
        let responseApprovals = await api.get('mocapprovers')

        setChecklistsNumber(responseChecklists.data.length)
        setActionsNumber(responseActions.data.length)
        setValidationsNumber(responseValidations.data.length)
        setApprovalsNumber(responseApprovals.data.length)
    }

    useEffect(async () => {
        await getIdentify()
        await getData()
    }, [])


    function logout(){
        Cookie.remove('token');
        sessionStorage.clear()
        window.location.href = "/login";
    }

    return (
        <>
            <div className={styles.pageContainer}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img src={logo} alt="" />
                    </div>

                    <div className={styles.headerContent}>
                        <div className={styles.headerNav}>
                            {isAdmin || isApprover ? (
                                <div className={styles.headerNavBlock}>
                                    <div className={styles.numberCircle}><span>{approvalsNumber}</span></div>
                                    <div className={styles.headerNavOption}>
                                        <span className={styles.optionText} onClick={() => history.push('/minhas-aprovações')}>Minhas aprovações</span>
                                    </div>
                                </div>
                            ) : null}

                            <div className={styles.headerNavBlock}>
                                <div className={styles.numberCircle}><span>{checklistsNumber}</span></div>
                                <div className={styles.headerNavOption}>
                                    <span className={styles.optionText} onClick={() => history.push('/checklists')}>Checklists a responder</span>
                                </div>
                            </div>

                            <div className={styles.headerNavBlock}>
                                <div className={styles.numberCircle}><span>{actionsNumber}</span></div>
                                <div className={styles.headerNavOption}>
                                    <span className={styles.optionText} onClick={() => history.push('/ações')}>Minhas ações</span>
                                </div>
                            </div>


                            {isAdmin || isApprover ? (
                                <div className={styles.headerNavBlock}>
                                    <div className={styles.numberCircle}><span>{validationsNumber}</span></div>
                                    <div className={styles.headerNavOption}>
                                        <span className={styles.optionText} onClick={() => history.push('/validações')}>Minhas validações</span>
                                    </div>
                                </div>

                            ) : null}
                            {isAdmin ? (
                                <div className={styles.headerNavBlock}>
                                    <div className={`${styles.headerNavOption} ${styles.headerNavOptionLast}`}>
                                        <span className={styles.optionText} onClick={() => history.push('/configurações/admins')}>Configurações</span>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className={styles.headerUser}>
                        <div className={styles.logout} >
                            <img src={user} alt="" onClick={()=> setLoginMenu(c=>!c) } />
                            {loginMenu== true?
                                <button className={styles.logoutButton} onClick={()=> logout()} >Logout</button>:
                                null
                                }</div>
                            <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem', width: '100%' }}>
                                <span style={{ color: "#009EBE" }}>{name}</span>
                                <span style={{ color: "#FFFFFF" }}>
                                    {isAdmin == true ?
                                        "Administrador" :
                                        <>
                                            {isApprover == true ?
                                                "Aprovador"
                                                : "Usuário padrão"}
                                        </>
                                    }
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.mainTitle}>
                    <span style={{ fontWeight: '200' }}>Gestão de <span style={{ fontWeight: 'normal' }}>Mudanças</span></span>
                </div>

                <div className={styles.mainButtons}>
                    <div onClick={() => history.push("/novo-moc")} className={styles.mainButton}>
                        <img src={plus} alt="" />
                        <span>Novo MOC</span>
                    </div>
                    <div onClick={() => history.push("/buscar-moc")} className={styles.mainButton}>
                        <img src={search} alt="" />
                        <span>Buscar MOC</span>
                    </div>
                    <div onClick={() => history.push("/dashboards")} className={styles.mainButton}>
                        <img src={dashs} alt="" />
                        <span>Dashboards</span>
                    </div>
                </div>
            </div>
        </>

    )
}