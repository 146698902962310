import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import LoadingContent from "../../../components/loadingContent/loadingContent";
import NavPages from "../../../components/NavPages/navPages";
import BasicInfo from "../../../components/search-moc/basicInfo";
import ChecklistSupport from "../../../components/search-moc/checklistSupport";
import HseApproval from "../../../components/search-moc/hseApproval";
import MocSteps from "../../../components/search-moc/MocSteps";
import Steps from "../../../components/steps";
import { api } from "../../../services/api";
import exportToPDF from "../../../utils/exportToPdf";
import { printPage } from "../../../utils/pdfExport";

import styles from './viewMoc.module.css'
import Swal from "sweetalert2";

export default function ViewMoc() {
    const location = useLocation()
    const id = location.state.state
    const capexEdit = location.state.capexEdit
    const title = location.state.title

    // Estado que determina se o moc é completo ou simplificado
    const [isComplete, setIsComplete] = useState(true)
    const [responseMoc, setResponseMoc] = useState(true)

    const [stages, setStages] = useState([])

    const [activeDiv, setActiveDiv] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [disableDivsOpacity, setDisableDivsOpacity] = useState(false)

    const [evidenceObservation, setEvidenceObservation] = useState('')

    useEffect(async () => {
        await getMocStageData()
    }, [])

    async function getMocStageData() {
        try {
            setIsLoading(true)

            let response = await api.get(`/stages/moc/${id}`)
            let responseMoc = await api.get(`moc/${id}`)

            console.log(response.data)

            setResponseMoc(responseMoc.data.Steps.id)

            if (response.data.length != 3) {
                setIsComplete(false)
            }

            setStages(response.data)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function startExporting() {
        setActiveDiv(true)
        setDisableDivsOpacity(true)
        setEvidenceObservation(true)
        await Swal.fire({
            text: 'O carregamento do arquivo pode demorar alguns minutos.',
            icon: 'info',
            confirmButtonColor: "#009EBE",
            confirmButtonText: 'Gerar PDF',
            allowOutsideClick: false,
            didClose: () => {window.print()}
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        setDisableDivsOpacity(false)
        setEvidenceObservation(false)
    }
    
    return (
        <>
            <Steps
                id={id}
            />

            <NavPages title="Buscar MOC" secondTitle="Visualizar MOC" />

            <LoadingContent isLoading={isLoading}>
                <div className="exportPdfDiv">
                    <Button onClick={startExporting}>
                        Exportar para PDF</Button>
                </div>

                <div className='mainDiv'>
                    <div className={styles.stepGroup} id="PDFExport">
                        <BasicInfo
                            id={id}
                            activeToggle={activeDiv}
                            disableOpacity={disableDivsOpacity}
                        />

                        <ChecklistSupport
                            id={id}
                            activeToggle={activeDiv}
                            disableOpacity={disableDivsOpacity}
                        />

                        <HseApproval
                            id={id}
                            activeToggle={activeDiv}
                            disableOpacity={disableDivsOpacity}
                        />
                    </div>


                    {!stages.length > 0 ? <div></div> :
                        isComplete ?
                            <div>
                                < div className={styles.stepGroup}>
                                    <MocSteps
                                        id={id}
                                        stageId={1}
                                        readOnly={true}
                                        viewMoc={true}
                                        editCapex={capexEdit}
                                        activeToggle={activeDiv}
                                        disableOpacity={disableDivsOpacity}
                                        evidenceObservation={evidenceObservation}
                                    />
                                </div>

                                {stages[0]?.isCompleted ?

                                    <div className={styles.stepGroup}>
                                        <MocSteps
                                            id={id}
                                            stageId={2}
                                            readOnly={true}
                                            viewMoc={true}
                                            activeToggle={activeDiv}
                                            disableOpacity={disableDivsOpacity}
                                            evidenceObservation={evidenceObservation}
                                        />
                                    </div>

                                    : null}

                                {stages[1]?.isCompleted ?
                                    <div className={styles.stepGroup}>
                                        <MocSteps
                                            id={id}
                                            stageId={3}
                                            readOnly={true}
                                            activeToggle={activeDiv}
                                            disableOpacity={disableDivsOpacity}
                                            evidenceObservation={evidenceObservation}
                                        />
                                    </div>
                                    : null}
                            </div>

                            :

                            < div className={styles.stepGroup}>
                                <MocSteps
                                    id={id}
                                    stageId={4}
                                    readOnly={true}
                                    activeToggle={activeDiv}
                                    disableOpacity={disableDivsOpacity}
                                    evidenceObservation={evidenceObservation}
                                />
                            </div>

                    }
                </div>
            </LoadingContent>
        </>
    )
}