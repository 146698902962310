import {BsPlusLg} from 'react-icons/bs'

import styles from './addButton.module.css'

export default function AddButton({ ...props }) {
    return (
        <>
            <div className={styles.addButton} {...props}>{<BsPlusLg />}</div>
        </>
    )
}