import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Header from "../../components/Header/header";
import { NewMocProvider } from "../../providers/new-moc";

import Checklist from "./new-moc-checklist";
import NewMoc from "./new-moc-main/new-moc";

export default function NewMocHome() {
  let { path } = useRouteMatch();

  return (
    <>
      <NewMocProvider>
        <Header />

        <div>
          <Switch>
            <Route path={`${path}/`} exact component={NewMoc} />
            <Route path={`${path}/checklist`} component={Checklist} />
          </Switch>
        </div>
      </NewMocProvider>
    </>
  )

}