import React, { useState } from "react";

import { BsPlusLg } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa';
import { SelectPicker } from "rsuite";
import moment from 'moment';
import Table from "rc-table";
import Swal from "sweetalert2";
import Label from "../Label/label";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styles from './style.module.css'
import { api } from "../../services/api";

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default function MocParticipants(props) {


  const [nameParticipants, setNameParticipants] = useState('')
  const [stageParticipants, setStageParticipants] = useState('')
  const [sectionParticipants, setSectionParticipants] = useState('')
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [nameParticipantsError, setNameParticipantsError] = useState('')
  const [stageParticipantsError, setStageParticipantsError] = useState('')
  const [sectionParticipantsError, setSectionParticipantsError] = useState('')
  const [dateParticipantsError, setDateParticipantsError] = useState('')


  function validation() {
    let validation = true

    if (nameParticipants === '') {
      setNameParticipantsError('Adicione o nome do participante.')
      validation = false
    }

    if (stageParticipants === '') {
      setStageParticipantsError('Selecione a etapa que o participante ajudou.')
      validation = false
    }

    if (sectionParticipants === '') {
      setSectionParticipantsError('Selecione o setor do participante.')
      validation = false
    }

    if (startDate === null || endDate === null ) {
      setDateParticipantsError('Selecione a data de participação.')
      validation = false
    }

    const findSection = props.sections.find(x => x.value == sectionParticipants)
    const findStages = props.stages.find(x => x.value == stageParticipants)

    const alreadyExists = props.arrayMocParticipants.some(item => (
      item.endDate === moment(
        endDate
      ).format('DD/MM/YYYY') &&
      item.sectionId === findSection.label &&
      item.mocId === props.mocId &&
      item.name === nameParticipants &&
      item.stageId === findStages.label &&
      item.startDate === moment(
        startDate
      ).format('DD/MM/YYYY')
    ));  
    if (alreadyExists) {
      Toast.fire({
        icon: 'error',
        text: `Ja existe um participante adicionado com essas informações.`
      })
      validation = false
    }

    return validation
  }

  async function handleAddParticipants() {
    const valid = validation();
    if (!valid) {
      return;
    }
    try {

      await api.post(`/mocparticipants`, {
        "name": nameParticipants,
        "stageId": stageParticipants,
        "mocId": props.mocId,
        "sectionId": sectionParticipants,
        "startDate": moment(
          startDate
        ).format('YYYY-MM-DD'),
        "endDate": moment(
          endDate
        ).format('YYYY-MM-DD')
      })

      const findSection = props.sections.find(x => x.value == sectionParticipants)
      const findStages = props.stages.find(x => x.value == stageParticipants)

      const tableMocParticipants = []
      tableMocParticipants.push({
        "name": nameParticipants,
        "stageId": findStages.label,
        "mocId": props.mocId,
        "sectionId": findSection.label,
        "startDate": moment(
          startDate
        ).format('DD/MM/YYYY'),
        "endDate": moment(
          endDate
        ).format('DD/MM/YYYY')
      })

      props.setArrayMocParticipants(
        props.arrayMocParticipants.concat(tableMocParticipants)
      )
      setNameParticipants('')
      setStageParticipants('')
      setSectionParticipants('')
      setDateRange('')

    } catch (error) {
      Toast.fire({
        icon: 'error',
        text: `Ocorreu um erro.Tente novamente mais tarde`
      })
    }
  }

  async function handlerRemoveParticipants(row) {

    props.setArrayMocParticipants(
      props.arrayMocParticipants.filter(x => x.id != row.id),
    )

    await api.delete(`/mocparticipants/${row.id}`)
  }

  const columnsParticipants = [
    {
      title: "Participantes",
      dataIndex: "",
      key: "",
      align: "left",
      width: 1000,
      render: (row) => (<div>{row.name}</div>)
    },
    {
      title: "Setor",
      dataIndex: "",
      key: "",
      align: "left",
      width: 1000,
      render: (row) => (
        <div>{
          row.Section?.name || row.sectionId
        }</div>)
    },
    {
      title: "Data de inicio",
      dataIndex: "",
      key: "",
      align: "left",
      width: 1000,
      render: (row) => (<div>{row.startDate}</div>)
    },
    {
      title: "Data de fim",
      dataIndex: "",
      key: "",
      align: "left",
      width: 1000,
      render: (row) => (<div>{row.endDate}</div>)
    },
    {
      title: "Fase do MOC",
      dataIndex: "",
      key: "",
      align: "left",
      width: 1000,
      render: (row) => (
        <div>{
          row.Stages?.name || row.stageId
        }</div>)
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "left",
      width: 150,
      render: (row) => (<div><FaTrashAlt onClick={() => handlerRemoveParticipants(row)} /></div>)
    }
  ]
  return (
    <>
      <div className={styles.mainContainer}>
        <div className="inputContainer">
          <Label htmlFor="">Nome do participante</Label>

          <input value={nameParticipants}
            onChange={(e) => {
              setNameParticipantsError('');
              setNameParticipants(e.target.value)
            }}
            type="text" />
          {nameParticipantsError ?
            (<div className="inputError">
              {nameParticipantsError}
            </div>) : null
          }
        </div>
        <div className="inputContainer">
          <Label htmlFor="">Setor</Label>
          <SelectPicker
            appearance="default"
            style={{ width: '100%' }}
            data={props.sections}
            placeholder="Selecione"
            value={sectionParticipants}
            onChange={(value) => {
              setSectionParticipantsError('')
              setSectionParticipants(value)
            }}
          />
          {sectionParticipantsError ?
            (<div className="inputError">
              {sectionParticipantsError}
            </div>)
            : null}
        </div>
        <div className="inputContainer">
          <Label htmlFor="">Data de participação</Label>
          <DatePicker
               selectsRange={true}
               startDate={startDate}
               endDate={endDate}
               onChange={(update) => {
                 setDateRange(update);
                 setDateParticipantsError('')
               }}
               isClearable={true}
          />
          {dateParticipantsError ?
            (<div className="inputError">
              {dateParticipantsError}
            </div>) : null}
        </div>
        <div className="inputContainer">
          <Label htmlFor="">Fase do MOC</Label>
          <SelectPicker
            appearance="default"
            style={{ width: '100%' }}
            data={props.stages}
            placeholder="Selecione"
            value={stageParticipants}
            onChange={(value) => {
              setStageParticipantsError('')
              setStageParticipants(value)
            }}
          />
          {stageParticipantsError ?
            (<div className="inputError">
              {stageParticipantsError}</div>)
            : null}
        </div>

        <button
          onClick={() => handleAddParticipants()}
          className={styles.addButton}>
          <BsPlusLg />
        </button>
      </div>
      <div>
        <Table
          sticky={true}
          columns={columnsParticipants}
          data={props.arrayMocParticipants}
          showHeader={true}
          emptyText="Nenhum anexo disponível"
        />
      </div>
    </>
  )
}