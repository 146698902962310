import React, { useState, useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import pic from "../../../../assets/login.svg";
import styles from "../../login.module.css";
import { api } from "../../../../services/api";

import { useHistory } from "react-router-dom"

export default function Login() {
  const history = useHistory()

  const [email, setEmail] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [captcha, setCaptcha] = useState('');

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

async function onSubmit(event) { 
    event.preventDefault();
    try {
      let response = await api.post('reset', {
        "email": email,
      },
      {
        params: {
        recaptcha: captcha
      }})

      if (response.status == 200) {        
        Swal.fire({
          title: 'Alteração de senha',
          text: "Um link foi enviado para seu e-mail. Após o recebimento, sua validade é de 12 horas.",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#46B8D3',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/login";    
          }
        })
      }
    }  catch (err) {
      let erros = err.response.data.errors?.map(x => {
          return {
              erro: x.msg
          }
      })
      window.grecaptcha.reset();
      setIsVerify(false)
      if(erros[0].erro.toString() == 'Requisição ja efetuada, verifique seu email'){
        Swal.fire({
          title: 'Alteração de senha',
          text: "Um link já foi enviado para seu e-mail. Por favor verifique sua caixa de e-mails e spam. Somente é possível solicitar um novo link após o período de expiração de 12 horas.",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#46B8D3',
          confirmButtonText: 'Ok'
        })
      }else{
        Toast.fire({
          icon: 'error',
          title: `${erros[0].erro.toString()}`
      })
     }
  }
}

function onChange(event){
  setIsVerify(true)
  setCaptcha(event)
}

return (
  <form onSubmit={onSubmit}>
    <div className={styles.loginBackground}>
      <div className={styles.loginleft}>
        <h1>Gestão de <strong> Mudanças</strong></h1>
        <button  onClick={() => history.push('/login')}>Entrar</button>
        <img src={pic} />
      </div>
      <div className={styles.loginright}>
        <h1>Reset de senha</h1>
        <div>
          <p>Email:</p>
          <input type="text"
            name="email"
            onChange={(e)=> setEmail(e.target.value)}
            value={email}
            />
        </div>
        <div className={styles.recaptcha}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={onChange}
          />
        </div>
        <button disabled={!isVerify}  type="submit">Envio</button>
      </div>
    </div>
  </form>
);
}