import React, { useState, useContext } from 'react';
import { useHistory, Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

import  {StoreContext}  from '../../../../providers/login';
import pic from "../../../../assets/login.svg";
import styles from "../../login.module.css";
import { api } from "../../../../services/api";
import {  AiFillEyeInvisible, AiFillEye } from "react-icons/ai";


export default function Login() {
  const history = useHistory()

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isVerify, setIsVerify] = useState(false);
  const [captcha, setCaptcha] = useState('');

  const { token, setToken} = useContext(StoreContext);

  
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  async function onSubmit(event) { // autentica o usuario e salva o token e redireciona para a home
    event.preventDefault();
    try {
      let response = await api.post('authenticate', {
        "email":email,
        "password": password
      },
      {
        params: {
        recaptcha: captcha
      }})

      if (response.status == 200) {
          setToken(response.data.token.replace("", ""))
        
        Toast.fire({
          icon: 'success',
          title: 'Login efetuado com sucesso!'
        })
        window.location.href = "/";
        setEmail('')
        setPassword('')
      }
    } catch (err) {
      window.grecaptcha.reset();
      setIsVerify(false)
      let erros = err.response.data.errors?.map(x => {
        return {
            erro: x.msg
        }
    })
     if(erros[0].erro.toString() == 'Requisição ja efetuada, verifique seu email'){
      Swal.fire({
        title: 'Alteração de senha',
        text: "Um link já foi enviado para seu e-mail. Por favor verifique sua caixa de e-mails e spam. Somente é possível solicitar um novo link após o período de expiração de 12 horas.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#46B8D3',
        confirmButtonText: 'Ok'
      })
      }else{
        Toast.fire({
          icon: 'error',
          title: `${erros[0].erro.toString()}`
      })
    }
    }
}

function onChange(event){
  setIsVerify(true)
  setCaptcha(event)
}
return (
  <form onSubmit={onSubmit}>
    <div className={styles.loginBackground}>
      <div className={styles.loginleft}>
        <h1>Gestão de <strong> Mudanças</strong></h1>
        <button onClick={() => history.push('/login/registro')}>Registrar</button>
        <img src={pic} />
      </div>
      <div className={styles.loginright}>
        <h1>Entrar</h1>
        <div>
          <p>Email:</p>
          <input type="text"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div>
          <p>Senha:</p>
          <input 
            type={passwordShown ? "text" : "password"} 
            name="senha"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            />
          {passwordShown == true?
           <AiFillEyeInvisible color='#000'   onClick={togglePasswordVisiblity}/>:
           <AiFillEye color='#000'  onClick={togglePasswordVisiblity}/>}
        </div>
        <Link to='login/reset'>Esqueci minha senha</Link>
        <div className={styles.recaptcha}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={onChange}
          />
        </div>
        <button disabled={!isVerify} type="submit">Entrar</button>

      </div>
    </div>
  </form>
);
}