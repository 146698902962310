import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import  {StoreProvider}  from "../../providers/login";
import login from './login/login-main/login'
import registro from './registro/registro-main/register'
import reset from './reset/reset-main/reset'
import resetPassword from './reset/reset-password/reset'

import register from './registro/registro-main/register'
import registerPassword from './registro/registro-password/register'

export default function NewMocHome() {
  let { path } = useRouteMatch();

  return (
    <>
      <StoreProvider>
        <div>
          <Switch>
            <Route path={`${path}/`} exact component={login} />
            
            <Route path={`${path}/registro`} exact component={register} />
            <Route path={`${path}/registro/senha/:id`} exact component={registerPassword} />

            <Route path={`${path}/reset`} exact component={reset} />
            <Route path={`${path}/reset/senha/:id`} exact component={resetPassword} />

          </Switch>
        </div>
      </StoreProvider>
    </>
  )

}