import { useState, useEffect } from 'react'
import Header from "../../components/Header/header"
import NavPages from "../../components/NavPages/navPages"
import styles from "./dashboards.module.css"

import Chart from "react-apexcharts";
import { api } from '../../services/api';
import { DateRangePicker, SelectPicker } from 'rsuite';
import Label from '../../components/Label/label';
import moment from 'moment'

export default function Dashboards() {
    const [sites, setSites] = useState([])
    const [selectedSiteId, setSelectedSiteId] = useState('')
    const [selectedDateRange, setSelectedDateRange] = useState([])

    const [totalMocs, setTotalMocs] = useState([])

    const [actions, setActions] = useState([])
    const [createdActions, setCreatedActions] = useState(0)
    const [concludedActions, setConcludedActions] = useState(0)

    const [pendingMocs, setPendingMocs] = useState(0)

    useEffect(async () => {
        await getSites()
        await getActionsPending()
        await getMocsSites()
        await getMocsPending()
    }, [])

    async function getSites() {
        let sitesResponse = await api.get('sites')

        let sitesArray = []

        sitesResponse.data.map(site => {
            sitesArray.push({ label: site.name, value: site.id })
        })

        setSites(sitesArray)
    }

    const [approvalNumber, setApprovalNumber] = useState(0)
    const [checklistNumber, setChecklistNumber] = useState(0)
    const [actionNumber, setActionNumber] = useState(0)
    const [finishedNumber, setFinishedNumber] = useState(0)

    async function getMocsSites(id) {
        if (id) {
            let mocsSitesResponse = await api.get(`mocs/site/${id}`)
            setTotalMocs(mocsSitesResponse.data.length)

            let approved = mocsSitesResponse.data.filter(moc => moc.Steps.id == 7).length
            let atChecklists = mocsSitesResponse.data.filter(moc => moc.Steps.id == 3).length
            let atActions = mocsSitesResponse.data.filter(moc => moc.Steps.id == 3).length
            let finished = mocsSitesResponse.data.filter(moc => moc.Steps.id == 12).length

            setApprovalNumber(approved)
            setChecklistNumber(atChecklists)
            setActionNumber(atActions)
            setFinishedNumber(finished)
        } else {
            let mocsSitesResponse = await api.get('mocs/site')
            setTotalMocs(mocsSitesResponse.data.length)

            let approved = mocsSitesResponse.data.filter(moc => moc.Steps.id == 7).length
            let atChecklists = mocsSitesResponse.data.filter(moc => moc.Steps.id == 3).length
            let atActions = mocsSitesResponse.data.filter(moc => moc.Steps.id == 3).length
            let finished = mocsSitesResponse.data.filter(moc => moc.Steps.id == 12).length

            setApprovalNumber(approved)
            setChecklistNumber(atChecklists)
            setActionNumber(atActions)
            setFinishedNumber(finished)
        }
    }

    async function getMocsPending(id) {
        if (id) {
            let mocsPendingResponse = await api.get(`mocs/pending/site/${id}`)
            setPendingMocs(mocsPendingResponse.data.length)
        } else {
            let mocsPendingResponse = await api.get('mocs/pending/site')
            setPendingMocs(mocsPendingResponse.data.length)
        }
    }

    async function getActionsPending(id) {
        if (id) {
            let actionsSitesResponse = await api.get(`actions/site/${id}`)
            setActions(actionsSitesResponse.data)
            setCreatedActions(actionsSitesResponse.data.length)
            setConcludedActions(actionsSitesResponse.data.filter(x => x.status == 'Realizado').length)
        } else {
            let actionsSitesResponse = await api.get('actions/site')
            setActions(actionsSitesResponse.data)
            setCreatedActions(actionsSitesResponse.data.length)
            setConcludedActions(actionsSitesResponse.data.filter(x => x.status == 'Realizado').length)
        }
    }


    const mocPhaseOptions = {
        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: ["Aprovação", "Respondendo checklists", "Finalizados"]
            },
            colors: ["#009EBE"],

        },
        series: [
            {
                data: [approvalNumber, checklistNumber, finishedNumber],
                name: ['Mocs']
            }
        ]
    };

    const pendingActionsOptions = {
        options: {
            chart: {
                width: "100%",
                height: 500,
                type: 'radialBar',
            },
            labels: [''],
            colors: ["#009EBE"],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        startAngle: -90,
                        endAngle: 90,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            fontSize: "30px",
                            show: true
                        }
                    }
                }
            },

        },
    }

    const seriesActions = [createdActions == 0 ? 0 : Math.round((concludedActions/createdActions ) * 100)]
    const seriesPendingMocs = [totalMocs == 0 ? 0 : Math.round((pendingMocs / totalMocs) * 100)]


    async function handleSelectedSite(value) {
        if (value == undefined || value == null) {
            await getActionsPending()
            await getMocsPending()
            await getMocsSites()
            setSelectedDateRange([])
        }
        setSelectedSiteId(value)
        getActionsPending(value)
        getMocsPending(value)
        getMocsSites(value)
        setSelectedDateRange([])
    }

    async function handleSelectedDateRange(value) {
        if (value == undefined || value == null) {
            await getActionsPending()
            setSelectedDateRange([])
        } else {
            setSelectedDateRange(value)
            let filteredPendingActions = actions.filter(action => moment(action.validity).isSameOrAfter(value[0]) && moment(action.validity).isSameOrBefore(value[1]) && action.status == "Pendente")
            let filteredCreatedActions = actions.filter(action => moment(action.validity).isSameOrAfter(value[0]) && moment(action.validity).isSameOrBefore(value[1]))

            setConcludedActions(filteredPendingActions.length)
            setCreatedActions(filteredCreatedActions.length)
        }
    }

    return (
        <>
            <Header />

            <NavPages title="Dashboards" goHome={true} />

            <div className="mainDiv">

                <div className={styles.containerDiv}>
                    <div style={{ width: '30%' }} className="inputContainer">
                        <Label htmlFor="">Site</Label>
                        <SelectPicker
                            appearance="default"
                            style={{ width: '100%' }}
                            placeholder="Todos os sites"
                            data={sites}
                            value={selectedSiteId}
                            onChange={(value) => {
                                handleSelectedSite(value)
                            }}
                        />
                    </div>
                    <div className={styles.chartsDiv}>
                        <div className={styles.chartContainer}>
                            <div style={{ width: '50%' }} className="inputContainer">
                                <Label htmlFor="">Filtro de data</Label>
                                <DateRangePicker
                                    placeholder="Selecione o período"
                                    format="dd-MM-yyyy"
                                    value={selectedDateRange}
                                    onChange={(value) => {
                                        handleSelectedDateRange(value)
                                    }}

                                />
                            </div>
                            <div style={{  justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2%' }}>
                                <span style={{ marginBottom: '1%' }}>Ações pendentes</span>
                                <Chart
                                    options={pendingActionsOptions.options}
                                    series={seriesActions}
                                    type="radialBar"
                                />
                                <span>{`${createdActions} ações criadas/${concludedActions} ações concluída`}</span>
                            </div>
                        </div>
                        <div className={styles.chartContainer}>
                            <span style={{ marginBottom: '1%' }}>MOCS por fase</span>
                            <Chart
                                options={mocPhaseOptions.options}
                                series={mocPhaseOptions.series}
                                type="bar"
                                height={250}
                            />
                        </div>

                        <div className={styles.chartContainer}>
                            <span style={{ marginBottom: '1%' }}>MOCS pendentes de aprovação</span>
                            <Chart
                                options={pendingActionsOptions.options}
                                series={seriesPendingMocs}
                                type="radialBar"
                            />
                            <span>{`${pendingMocs} MOCS pendentes/${totalMocs} MOCS criados`}</span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}