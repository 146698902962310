import { useEffect, useState } from "react"

import styles from './basicInfo.module.css'

import Title from "../../Title/title"
import Label from "../../../components/Label/label";
import { FaTimesCircle, FaFilePdf, FaFileExcel } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip';
import { useEditMoc } from "../../../providers/edit-moc";
import ViewPic from '../../viewPic'

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"
import { Checkbox, SelectPicker } from "rsuite";
import Table from "rc-table";
import { api } from "../../../services/api";
import moment from "moment";
import MocParticipants from "../../mocparticipants";

export default function BasicInfo({ id, activeToggle, disableOpacity, isEdit }) {
    const [titulo, setTitulo] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [data, setData] = useState('')
    const [custo, setCusto] = useState('')
    const [fiscal, setFiscal] = useState('')
    const [site, setSite] = useState('')
    const [categoria, setCategoria] = useState('')
    const [tipo, setTipo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const [hse, setHse] = useState(false)
    const { setUploadFotos } = useEditMoc()
    const { setIsOpenModalPic } = useEditMoc()

    const [activeBasic, setActiveBasic] = useState(false)

    const [mocParticipants, setMocParticipants] = useState([])
    const [section, setSection] = useState([])
    const [stages, setStages] = useState([])

    useEffect(() => {
        if (activeToggle) {
            setActiveBasic(true)
        }
    }, [activeToggle])

    useEffect(async () => {
        await getData()
        setIsOpenModalPic(false)
    }, [])

    async function getData() {
        let responseBasic = await api.get(`/moc/${id}`)

        setTitulo(responseBasic.data.title)
        setData(moment(responseBasic.data.openDate).format('YYYY-MM-DD'))
        setCusto(responseBasic.data.initialBudget)
        setSolicitante(responseBasic.data.Users.name)
        setFiscal(responseBasic.data.fiscalYear)
        setHse(responseBasic.data.hseSupport)
        setDescricao(responseBasic.data.description)
        setSite(responseBasic.data.Sites.name)
        setCategoria(responseBasic.data.Categories.name)
        setTipo(responseBasic.data.Types.name)

        let responseAttachments = await api.get(`mocAttactments/${id}`)

        let fotos = []
        let anexos = []

        responseAttachments.data.map(attachment => {
            if (attachment.type == 'image/png' || attachment.type == 'image/jpeg') {
                fotos.push(attachment)
            } else {
                anexos.push(attachment)
            }
        })

        setFotos(fotos)
        setAnexos(anexos)

        const findAllSection = await api.get('/section')
        const findAllStages = await api.get('/stages')

        const sectionArray = []
        const stagesArray = []

        findAllStages.data.map(x => {
            if (responseBasic.data.modelMoc === 'Simplificado') {
                if (x.name === 'Simplificado') {
                    stagesArray.push({ label: x.name, value: x.id });
                }
            } else if (responseBasic.data.modelMoc === 'Completo') {
                if (x.name === 'Comissionamento' || x.name === 'Detalhamento' || x.name === 'Pré-Projeto') {
                    stagesArray.push({ label: x.name, value: x.id });
                }
            }
        })

        findAllSection.data.map(x => {
            return sectionArray.push({ label: x.name, value: x.id })
        })

        const responseMocparticipants = await api.get(`/mocparticipants/${id}`)
        setMocParticipants(responseMocparticipants.data)
        setStages(stagesArray)
        setSection(sectionArray)

    }

    function openImg(photo) {
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }

    const columnsAttachments = [
        {
            title: "Anexos",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.name}</div>)
        }
    ]

    const columnsParticipants = [
        {
            title: "Participantes",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.name}</div>)
        },
        {
            title: "Setor",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.Section.name}</div>)
        },
        {
            title: "Fase do MOC",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.Stages.name}</div>)
        },
        {
            title: "Data de inicio",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.startDate}</div>)
        },
        {
            title: "Data de fim",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.endDate}</div>)
        }
    ]

    return (
        <div className={styles.basicInfoDiv}>

            <div className={styles.basicInfoTitle}>
                <Title>Informações básicas</Title>

                <div onClick={() => setActiveBasic(prevActiveBasic => !prevActiveBasic)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    {activeBasic == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </div>
            </div>

            {activeBasic ?
                <div className={`${styles.basicInfo} ${disableOpacity ? '' : styles.opacity}`} >
                    <div className={styles.inputDiv}>
                        <div style={{ width: '100%' }} className="inputContainer">
                            <Label htmlFor="">Título do MOC</Label>
                            <input value={titulo} disabled type="text" />
                        </div>
                        <div style={{ width: '25%' }} className="inputContainer">
                            <Label htmlFor="">Solicitante</Label>
                            <input value={solicitante} disabled type="text" />
                        </div>
                        <div style={{ width: '25%' }} className="inputContainer">
                            <Label htmlFor="">Data de abertura</Label>
                            <input value={data} disabled type="date" />
                        </div>
                    </div>

                    <div className={styles.inputDiv}>
                        <div style={{ width: '40%' }} className="inputContainer">
                            <Label htmlFor="">Custo previsto</Label>
                            <input value={custo} disabled type="text" />
                        </div>
                        <div style={{ width: '40%' }} className="inputContainer">
                            <Label htmlFor="">Fiscal year de implementação</Label>
                            <input value={fiscal} disabled type="text" />
                        </div>
                        <div style={{ width: '25%' }} className="inputContainer">
                            <Label htmlFor="">Site</Label>
                            <SelectPicker
                                readOnly
                                appearance="default"
                                style={{ width: '100%' }}
                                placeholder={site}
                                value={site}
                            />

                        </div>
                        <div style={{ width: '25%' }} className="inputContainer">
                            <Label htmlFor="">Categoria</Label>
                            <SelectPicker
                                readOnly
                                appearance="default"
                                style={{ width: '100%' }}
                                placeholder={categoria}
                            />

                        </div>
                        <div style={{ width: '20%' }} className="inputContainer">
                            <Label htmlFor="">Tipo</Label>
                            <SelectPicker
                                readOnly
                                appearance="default"
                                style={{ width: '100%' }}
                                placeholder={tipo}
                            />
                        </div>
                    </div>

                    <div className="inputContainer" style={{ width: '100%', marginTop: '1%' }} >
                        <Label htmlFor="">Descrição da mudança:</Label>
                        <textarea value={descricao} disabled name="" id="" cols="30" rows="5"></textarea>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1rem' }}>
                            <Checkbox disabled >Desejo apoio do HSE no preenchimento dos checklists</Checkbox>
                        </div>
                    </div>

                    <div className={styles.attachmentsDiv}>
                        {anexos.length != 0 || fotos.length != 0 ?
                            <>
                                <div className={styles.attachmentsDiv}>
                                    <div className={styles.imgHeader}>
                                        <span>Anexos</span>
                                    </div>
                                    {anexos.length != 0 ?
                                        <div className={styles.imgDiv}>
                                            {anexos.map(x => {
                                                return (
                                                    <div className={styles.imgContainer}>
                                                        {x.type.split('/')[1] == 'pdf' ?
                                                            <a href={x.link}><FaFilePdf data-tip data-for={`pdf${x.id}`} color='#000' size={70} />
                                                                <ReactTooltip place="top" effect="solid" id={`pdf${x.id}`}>
                                                                    <span>{x.name}</span>

                                                                </ReactTooltip>
                                                            </a> :
                                                            <a href={x.link}>
                                                                <FaFileExcel data-tip data-for={`excel${x.id}`} color='#000' size={70} />
                                                                <ReactTooltip place="top" effect="solid" id={`excel${x.id}`}>
                                                                    <span>{x.name}</span>
                                                                </ReactTooltip>
                                                            </a>}
                                                    </div>
                                                )

                                            })
                                            }
                                        </div>
                                        :
                                        <div className={styles.pdfDiv}>
                                            <Table
                                                sticky={true}
                                                showHeader={false}
                                                emptyText="Nenhum anexo disponível"
                                            />
                                        </div>}

                                    <div className={styles.imgHeader}>
                                        <span>Imagens</span>
                                    </div>
                                    {fotos.length != 0 ?
                                        <div className={styles.imgDiv}>

                                            {fotos.map(x => {
                                                return (
                                                    <div className={styles.imgContainer}>
                                                        <img className={styles.img} src={x.link} alt="" onClick={() => openImg(x.link)} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        :
                                        <div className={styles.pdfDiv}>
                                            <Table
                                                sticky={true}
                                                showHeader={false}
                                                emptyText="Nenhum anexo disponível"
                                            />
                                        </div>}
                                </div>
                            </>
                            :
                            <div className={styles.pdfDiv}>
                                <Table
                                    sticky={true}
                                    columns={columnsAttachments}
                                    showHeader={true}
                                    emptyText="Nenhum anexo disponível"
                                />
                            </div>}
                        <ViewPic />
                    </div>

                    <div style={{ opacity: '1 !important' }} >
                        {isEdit === true ?
                        <>
                            <div style={{  marginTop: '1rem'}} className={styles.basicInfoTitle}>
                                <Title>Adiconar participantes</Title>
                            </div>
                            <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                <MocParticipants
                                    sections={section}
                                    stages={stages}
                                    mocId={id}
                                    arrayMocParticipants={mocParticipants}
                                    setArrayMocParticipants={setMocParticipants}
                                />
                            </div>
                        </>:

                            <Table
                                sticky={true}
                                columns={columnsParticipants}
                                data={mocParticipants}
                                showHeader={true}
                                emptyText="Nenhum anexo disponível"
                            />}

                    </div>

                </div>
                :
                null
            }

        </div >
    )
}