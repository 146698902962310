import { IoChevronBack, IoChevronForward } from 'react-icons/io5'

import { useHistory } from 'react-router-dom'

import styles from "./navPages.module.css"

import { MdOutlinePictureAsPdf } from 'react-icons/md'

export default function NavPages(props) {

  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <div className={styles.navPagesContainer}>
        <div className={styles.navPages}>
          <h4 onClick={props.goHome == true ? () => history.push('/') : goBack} style={{ marginTop: "0.25rem" }}>{<IoChevronBack />}</h4>
          <h3>{props.title}</h3>
          {props.secondTitle ? <h4 style={{ marginTop: "0.25rem" }}><IoChevronForward /></h4> : null}
          <h3>{props.secondTitle}</h3>
        </div>

        {props.isPdf ?
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <button className={styles.button} ><MdOutlinePictureAsPdf />Exportar para PDF</button>
            </div>
            :
            null}
      </div>

    </>
  )
}