import axios from 'axios';
import Cookie from 'js-cookie';

const TOKENAuth = Cookie.get("token")

export const api = axios.create({
    baseURL: process.env.REACT_APP_HOSTNAME_BACKEND,
    headers: { 'Authorization': 'Bearer ' + TOKENAuth?.replaceAll('"', "") }
})

export const addAttachment = async (tableUrl, objToAdd, id) => {

    const formData = new FormData();

    for (const name in objToAdd) {

        if (Object.hasOwnProperty.call(objToAdd, name)) {

            formData.append(name, objToAdd[name]);

        }

    }
    const res = await fetch(`${process.env.REACT_APP_HOSTNAME_BACKEND}${tableUrl}`, {

        method: "POST",

        headers: { 'Authorization': 'Bearer ' + TOKENAuth?.replaceAll('"', "") },

        body: formData,

    });

    const data = res;

    return data;

};


export const editAttachment = async (tableUrl, objToAdd, id) => {

    const formData = new FormData();
    for (const name in objToAdd) {
        if (Object.hasOwnProperty.call(objToAdd, name)) {
            formData.append(name, objToAdd[name]);
        }
    }
    const res = await fetch(`${process.env.REACT_APP_HOSTNAME_BACKEND}${tableUrl}/${id}`, {
        method: "PUT",
        headers: { 'Authorization': 'Bearer ' + TOKENAuth?.replaceAll('"', "") },
        body: formData,
    });

    const data = res;
    return data;

};