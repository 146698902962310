import React from "react"

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoutes from './Routes/Private'

import Home from "./pages/home/home";
import MyApprovalsHome from "./pages/my-approvals/my-approvals-home";
import NewMocHome from "./pages/new-moc/new-moc-home";
import ConfigHome from "./pages/configuration/configHome";
import SearchMoc from "./pages/search-moc/search-moc";
import Login from "./pages/login/index";

import ChecklistsHome from "./pages/checklists/checklists-home";
import Actions from "./pages/my-actions/my-actions";
import Validations from "./pages/my-validations/my-validations";
import Dashboards from "./pages/dashboards";

import {EditMocProvider} from "./providers/edit-moc";

export default function Routes() {
    return (
        <BrowserRouter>
        <EditMocProvider>
            <PrivateRoutes path="/" exact component={Home} />
            <PrivateRoutes path="/novo-moc" component={NewMocHome} />
            <PrivateRoutes path="/minhas-aprovações" component={MyApprovalsHome} />
            <PrivateRoutes path="/configurações" component={ConfigHome} />
            <PrivateRoutes path="/buscar-moc" component={SearchMoc} />
            <PrivateRoutes path="/checklists" component={ChecklistsHome} />
            <PrivateRoutes path="/ações" component={Actions} />
            <PrivateRoutes path="/validações" component={Validations} />
            <PrivateRoutes path="/dashboards" component={Dashboards} />
            </EditMocProvider>
            <Route path="/login" component={Login} />
        </BrowserRouter>
    )
}