import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import MaterialTable from 'material-table';
import NavPages from "../../../components/NavPages/navPages";
import { api } from '../../../services/api';
import { FaFilter } from 'react-icons/fa'

import moment from 'moment';

import { tableIcons } from "../../../assets/icons-table/icons";
import styles from "../../search-moc/search-moc.module.css"

export default function MyApprovals() {
    const [tableData, setTableData] = useState([])
    const [filtering, setFiltering] = useState(false);

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get('/mocapprovers')
        setTableData(response.data)
    }

    const history = useHistory()

    const columns = [
        {
            title: "Título do MOC",
            field: "title",
            align: "left",
            width: "80%"
        },
        {
            title: "Código do MOC",
            field: "codMoc",
            align: "left",
            render: (row) => (<div>{row.codMoc}</div>)
        },
        {
            title: "Data de abertura",
            align: "left",
            field: 'openDate',
            render: (row) => (<div>{moment.utc(row.openDate).format('DD/MM/yyyy')}</div>)
        },
        {
            title: "Site",
            align: "left",
            field: 'Sites.name',
            //render: (row) => (<div>{row.Sites.name}</div>)
        },
        {
            title: "Categoria",
            align: "left",
            field: 'Categories.name',
            //render: (row) => (<div>{row.Categories.name}</div>)
        },
        {
            title: "Modelo",
            align: "left",
            field: 'modelMoc',
            lookup: { Completo: 'Completo', Simplificado: 'Simplificado' },
            render: (row) => (<div>{row.modelMoc == null ? '---' : row.modelMoc}</div>)
        },
        {
            title: "Solicitante",
            align: "left",
            field: 'Users.name',
        },
        {
            title: "Fiscal year",
            align: "left",
            field: 'fiscalYear',
            width: "10%",
        },
        {
            title: "Status",
            align: "left",
            width: "80%",
            field: 'Steps.name',
        },
        {
            title: <div onClick={() => { setFiltering(currentFilter => !currentFilter) }}><FaFilter fontSize="medium" /></div>,
            align: "left",
        },

    ]

    function onRowClick(mocId, title) {
        history.push('/minhas-aprovações/aprovar-moc', { mocId: mocId, title: title })
    }

    return (
        <>
            <NavPages title="Minhas Aprovações" goHome={true} />

            <div className="mainDiv">
                <div className={styles.materialTable}>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (<div style={{ height: "0px", }}></div>),
                        }}
                        onRowClick={(event, rowData) => { onRowClick(rowData.id, rowData.title) }}
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        title=''
                        localization={{
                            body: {
                                emptyDataSourceMessage: "Nenhum MOC para a aprovação disponível",
                            },
                        }}

                        options={{
                            pageSize: 5,
                            pageSizeOptions: false,
                            actionsColumnIndex: 9,
                            filtering: filtering,
                            search: false,
                            maxBodyHeight: '30rem',
                            minBodyHeight: '30rem',
                            exportButton: false,
                            //actionsCellStyle:{display:"flex", justifyContent: "center",marginBottom:' -1px'},
                            rowStyle: {
                                fontSize: '0.9rem',

                            }, headerStyle: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',

                            }, headerStyleHover: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',
                            },

                        }}
                    />
                </div>

            </div>

        </>
    )
}