import styles from "./titleConfigs.module.css"



export default function TitleConfigs(props) {
    return (
        <>
            <div className={styles.title}>
                <img src={props.icon} style={props.tamanho} alt="" />
                <span>{props.titulo}</span>
            </div>
        
        </>
    )
}