import styles from './steps.module.css'

import { useEffect, useState } from 'react'
import { api } from '../../services/api'

import InfoBlue from '../../assets/steps/InfoBlue.png'
import AprovacaoHseBlue from '../../assets/steps/AprovacaoHseBlue.png'
import ChecklistApoioBlue from '../../assets/steps/ChecklistApoioBlue.png'

import PreProjetoBlue from '../../assets/steps/PreProjetoBlue.png'
import PreProjetoWhite from '../../assets/steps/PreProjetoWhite.png'
import AcoesPreProjetoBlue from '../../assets/steps/AcoesPreProjetoBlue.png'
import AcoesPreProjetoWhite from '../../assets/steps/AcoesPreProjetoWhite.png'

import ComissionamentoBlue from '../../assets/steps/ComissionamentoBlue.png'
import ComissionamentoWhite from '../../assets/steps/ComissionamentoWhite.png'
import AcoesComissionamentoBlue from '../../assets/steps/AcoesComissionamentoBlue.png'
import AcoesComissionamentoWhite from '../../assets/steps/AcoesComissionamentoWhite.png'

import DetalhamentoBlue from '../../assets/steps/DetalhamentoBlue.png'
import DetalhamentoWhite from '../../assets/steps/DetalhamentoWhite.png'
import AcoesDetalhamentoBlue from '../../assets/steps/AcoesDetalhamentoBlue.png'
import AcoesDetalhamentoWhite from '../../assets/steps/AcoesDetalhamentoWhite.png'

import AprovacaoCapexBlue from '../../assets/steps/AprovacaoCapexBlue.png'
import AprovacaoCapexWhite from '../../assets/steps/AprovacaoCapexWhite.png'
import ValidacaoFinalBlue from '../../assets/steps/ValidacaoFinalBlue.png'
import ValidacaoFinalWhite from '../../assets/steps/ValidacaoFinalWhite.png'


export default function Steps({ id }) {

    const [steps, setSteps] = useState({})
    const [isSimplified, setIsSimplified] = useState(false)
    const [isFinished, setIsFinished] = useState(false)


    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let responseSteps = await api.get(`stages/moc/${id}`)
        let responseMoc = await api.get(`moc/${id}`)

        let finished

        if(responseMoc.data.Steps.name == "Finalizado") {
            finished = true
        } else {
            finished = false
        }

        console.log(finished)

        setIsFinished(finished)



        console.log(responseSteps.data)

        if (responseSteps.data.length != 3) {
            setIsSimplified(true)
        }

        setSteps(responseSteps.data)
    }


    const activeStep = '#009ebe'
    const notActiveStep = '#FFF'

    return (
        <div className={styles.stepsDiv}>
            <div className={styles.step}>
                <div className={styles.stepIcon}>
                    <img src={InfoBlue} alt="" />
                </div>

                <span>Informações básicas</span>
            </div>
            <div className={styles.step}>
                <div className={styles.stepIcon}>
                    <img src={ChecklistApoioBlue} alt="" />
                </div>

                <span>Checklist de apoio</span>
            </div>
            <div className={styles.step}>
                <div className={styles.stepIcon}>
                    <img src={AprovacaoHseBlue} alt="" />
                </div>

                <span>Aprovação de HSE</span>
            </div>
            {!steps.length > 0 ? null :
                isSimplified ?
                    <>
                        <div className={styles.step}>
                            {steps[0].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={PreProjetoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={PreProjetoWhite} alt="" />
                                </div>
                            }

                            <span>Checklists simplificado</span>
                        </div>
                        <div className={styles.step}>
                            {steps[0].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={AcoesPreProjetoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={AcoesPreProjetoWhite} alt="" />
                                </div>
                            }
                            <span>Ações simplificado</span>
                        </div>
                    </>

                    :

                    null
            }

            {!steps.length > 0 ? null :
                !isSimplified ?
                    <>
                        <div className={styles.step}>
                            {steps[0].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={PreProjetoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={PreProjetoWhite} alt="" />
                                </div>
                            }

                            <span>Checklists de pré-projeto</span>
                        </div>

                        <div className={styles.step}>
                            {steps[0].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={AcoesPreProjetoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={AcoesPreProjetoWhite} alt="" />
                                </div>
                            }

                            <span>Ações de pré-projeto</span>
                        </div>


                        <div className={styles.step}>
                            {steps[1].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={DetalhamentoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={DetalhamentoWhite} alt="" />
                                </div>
                            }

                            <span>Checklists de detalhamento</span>
                        </div>
                        <div className={styles.step}>
                            {steps[1].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={AcoesDetalhamentoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={AcoesDetalhamentoWhite} alt="" />
                                </div>
                            }

                            <span>Ações de detalhamento</span>
                        </div>


                        <div className={styles.step}>
                            {steps[2].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={ComissionamentoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={ComissionamentoWhite} alt="" />
                                </div>
                            }

                            <span>Checklists de comissionamento</span>
                        </div>
                        <div className={styles.step}>
                            {steps[2].isCompleted ?
                                <div className={styles.stepIcon}>
                                    <img src={AcoesComissionamentoBlue} alt="" />
                                </div>

                                :

                                <div className={styles.stepIcon}>
                                    <img src={AcoesComissionamentoWhite} alt="" />
                                </div>
                            }

                            <span>Ações de comissionamento</span>
                        </div>

                    </>

                    :

                    null
            }

            { !isFinished ?
                <div className={styles.step}>
                    <div className={styles.stepIcon}>
                        <img src={ValidacaoFinalWhite} alt="" />
                    </div>

                    <span>Validação final</span>
                </div>

                :

                <div className={styles.step}>
                    <div className={styles.stepIcon}>
                        <img src={ValidacaoFinalBlue} alt="" />
                    </div>

                    <span>Validação final</span>
                </div>
            }


        </div>
    )
}