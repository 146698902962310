import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavPages from "../../../components/NavPages/navPages";
import BasicInfo from "../../../components/search-moc/mocEdits/basicInfo";
import ChecklistSupport from "../../../components/search-moc/checklistSupport";
import HseApproval from "../../../components/search-moc/hseApproval";
import MocSteps from "../../../components/search-moc/MocSteps";
import Steps from "../../../components/steps";
import { api } from "../../../services/api";

import styles from './editMoc.module.css'


export default function ViewMoc() {
    const location = useLocation()
    const id = location.state.state

    console.log(id)

    // Estado que determina se o moc é completo ou simplificado
    const [isComplete, setIsComplete] = useState(true)
    const [responseMoc, setResponseMoc] = useState(true)

    const [stages, setStages] = useState([])

    useEffect(async () => {
        await getMocStageData()
    }, [])

    async function getMocStageData() {
        let response = await api.get(`/stages/moc/${id}`)
        let responseMoc = await api.get(`moc/${id}`)

        console.log(response.data)

        setResponseMoc(responseMoc.data.Steps.id)

        setStages(response.data)

        if (response.data.length != 3) {
            setIsComplete(false)
        }

    }

    return (
        <>
            <Steps
                id={id}
            />

            <NavPages title="Buscar MOC" secondTitle="Visualizar MOC" />

            <div className='mainDiv'>
                    <div className={styles.stepGroup} id="PDFExport">
                        <BasicInfo
                            id={id}
                        />

                        < ChecklistSupport
                            id={id}
                            activeToggle={false}
                            disableOpacity={false}
                        />

                        <HseApproval
                            id={id}
                        />
                    </div>


                    {!stages.length > 0 ? <div></div> :
                        isComplete ?
                            <div>
                                < div className={styles.stepGroup}>
                                    <MocSteps
                                        id={id}
                                        stageId={1}
                                        editMoc={true}
                                        readOnly={false}
                                        openChecklist={false}
                                    />
                                </div>

                                {stages[0]?.isCompleted ?

                                    <div className={styles.stepGroup}>
                                        <MocSteps
                                            id={id}
                                            stageId={2}
                                            editMoc={true}
                                            readOnly={false}
                                            openChecklist={false}
                                        />
                                    </div>

                                    : null}

                                {stages[1]?.isCompleted ?
                                    <div className={styles.stepGroup}>
                                        <MocSteps
                                            id={id}
                                            stageId={3}
                                            editMoc={true}
                                            readOnly={false}
                                            openChecklist={false}
                                        />
                                    </div>
                                    : null}
                            </div>

                            :

                            < div className={styles.stepGroup}>
                                <MocSteps
                                    id={id}
                                    stageId={4}
                                    editMoc={true}
                                    readOnly={false}
                                    openChecklist={false}
                                />
                            </div>

                    }
            </div>
        </>
    )
}