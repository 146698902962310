
import { useState, useEffect } from 'react'

import styles from './admins.module.css'

import adminIconBlue from '../../../assets/configuration/adminBlue.svg'
import TitleConfigs from '../../../components/TitleConfigs'
import Label from '../../../components/Label/label'
import { AutoComplete } from 'rsuite'
import AddButton from '../../../components/addButton'
import { api } from '../../../services/api'
import Swal from 'sweetalert2'
import Table from 'rc-table'
import deleteIcon from '../../../assets/delete.svg'


export default function Admins() {
    const [selectedAdminName, setSelectedAdminName] = useState('')
    const [adminId, setAdminId] = useState('')

    const [adminError, setAdminError] = useState('')

    const [admins, setAdmins] = useState('')

    const [users, setUsers] = useState([])
    const [usersData, setUsersData] = useState([])

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let responseUsers = await api.get('users')

        let usersArray = []
        responseUsers.data.map(x => {
            usersArray.push({ label: x.name, value: x.name })
        })

        setUsers(usersArray)
        setUsersData(responseUsers.data)

        await getAdmins()
    }

    async function getAdmins() {
        let adminsArray = []
        let responseAdmins = await api.get('admins')

        responseAdmins.data.map(x => {
            adminsArray.push({ name: x.name, id: x.id })
        })

        setAdmins(adminsArray)
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columnsAdmins = [
        {
            title: "",
            dataIndex: "name",
            key: "file",
            align: "left",
            width: 1000,
        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 50,
            render: (value, row, id) => {
                return (<div onClick={() => handleRemoveAdmin(row.id)} style={{ display: 'flex', cursor: 'pointer' }}><img style={{ display: 'block', width: '1.2rem', height: '1.2rem' }} src={deleteIcon} alt='' /></div>)
            }
        },
    ]

    const handleRemoveAdmin = async (id) => {
      const confirmationResult = await Swal.fire({
        title: "Deletar Administrador?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009EBE",
        cancelButtonColor: "#636e72",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });
      if (confirmationResult.isConfirmed) {
        try {
          await api.put(`/admins/${id}`, {
            isAdmin: false,
          });
          Swal.fire("Administrador deletado com sucesso!", "", "success");
          getAdmins();
        } catch (error) {
          console.error(error);
          Swal.fire("Erro ao deletar administrador", "", "error");
        }
      }
    };

    async function addAdmin() {
        let actualAdmins = [...admins]

        if (actualAdmins.filter(x => x.id == adminId).length != 0) {
            Toast.fire({
                icon: 'error',
                title: 'Administrador já adicionado.'
            })
            return

        } if (adminId === '') {
            setAdminError('Selecione um usuário válido')
            return
        } else {
            try {
                let responseAdmin = await api.put(`/admins/${adminId}`, {
                    "isAdmin": "true"
                })

                if (responseAdmin.status == 200) {
                    Swal.fire({
                        title: 'Administrador cadastrado',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: "#009EBE",
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false
                    })
                    getAdmins()
                }

            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: `${error.response.data.errors[0].msg}`
                })
            }
        }
    }

    function handleSelectedAdmin(value) {
        let filteredAdmin = usersData.filter(x => x.name == value)

        if (filteredAdmin.length != 0) {
            setAdminId(filteredAdmin[0].id)
        }

        setSelectedAdminName(value)
    }

    return (
        <>
            <TitleConfigs titulo='Administradores' icon={adminIconBlue} tamanho={{ width: "1.3rem" }} />

            <div className={styles.mainDiv}>
                <div style={{width:'30rem'}}>
                    <div style={{ width: '100%' }} className="inputContainer">
                        <Label htmlFor="">Administrador</Label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                            <AutoComplete
                                style={{ width: '100%' }}
                                data={users}
                                value={selectedAdminName}
                                onChange={(value) => {
                                    setAdminError('')
                                    setAdminId('')
                                    handleSelectedAdmin(value)
                                }}
                            />
                            <AddButton onClick={() => addAdmin()} />

                        </div>
                        {adminError ? (
                            <div className="inputError">{adminError}</div>
                        ) : null}
                    </div>

                    {admins.length !== 0 ?
                        <Table
                            sticky={true}
                            columns={columnsAdmins}
                            data={admins}
                            showHeader={false}
                        />
                        :
                        null
                    }
                </div>



            </div>

        </>
    )
}