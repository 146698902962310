import { useEffect, useState } from "react";
import NavPages from "../../../components/NavPages/navPages";
import Steps from "../../../components/steps";

import styles from "./checklistAnswers.module.css"

import { useLocation } from "react-router-dom";
import MocSteps from "../../../components/search-moc/MocSteps";
import { api } from "../../../services/api";
import BasicInfo from "../../../components/search-moc/basicInfo";
import ChecklistSupport from "../../../components/search-moc/checklistSupport";
import HseApproval from "../../../components/search-moc/hseApproval";
import CapexApprovals from "../../../components/search-moc/capexApproval";
import exportToPDF from "../../../utils/exportToPdf";
import Button from "../../../components/Button";
import LoadingContent from "../../../components/loadingContent/loadingContent";
import { printPage } from "../../../utils/pdfExport";
import Swal from "sweetalert2";

export default function CapexApproval({ id, title }) {
    /*  const location = useLocation()
     const id = location.state.state */

    // Estado que determina se o moc é completo ou simplificado
    const [isComplete, setIsComplete] = useState(true)

    const [activeDiv, setActiveDiv] = useState(false)

    const [stages, setStages] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [disableDivsOpacity, setDisableDivsOpacity] = useState(false)

    const [evidenceObservation, setEvidenceObservation] = useState(false)

    useEffect(async () => {
        await getMocStageData()
    }, [])

    async function getMocStageData() {
        try {
            setIsLoading(true)

            let response = await api.get(`/stages/moc/${id}`)

            setStages(response.data)

            if (response.data.length != 3) {
                setIsComplete(false)
            }

        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }

    }

    async function startExporting() {
        setActiveDiv(true)
        setDisableDivsOpacity(true)
        setEvidenceObservation(true)
        await Swal.fire({
            text: 'O carregamento do arquivo pode demorar alguns minutos.',
            icon: 'info',
            confirmButtonColor: "#009EBE",
            confirmButtonText: 'Gerar PDF',
            allowOutsideClick: false,
            didClose: () => {window.print()}
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        setDisableDivsOpacity(false)
        setEvidenceObservation(false)
    }

    return (
        <>
            <Steps
                id={id}
            />

            <NavPages title="Minhas Aprovações" secondTitle="Aprovação do CAPEX" />
            <LoadingContent isLoading={isLoading}>
                <div className='mainDiv'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1%' }}>
                        <Button onClick={startExporting}
                        >Exportar para PDF</Button>
                    </div>

                    <div>

                        <div className={styles.stepGroup} id="PDFExport">
                            <BasicInfo
                                id={id}
                                activeToggle={activeDiv}
                                disableOpacity={disableDivsOpacity}
                            />

                            <ChecklistSupport
                                id={id}
                                activeToggle={activeDiv}
                                disableOpacity={disableDivsOpacity}
                            />

                            <HseApproval
                                id={id}
                                activeToggle={activeDiv}
                                disableOpacity={disableDivsOpacity}
                            />
                        </div>

                        {!stages.length > 0 ? <div></div> :
                            isComplete ?
                                <div>
                                    < div className={styles.stepGroup}>
                                        <MocSteps
                                            id={id}
                                            stageId={1}
                                            readOnly={true}
                                            activeToggle={activeDiv}
                                            disableOpacity={disableDivsOpacity}
                                            evidenceObservation={evidenceObservation}
                                        />
                                    </div>
                                </div>

                                :

                                < div className={styles.stepGroup}>
                                    <MocSteps
                                        id={id}
                                        stageId={4}
                                        readOnly={true}
                                        activeToggle={activeDiv}
                                        disableOpacity={disableDivsOpacity}
                                        evidenceObservation={evidenceObservation}
                                    />
                                </div>
                        }
                    </div>

                </div>
            </LoadingContent>
        </>
    )
}