import { useEffect, useState } from "react"

import { api } from "../../../services/api"
import Title from "../../Title/title"
import Swal from "sweetalert2";

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"
import { IoAttachSharp } from "react-icons/io5";

import Button from "../../Button";
import ModalAttachAction from "../../modalAttachAction";

import styles from './validation.module.css'
import { SelectPicker } from "rsuite";
import Table from "rc-table";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
export default function Validation({ id, codMoc, evidenceObservation = "" }) {
    const [active, setActive] = useState(true)

    const [actions, setActions] = useState([])
    const [actionFinished, setActionFinished] = useState(true)

    const [modalAction, setModalAction] = useState(false);
    const [actionId, setActionId] = useState('');


    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get(`/actions/moc/${id}`)

        let validation = true
        response.data.map(action => {
            if (action.finalValidation == "Pendente" || action.finalValidation == null || action.finalValidation == "Não realizado") {
                validation = false
            }
        })

        setActionFinished(validation)
        setActions(response.data)
    }


    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "Ação:",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 250,
            render: (row) => (<div>{row.name}</div>)
        },
        {
            title: "Etapa de conclusão:",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.Stages.name}</div>)
        },
        {
            title: "Responsável:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.Users.name}</div>)
        },
        {
            title: "Realização:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.realization ? moment(row.realization).format("DD/MM/YYYY") : '-'}</div>)
        },
        {
            title: "Checklist:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.checklistName} </div>)
        },
        {
            title: "Status:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 80,
            render: (row) => (
                <div style={{
                    display: "flex",
                    justifyContent: 'space-between'
                }}>
                    {row.status}
                </div>)
        },
        {
            title: "",
            dataIndex: "",
            key: "",
            align: "left",
            width: 50,
            render: (row) => (
                <div
                    onClick={() => handleOpenModalAction(row.id)}
                    style={{
                        cursor: "pointer"
                    }}>
                    <IoAttachSharp color='#009EBE' size={20} />
                </div>)
        },
        {
            title: "Validação final:",
            dataIndex: "",
            key: "site",
            align: "left",
            width: 150,
            render: (row) => {
                return (
                    <SelectPicker
                        value={row.finalValidation}
                        data={statusData}
                        onChange={(value) => handleStatusChange(value, row.id)}
                        searchable={false}
                        cleanable={false}
                        placeholder="Selecione"
                        size="xs"
                    />
                )

            }
        }
    ]

    const statusData = [
        { label: 'Pendente', value: 'Pendente' },
        { label: 'Realizado', value: 'Realizado' },
        { label: 'Impedido de realizar', value: 'Impedido de realizar' },
        { label: 'Não realizado', value: 'Não realizado' }
    ]

    async function handleStatusChange(value, id) {
        try {
            let response = await api.put(`actions/${id}`, {
                "finalValidation": value,
            })

            await getData()
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }

    }

    async function submitStage() {
        if (actionFinished) {

            Swal.fire({
                text: `Finalizar o ${codMoc} ?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                confirmButtonText: "Confirmar",
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let response = await api.put(`/moc/finish/${id}`)

                        if (response.status == 200) {
                            Swal.fire({
                                text: "MOC finalizado com sucesso",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#009EBE",
                                confirmButtonText: "Ok"
                            }).then((result) => {
                                window.location.href = "/";
                            })
                        }
                    } catch (error) {
                        Toast.fire({
                            icon: 'error',
                            text: `${error.response.data.errors[0].msg}`
                        })
                    }
                }
            })
        }
    }

    function handleOpenModalAction(id) {
        setModalAction(true)
        setActionId(id)
    }


    return (
        <>
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Validação Final</Title>

                    <div onClick={() => setActive(prevActive => !prevActive)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {active == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>

                {active ?
                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <Table
                            sticky={true}
                            columns={columns}
                            data={actions}
                            showHeader={true}
                            emptyText="Nenhuma ação disponível"
                        />
                        {evidenceObservation 
                            ?   <p className={styles.observation}>
                                    <FaInfoCircle/> Acesse a aplicação para visualizar as evidências das ações. 
                                </p>
                            : ""
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1%' }}>
                            <Button style={!actionFinished ? { opacity: '0.5' } : {}} title={!actionFinished ? 'A conclusão só é permitida quando não houver nenhuma ação pendente' : ''} onClick={() => submitStage()} >{`Finalizar MOC`}</Button>

                        </div>
                    </div>



                    : null}
            </div>
            <ModalAttachAction
                id={actionId}
                open={modalAction}
                onRequestClose={setModalAction}
            />
        </>
    )
}