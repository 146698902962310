import { useEffect, useState } from 'react'

import styles from './sites.module.css'

import siteIconBlue from '../../../assets/configuration/sitesBlue.svg'
import TitleConfigs from '../../../components/TitleConfigs'
import { RiCloseFill } from 'react-icons/ri';
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'

import ButtonPlus from '../../../components/ButtonPlus'
import Table from 'rc-table'
import Modal from 'react-modal'
import Button from '../../../components/Button';
import Label from '../../../components/Label/label';
import AddButton from '../../../components/addButton';
import { api } from '../../../services/api';
import Swal from "sweetalert2";
import { AutoComplete } from 'rsuite';

export default function Sites({mocs}) {
    const [modalOpen, setModalOpen] = useState(false)

    const [siteName, setSiteName] = useState('')
    const [approver, setApprover] = useState('')
    const [selectedApproverName, setSelectedApproverName] = useState('')

    const [siteError, setSiteError] = useState('')
    const [approverError, setApproverError] = useState('')

    const [addedApprovers, setAddedApprovers] = useState([])
    const [sites, setSites] = useState([])

    const [users, setUsers] = useState([])
    const [usersData, setUsersData] = useState([])

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        const responseSites = await api.get('sites')
        setSites(responseSites.data)

        const responseUsers = await api.get('users')
        const usersArray = responseUsers.data.map(x => ({ label: x.name, value: x.email }))

        setUsers(usersArray)
        setUsersData(responseUsers.data)

    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    function handleCloseModal() {
        setModalOpen(false)
        cleanStates()
    }

    function cleanStates() {
        setSiteName('')
        setSiteId('')
        setAddedApprovers([])
        setSelectedApproverName('')
        setApprover('')

        setSiteError('')
        setApproverError('')
    }


    const columnsApprovers = [
        {
            title: "",
            dataIndex: "name",
            key: "file",
            align: "left",
            width: 1000,
        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 50,
            render: (value, row, id) => {
                return (<div onClick={() => handleRemoveApprover(row.approverId)} style={{ display: 'flex', cursor: 'pointer' }}><img style={{ display: 'block', width: '1.2rem', height: '1.2rem' }} src={deleteIcon} alt='' /></div>)
            }
        },
    ]


    const columns = [
        {
            title: "Sites:",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 275,
            render: (row) => (<div>{row.name}</div>)
        },
        {
            title: "Aprovadores:",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 550,
            render: (row) => {
                let approvers = []

                row.Approvers.map(approver => {
                    approvers.push(approver.User.name)
                })

                let approversString = approvers.join(";")

                return (
                    <div>{approversString}</div>
                )
            }
        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "right",
            width: 5,
            render: (value, row, id) => (<div className='iconTableDiv'>
                <img className="actionBtn" onClick={(event) => onEdit(row.id)} style={{ width: '1rem', height: '1rem', cursor: 'pointer' }} src={editIcon} alt='' />
                <img className="actionBtn" onClick={(event) => handleRemoveSite(row.id)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>)
        },
    ]

    const [isEdit, setIsEdit] = useState(false)
    const [siteId, setSiteId] = useState('')
    const [editSiteName, setEditSiteName] = useState('')
    const [previousAddedApprovers, setPreviousAddedApprovers] = useState([])

    async function onEdit(id) {
        setModalOpen(true)
        setIsEdit(true)
        setSiteId(id)

        let response = await api.get(`sites/${id}`)
        setSiteName(response.data.name)
        setEditSiteName(response.data.name)

        await getApprovers(id)
    }

    async function getApprovers(id) {
        let response = await api.get(`sites/${id}`)

        let arrayApprovers = []

        response.data.Approvers.map(approver => {
            arrayApprovers.push({ name: approver.User.name, id: approver.User.id, approverId: approver.id, id: id })
        })

        setAddedApprovers(arrayApprovers)
        setPreviousAddedApprovers(arrayApprovers)
    }

    function isSameEdit() {
        let validation = false

        if (editSiteName == siteName) {
            validation = true
        }

        addedApprovers.map(approver => {
            if (previousAddedApprovers.filter(x => x.approverId == approver.approverId).length == 0) {
                validation = false
            }
        })

        previousAddedApprovers.map(approver => {
            if (addedApprovers.filter(x => x.approverId == approver.approverId).length == 0) {
                validation = false
            }
        })

        // previous [1,2,3]
        // added [1,3,4]

        return validation
    }

    async function submitEdit() {
        let validation = true

        if (siteName.trim() === '') {
            setSiteError('Digite o nome do site')
            validation = false
        }

        if (addedApprovers.length === 0) {
            setApproverError('Adicione pelo menos um aprovador')
            validation = false
        }

        if (isSameEdit()) {
            handleCloseModal()
            await getData()

            return
        }

        if (validation && !isSameEdit()) {
            try {
                if (editSiteName != siteName) {
                    let response = await api.put(`sites/${siteId}`, {
                        "name": siteName
                    })
                }

                addedApprovers.map(async (approver) => {
                    if (previousAddedApprovers.filter(x => x.approverId == approver.approverId).length == 0) {
                        let responseApprover = await api.post('/sites/approvers', {
                            "userId": approver.id,
                            "siteId": siteId
                        })

                    }
                })

                previousAddedApprovers.map(async (approver) => {
                    if (addedApprovers.filter(x => x.approverId == approver.approverId).length == 0) {
                        let responseApprover = await api.delete(`sites/approvers/${approver.approverId}`)

                    }
                })

                Swal.fire({
                    title: 'Site editado',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: "#009EBE",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await getData()
                        cleanStates()
                        setModalOpen(false)
                    }
                })
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    text: `${error.response.data.errors[0].msg}`
                })
            }

        }

    }

    /* async function removeApproverEdit(id) {
        setApproversChanged(true)
    
        let approver = addedApprovers.filter(x => x.id == id)[0]
    
        Swal.fire({
            title: 'Deletar aprovador?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response = await api.delete(`sites/approvers/${approver.id}`)
    
                    if (response.status == 200) {
                        setAddedApprovers(addedApprovers.filter(x => x.id != id))
    
                        Swal.fire({
                            title: 'Aprovador removido',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: "#009EBE",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false
                        })
                    }
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        text: `${error.response.data.errors[0].msg}`
                    })
                }
    
            }
        })
    } */


    async function handleRemoveSite(id) {
        const isSiteInUse = mocs.some(({Sites})=>Sites.id === id);
        if(isSiteInUse){
            Toast.fire({
                icon: 'error',
                text: 'Não é possível excluir, pois existem MOCs registrados associados a este site.',
                timer: 5000
            })
            return;
        }
        Swal.fire({
            title: 'Deletar site?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let response = await api.delete(`/sites/${id}`)

                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Site removido',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: "#009EBE",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false
                        })

                        getData()
                    }

                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.response.data.errors[0].msg}`
                    })
                }
            }
        })
    }


    function handleRemoveApprover(el) {
        let newAddedApprovers = addedApprovers.filter(x => x.approverId != el)
        setAddedApprovers(newAddedApprovers)
    }

    function addApprover() {
        let actualApprovers = [...addedApprovers]

        if (actualApprovers.includes(selectedApproverName)) {
            Toast.fire({
                icon: 'error',
                title: 'Aprovador já adicionado.'
            })
            return

        } if (approver === '') {
            setApproverError('Selecione um aprovador válido')
            return
        } else {
            actualApprovers.push({ name: selectedApproverName, approverId: approver.approverId, id: approver.id })
            setAddedApprovers(actualApprovers)
            setApprover('')
            setSelectedApproverName('')
            setApproverError('')
        }
    }

    function handleSelectedApprover(value) {
        let filteredApprovers = usersData.filter(x => x.email == value)


        if (filteredApprovers.length != 0) {
            setApprover({ name: filteredApprovers[0].name, id: filteredApprovers[0].id, approverId: filteredApprovers[0].id })
        }

        setSelectedApproverName(filteredApprovers.length != 0? filteredApprovers[0].name : value)
    }

    function validation() {
        let validation = true

        if (siteName === '') {
            validation = false
            setSiteError('Digite o nome do site')
        }

        if (addedApprovers.length === 0) {
            validation = false
            setApproverError('Selecione pelo menos um aprovador')
        }

        return validation
    }
    async function submit() {
        try {
            if (validation()) {

                let responseSite = await api.post('/sites', {
                    "name": siteName
                })

                if (responseSite.status == 200) {
                    addedApprovers.map(async approver => {
                        let responseApprovers = await api.post('/sites/approvers', {
                            "userId": approver.id,
                            "siteId": responseSite.data.id
                        })

                        if (responseApprovers.status == 200) {
                            cleanStates()
                            handleCloseModal()
                            getData()
                        }
                    })
                }
            }

        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data.errors[0].msg}`
            })
        }
    }

    return (
        <>
            <TitleConfigs titulo='Sites' icon={siteIconBlue} tamanho={{ width: "1.3rem" }} />

            <div className={styles.mainDiv}>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonPlus onClick={() => {
                        setModalOpen(true)
                        setIsEdit(false)
                    }} titulo='Novo Site' />
                </div>

                <Table
                    sticky={true}
                    columns={columns}
                    data={sites}
                    showHeader={true}
                    emptyText="Nenhum site cadastrado"
                />
            </div>

            <Modal isOpen={modalOpen} onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(44, 42, 42, 0.707)'
                    },
                    content: {
                        position: 'absolute',
                        width: '40rem',
                        height: '28rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: 'none',
                        background: '#fff',
                        overflow: 'hidden',
                        borderRadius: 'none',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        padding: 'none',
                    }
                }}>
                <div className="headerModal">
                    <h3>Novo site</h3>
                    <h2 onClick={handleCloseModal}><RiCloseFill /></h2>
                </div>


                <div className="modalContainer">

                    <div className='modalFirstDiv'>
                        <div style={{ width: '100%' }} className="inputContainer">
                            <Label htmlFor="">Site</Label>
                            <input value={siteName} onChange={(event) => {
                                setSiteError('')
                                setSiteName(event.target.value)
                            }}
                            onBlur={()=>setSiteName(prev=>prev.trim())} 
                                type="text" />
                            {siteError ? (
                                <div className="inputError">{siteError}</div>
                            ) : null}
                        </div>

                        <div style={{ width: '100%', marginTop: '2%' }} className="inputContainer">
                            <Label htmlFor="">Aprovadores de HSE</Label>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                                <AutoComplete
                                    style={{ width: '100%' }}
                                    data={users}
                                    value={selectedApproverName}
                                    onChange={(value) => {
                                        setApproverError('')
                                        setApprover('')
                                        handleSelectedApprover(value)
                                    }}
                                />
                                <AddButton onClick={() => addApprover()} />

                            </div>
                            {approverError ? (
                                <div className="inputError">{approverError}</div>
                            ) : null}
                        </div>

                    </div>

                    {addedApprovers.length !== 0 ?
                        <div className="modalTableOverflowDiv">
                            <Table
                                sticky={true}
                                columns={columnsApprovers}
                                data={addedApprovers}
                                showHeader={false}
                            />
                        </div>
                        :
                        null
                    }

                    <div className="modalFooter">
                        <Button onClick={isEdit ? () => submitEdit() : () => submit()}>Ok</Button>
                    </div>

                </div>

            </Modal>



        </>
    )
}