import React, { useState } from 'react';

export const NewMocContext = React.createContext({});


export const NewMocProvider = (props) => {

    const [titulo, setTitulo] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [data, setData] = useState('')
    const [custo, setCusto] = useState('')
    const [fiscal, setFiscal] = useState('')
    const [site, setSite] = useState('')
    const [categoria, setCategoria] = useState('')
    const [tipo, setTipo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const [hse, setHse] = useState(false)
    const [selectedSiteName, setSelectedSiteName] = useState('')
    const [selectedTipoName, setSelectedTipoName] = useState('')
    const [selectedCategoriaName, setSelectedCategoriaName] = useState('')
    const [checkAnswers, setCheckAnswers] = useState({
        check1: '',
        check2: '',
        check3: ''
    })
        

    return (
        <NewMocContext.Provider value={{
            titulo, setTitulo,
            solicitante, setSolicitante,
            data, setData,
            custo, setCusto,
            fiscal, setFiscal,
            site, setSite,
            categoria, setCategoria,
            tipo, setTipo,
            descricao, setDescricao,
            anexos, setAnexos,
            fotos, setFotos,
            hse, setHse,
            selectedSiteName, setSelectedSiteName,
            selectedTipoName, setSelectedTipoName,
            selectedCategoriaName, setSelectedCategoriaName,
            checkAnswers, setCheckAnswers,
        }}>
            {props.children}
        </NewMocContext.Provider>
    )
}


export const useNewMoc = () => React.useContext(NewMocContext)