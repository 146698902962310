import React, { useState } from 'react';

export const EditMocContext = React.createContext({});


export const EditMocProvider = (props) => {

    const [titulo, setTitulo] = useState('')
    const [solicitante, setSolicitante] = useState('')
    const [data, setData] = useState('')
    const [custo, setCusto] = useState('')
    const [fiscal, setFiscal] = useState('')
    const [site, setSite] = useState('')
    const [categoria, setCategoria] = useState('')
    const [tipo, setTipo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const [hse, setHse] = useState(false)
    const [selectedSiteName, setSelectedSiteName] = useState('')
    const [selectedTipoName, setSelectedTipoName] = useState('')
    const [selectedCategoriaName, setSelectedCategoriaName] = useState('')
    const [uploadfotos, setUploadFotos] = useState([])
    const [isOpenModalPic, setIsOpenModalPic] = useState([])
    const [addedFiles, setAddedFiles] = useState([])
    const [previousAddedFiles, setPreviousAddedFiles] = useState([])


    return (
        <EditMocContext.Provider value={{
            titulo, setTitulo,
            solicitante, setSolicitante,
            data, setData,
            custo, setCusto,
            fiscal, setFiscal,
            site, setSite,
            categoria, setCategoria,
            tipo, setTipo,
            descricao, setDescricao,
            anexos, setAnexos,
            fotos, setFotos,
            hse, setHse,
            selectedSiteName, setSelectedSiteName,
            selectedTipoName, setSelectedTipoName,
            selectedCategoriaName, setSelectedCategoriaName,
            uploadfotos, setUploadFotos,
            isOpenModalPic, setIsOpenModalPic,
            addedFiles, setAddedFiles,
            previousAddedFiles, setPreviousAddedFiles
        }}>
            {props.children}
        </EditMocContext.Provider>
    )
}


export const useEditMoc = () => React.useContext(EditMocContext)