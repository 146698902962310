import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom'

import jwt_decode from "jwt-decode";
import Cookie from 'js-cookie';

import { Route, Redirect } from 'react-router-dom';
import {StoreContext} from '../providers/login';

const RoutesPrivate = ({ component: Component, ...rest}) => {
  const token =Cookie.get("token")
  const history = useHistory()

  if (Cookie.get("token")) {
    const jwt_Token_decoded = jwt_decode(Cookie.get("token"));
    if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        Cookie.remove('token')
        Cookie.remove('nome')

    // this runs only when I refresh the page or reload on route change it dosent work
    }  
  }
  return (
    <Route
      {...rest}
      render={() => token
        ? <Component {...rest} />
        : <Redirect to="/login" />
      }
    />
  )
}

export default RoutesPrivate;