import React  from "react";
import useStorage from '../services/useStorage';

import { createContext } from 'react';

export const StoreContext = createContext({
  token: null,
  setToken: () => {},
});


export const  StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  return (
    <StoreContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
