import NavPages from "../../../components/NavPages/navPages"
import moment from "moment"
import MaterialTable from 'material-table';

import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react";
import { FaFilter } from 'react-icons/fa'

import { api } from "../../../services/api";
import { tableIcons } from "../../../assets/icons-table/icons";
import styles from "../../search-moc/search-moc.module.css"

export default function Checklists() {
    const [tableData, setTableData] = useState([])
    const [filtering, setFiltering] = useState(false);

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get('responsible/moc')

        setTableData(response.data)
    }

    const history = useHistory()
    const columns = [
        {
            title: "Título do MOC",
            field: "title",
            align: "left",
            width: "80%",
            render: (row) => (<div>{row.Mocs.title}</div>)

        },
        {
            title: "Código do MOC",
            field: "codMoc",
            align: "left",
            render: (row) => (<div>{row.Mocs.codMoc}</div>)
        },
        {
            title: "Data de abertura",
            align: "left",
            field: 'openDate',
            render: (row) => (<div>{moment.utc(row.Mocs.openDate).format('DD/MM/yyyy')}</div>)
        },
        {
            title: "Site",
            align: "left",
            field: 'Mocs.Sites.name',
            //render: (row) => (<div>{row.Sites.name}</div>)
        },
        {
            title: "Categoria",
            align: "left",
            field: 'Mocs.Categories.name',
            //render: (row) => (<div>{row.Categories.name}</div>)
        },
        {
            title: "Modelo",
            align: "left",
            field: 'Mocs.modelMoc',
            lookup: { Completo: 'Completo', Simplificado: 'Simplificado' },
            render: (row) => (<div>{row.Mocs.modelMoc == null ? '---' : row.Mocs.modelMoc}</div>)
        },
        {
            title: "Solicitante",
            align: "left",
            field: 'Mocs.Users.name',
        },
        {
            title: "Fiscal year",
            align: "left",
            field: 'Mocs.fiscalYear',
            width: "10%",
        },
        {
            title: "Status",
            align: "left",
            width: "80%",
            field: 'Mocs.Steps.name',
        },
        {
            title: <div onClick={() => { setFiltering(currentFilter => !currentFilter) }}><FaFilter fontSize="medium" /></div>,
            align: "left",
        },

    ]

    function onRowClick(record, title) {
        history.push('/ações/responsavel', { state: record, mocTitle: title, title: "Minhas ações", subTitle: "Realizar ações" })
    }

    return (
        <>
            <NavPages title="Minhas ações" goHome={true} />

            <div className="mainDiv">
                <div className={styles.materialTable}>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (<div style={{ height: "0px", }}></div>),
                        }}
                        onRowClick={(event, rowData) => { onRowClick(rowData.mocId, rowData.Mocs.title) }}
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        title=''
                        localization={{
                            body: {
                                emptyDataSourceMessage: "Nenhuma ação disponível",
                            },
                        }}

                        options={{
                            pageSize: 5,
                            pageSizeOptions: false,
                            actionsColumnIndex: 9,
                            filtering: filtering,
                            search: false,
                            maxBodyHeight: '30rem',
                            minBodyHeight: '30rem',
                            exportButton: false,
                            //actionsCellStyle:{display:"flex", justifyContent: "center",marginBottom:' -1px'},
                            rowStyle: {
                                fontSize: '0.9rem',

                            }, headerStyle: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',

                            }, headerStyleHover: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',
                            },

                        }}
                    />
                </div>
            </div>
        </>
    )
}