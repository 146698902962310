import { useEffect, useState } from "react"
import { api } from "../../../services/api"

import Title from "../../Title/title"
import Button from "../../Button"
import Label from "../../Label/label"

import Modal from "react-modal";
import Swal from "sweetalert2";
import Table from "rc-table"
import ReactTooltip from 'react-tooltip';

import moment from "moment";
import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"
import { AutoComplete, Radio, RadioGroup } from "rsuite"
import { FaTimesCircle, FaFilePdf, FaFileExcel } from 'react-icons/fa'
import { useEditMoc } from "../../../providers/edit-moc";
import ViewPic from '../../viewPic'
import styles from './capexApproval.module.css'
import CapexFileUploader from "../../fileUploaderCapex"

export default function CapexApprovals({ id, readOnly, activeToggle, disableOpacity }) {
    const [activeCapexApproval, setActiveCapexApproval] = useState(false)

    useEffect(() => {
        if (activeToggle) {
            setActiveCapexApproval(true)
        }
    }, [activeToggle])

    const [isApproved, setIsApproved] = useState(false)
    const [commentary, setCommentary] = useState('')

    const [items, setItems] = useState([])
    const [moc, setMoc] = useState('')
    const [capexId, setCapexId] = useState('')
    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])

    const [capexApprover, setCapexApprover] = useState('')

    const [aprovar, setAprovar] = useState(true)
    const [justificativa, setJustificativa] = useState('')

    const [approvalsJustificativa, setApprovalsJustificativa] = useState('')

    const [addedFiles, setAddedFiles] = useState([])

    const [modalOpen, setModalOpen] = useState(false)
    const [logs, setLogs] = useState(false)
    const [justificativaError, setJustificativaError] = useState('')

    const { setUploadFotos } = useEditMoc()
    const { setIsOpenModalPic } = useEditMoc()

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(async () => {
        await getData()
        setIsOpenModalPic(false)
    }, [])

    async function getData() {
        let responseMoc = await api.get(`moc/${id}`)
        setMoc(responseMoc.data)

        let responseApprovals = await api.get(`capex/${id}`)

        setIsApproved(responseApprovals.data[0].isApproved)
        if (responseApprovals.data[0].isApproved != null) {
            setAprovar(responseApprovals.data[0].isApproved)
        }
        setCommentary(responseApprovals.data[0].commentary)
        setCapexId(responseApprovals.data[0].id)
        setCapexApprover(responseApprovals.data[0].Users.name)


        let fotos = []
        let anexos = []

        let responseAddedItens = await api.get(`/capexAttactments/${responseApprovals.data[0].id}`)
        responseAddedItens.data.map(attachment => {
            if (attachment.type == 'image/png' || attachment.type == 'image/jpeg') {
                fotos.push(attachment)
            } else {
                anexos.push(attachment)
            }
        })
        setFotos(fotos)
        setAnexos(anexos)

        let responseDescriptionsCapex = await api.get(`capex/descriptions/${responseApprovals.data[0].id}`)
        let itemsArray = []
        responseDescriptionsCapex.data.map(x => {
            itemsArray.push({ description: x.description, cost: x.expense })
        })
        setItems(itemsArray)

        let responseApprovalsCapex = await api.get(`approvalscapex/${responseApprovals.data[0].id}`)

        setApprovalsJustificativa(responseApprovalsCapex.data.justification)
    }

    function openImg(photo) {
        if(!photo){
            return;
        }
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }


    const selectData = [
        { label: 'Completo', value: "Completo" },
        { label: 'Simplificado', value: "Simplificado" }
    ]

    function totalCost() {
        let totalCost = 0
        items.map(item => {
            totalCost = totalCost + Number(item.cost)
        })
        return totalCost
    }

    const columns = [
        {
            title: "Descrição",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 550,
            render: (row) => (<div>{row.description}</div>)
        },
        {
            title: "Custo",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 180,
            render: (row) => (<div>{`R$ ${row.cost}`}</div>)
        },

    ]
    function validation() {
        let validation = true

        if (aprovar === false && justificativa === '') {
            setJustificativaError('Digite uma justificativa/comentário')
            validation = false
        }

        return validation
    }

    async function handleSubmit() {
        try {
            if (validation()) {
                if (isApproved == null) {
                    const response = await api.post('/approvalscapex',
                        {
                            "capexId": capexId,
                            "isApproved": aprovar,
                            "justification": justificativa
                        }
                    )

                    if (response.status == 200) {
                        Swal.fire({
                            text: "Avaliação do CAPEX concluída com sucesso",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#009EBE",
                            confirmButtonText: "Ok"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = "/";
                            }
                        })
                    }
                } else {
                    const response = await api.put(`/approvalscapex/${capexId}`,
                        {
                            "capexId": capexId,
                            "isApproved": aprovar,
                            "justification": justificativa
                        }
                    )

                    if (response.status == 200) {
                        Swal.fire({
                            text: "Avaliação do CAPEX concluída com sucesso",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#009EBE",
                            confirmButtonText: "Ok"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = "/";
                            }
                        })
                    }
                }
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data.errors[0].msg}`
            })
        }

    }

    // modal logs

    async function getModalData() {
        let response = await api.get(`/capex/logs/${capexId}`)

        let logsArray = []
        response.data.map(x => {
            logsArray.push({ action: x.action, aprovador: x.Users.name, createdAt: x.createdAt, justification: x.justification })
        })

        setLogs(logsArray)
    }

    async function handleOpenModal() {
        setModalOpen(true)
        await getModalData()
    }

    function handleCloseModal() {
        setModalOpen(false)
    }

    const columnsAttachments = [
        {
            title: "Anexos",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.name}</div>)
        }
    ]

    const columnsLog = [
        {
            title: "Aprovador:",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 250,
            render: (row) => (<div>{row.aprovador}</div>)
        },
        {
            title: "Avaliação:",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.action}</div>)
        },
        {
            title: "Data:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{moment(row.createdAt).format("DD/MM/YYYY")}</div>)
        },
        {
            title: "Justificativa:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div>{row.justification}</div>)
        },
    ]

    return (
        <>
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Aprovação de CAPEX</Title>
                    <div onClick={() => setActiveCapexApproval(prevActiveHseApproval => !prevActiveHseApproval)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {activeCapexApproval == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>
                {activeCapexApproval ? (
                    <>
                        {moc.capexApproved || readOnly ? (
                            <>
                                <div className={`${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`} style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                                                <Table
                                                    data={items}
                                                    sticky={true}
                                                    columns={columns}
                                                    showHeader={true}
                                                    emptyText="Nenhuma ação disponível"
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6rem' }}>
                                                    <div className={styles.tableRow}>
                                                        <div style={{ width: '100%' }}>Total:</div>
                                                        <div style={{ width: '31%' }}>R${totalCost()} </div>
                                                    </div>
                                                    <div className={styles.tableRow}>
                                                        <div style={{ width: '100%' }}>Estimativa inicial:</div>
                                                        <div style={{ width: '31%' }}>R$ {moc.initialBudget}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ width: '100%' }} className="inputContainer">
                                            <Label htmlFor="">Comentários:</Label>
                                            <textarea
                                                value={commentary}
                                                rows='6'
                                                readOnly
                                                disabled
                                            />
                                        </div>

                                        <div style={{ width: '100%' }} className="inputContainer">

                                            <div className={styles.attachmentsDiv}>
                                                {anexos.length != 0 || fotos.length != 0 ?
                                                    <>
                                                        <div className={styles.attachmentsDiv}>
                                                            <div className={styles.imgHeader}>
                                                                <span>Anexos</span>
                                                            </div>
                                                            <div className={styles.imgDiv}>
                                                                {anexos.map(x => {
                                                                    return (
                                                                        <div className={styles.imgContainer}>
                                                                            {x.type.split('/')[1] == 'pdf' ?
                                                                                <a href={x.link}><FaFilePdf data-tip data-for={`pdf${x.id}`} color='#000' size={70} />
                                                                                    <ReactTooltip place="top" effect="solid" id={`pdf${x.id}`}>
                                                                                        <span>{x.name}</span>

                                                                                    </ReactTooltip>
                                                                                </a> :
                                                                                <a href={x.link}>
                                                                                    <FaFileExcel data-tip data-for={`excel${x.id}`} color='#000' size={70} />
                                                                                    <ReactTooltip place="top" effect="solid" id={`excel${x.id}`}>
                                                                                        <span>{x.name}</span>
                                                                                    </ReactTooltip>
                                                                                </a>}
                                                                        </div>
                                                                    )

                                                                })
                                                                }
                                                            </div>

                                                            <div className={styles.imgHeader}>
                                                                <span>Imagens</span>
                                                            </div>

                                                            <div className={styles.imgDiv}>
                                                                {fotos.map(x => {
                                                                    return (
                                                                        <div className={styles.imgContainer}>
                                                                            <img className={styles.img} src={x.link} alt="" onClick={() => openImg(x.link)} />
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className={styles.pdfDiv}>
                                                        <Table
                                                            sticky={true}
                                                            showHeader={true}
                                                            columns={columnsAttachments}
                                                            emptyText="Nenhum anexo disponível"
                                                        />
                                                    </div>}
                                                <ViewPic />
                                            </div>
                                        </div>

                                        <div style={{ width: '100%' }} className="inputContainer">
                                            <Label htmlFor="">Aprovador CAPEX:</Label>
                                            <AutoComplete
                                                disabled
                                                value={capexApprover}
                                                onChange={value => setCapexApprover(value)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                        <div style={{ width: '35rem' }} className={styles.formDiv}>
                                            <div>
                                                <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "#595959" }}>Aprova CAPEX para prosseguimento do MOC?</p>
                                                <RadioGroup name="radioList" inline value={aprovar}>
                                                    <Radio value={true}>Aprovar</Radio>
                                                    <Radio value={false}>Rejeitar</Radio>
                                                </RadioGroup>
                                            </div>
                                        </div>
                                        <div className={styles.formDiv}>
                                            <div className="inputContainer" style={{ width: '100%' }}>
                                                <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "#595959" }}>Justificativas/Comentários</p>
                                                <textarea name="" id="" cols="30" rows="5" value={approvalsJustificativa}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.enableDiv}>
                                    <Button
                                        onClick={async () => await handleOpenModal()}
                                        titulo="Nova ação">
                                        Logs do CAPEX
                                    </Button>
                                </div>

                            </>) :
                            <div className={styles.enableDiv} style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                    <div className={`${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`} style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>

                                        <div className={styles.table} style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                                            <Table
                                                data={items}
                                                sticky={true}
                                                columns={columns}
                                                showHeader={true}
                                                emptyText="Nenhuma ação disponível"
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6rem' }}>
                                                <div className={styles.tableRow}>
                                                    <div style={{ width: '100%' }}>Total:</div>
                                                    <div style={{ width: '31%' }}>R${totalCost()} </div>
                                                </div>
                                                <div className={styles.tableRow}>
                                                    <div style={{ width: '100%' }}>Estimativa inicial:</div>
                                                    <div style={{ width: '31%' }}>R$ {moc.initialBudget}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{ width: '100%' }} className={`inputContainer ${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`}>
                                        <Label htmlFor="">Comentários:</Label>
                                        <textarea
                                            value={commentary}
                                            rows='6'
                                        />
                                    </div>

                                    <div style={{ width: '100%' }} className={`inputContainer ${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`}>
                                        <div className={`${styles.attachmentsDiv} ${disableOpacity ? '' : styles.opacity}`}>
                                            {anexos.length != 0 || fotos.length != 0 ?
                                                <>
                                                    <div className={styles.attachmentsDiv}>
                                                        <div className={styles.imgHeader}>
                                                            <span>Anexos</span>
                                                        </div>
                                                        <div className={styles.imgDiv}>
                                                            {anexos.map(x => {
                                                                return (
                                                                    <div className={styles.imgContainer}>
                                                                        {x.type.split('/')[1] == 'pdf' ?
                                                                            <a href={x.link}><FaFilePdf data-tip data-for={`pdf${x.id}`} color='#000' size={70} />
                                                                                <ReactTooltip place="top" effect="solid" id={`pdf${x.id}`}>
                                                                                    <span>{x.name}</span>

                                                                                </ReactTooltip>
                                                                            </a> :
                                                                            <a href={x.link}>
                                                                                <FaFileExcel data-tip data-for={`excel${x.id}`} color='#000' size={70} />
                                                                                <ReactTooltip place="top" effect="solid" id={`excel${x.id}`}>
                                                                                    <span>{x.name}</span>
                                                                                </ReactTooltip>
                                                                            </a>}
                                                                    </div>
                                                                )

                                                            })
                                                            }
                                                        </div>

                                                        <div className={styles.imgHeader}>
                                                            <span>Imagens</span>
                                                        </div>

                                                        <div className={styles.imgDiv}>
                                                            {fotos.map(x => {
                                                                return (
                                                                    <div className={styles.imgContainer}>
                                                                        <img className={styles.img} src={x.link} alt="" onClick={() => openImg(x.link)} />
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className={styles.pdfDiv}>
                                                    <Table
                                                        sticky={true}
                                                        showHeader={true}
                                                        columns={columnsAttachments}
                                                        emptyText="Nenhum anexo disponível"
                                                    />
                                                </div>}
                                            <ViewPic />
                                        </div>
                                    </div>
                                    <div style={{ width: '100%' }} className={`inputContainer ${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`}>
                                        <Label htmlFor="">Aprovador CAPEX:</Label>
                                        <AutoComplete
                                            value={capexApprover}
                                            onChange={value => setCapexApprover(value)}
                                        />
                                    </div>
                                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                                        <div style={{ width: '35rem' }} className={styles.formDiv}>
                                            <div>
                                                <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "#595959" }}>Aprova CAPEX para prosseguimento do MOC?</p>
                                                <RadioGroup name="radioList" inline value={aprovar} onChange={(value) => {
                                                    setJustificativaError('')
                                                    setAprovar(value)
                                                }}>
                                                    <Radio value={true}>Aprovar</Radio>
                                                    <Radio value={false}>Rejeitar</Radio>
                                                </RadioGroup>
                                            </div>
                                        </div>
                                        <div className={`${styles.formDiv} ${styles.enableDiv}`}>
                                            <div className="inputContainer" style={{ width: '100%' }}>
                                                <p style={{ fontWeight: "400", fontSize: "0.9rem", color: "#595959" }}>Justificativas/Comentários</p>
                                                <textarea name="" id="" cols="30" rows="5" value={justificativa} onChange={(event) => {
                                                    setJustificativaError('')
                                                    setJustificativa(event.target.value)
                                                }}></textarea>
                                                {justificativaError ? (
                                                    <div className="inputError">{justificativaError}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.enableDiv} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            onClick={async () => await handleOpenModal()}
                                            titulo="Nova ação"
                                        >Logs do CAPEX</Button>
                                        <Button onClick={() => handleSubmit()}>Submeter</Button>
                                    </div>
                                </div>
                            </div>}
                        <Modal isOpen={modalOpen} onRequestClose={handleCloseModal}
                            shouldCloseOnOverlayClick={false}
                            ariaHideApp={false}
                            style={{
                                overlay: {
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(44, 42, 42, 0.707)'
                                },
                                content: {
                                    position: 'absolute',
                                    width: '50rem',
                                    height: '25rem',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    border: 'none',
                                    background: '#fff',
                                    overflow: 'auto',
                                    borderRadius: 'none',
                                    WebkitOverflowScrolling: 'touch',
                                    outline: 'none',
                                    padding: 'none',
                                }
                            }}>
                            <div className="headerModal">
                                <h3>Logs de aprovações de CAPEX </h3>
                                <h2 onClick={handleCloseModal}></h2>
                            </div>


                            <div className="modalContainer">
                                <div className="modalTableOverflowDiv" style={{ maxHeight: '100%' }}>
                                    <Table
                                        sticky={true}
                                        data={logs}
                                        columns={columnsLog}
                                        showHeader={true}
                                        emptyText="Nenhum log disponível"
                                    />
                                </div>

                                <div className="modalFooter">
                                    <Button onClick={() => handleCloseModal()}>Fechar</Button>
                                </div>
                            </div>
                        </Modal>
                    </>) : null}
            </div>
        </>
    )
}