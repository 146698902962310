import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from "sweetalert2";

import MaterialTable from 'material-table';

import { tableIcons } from "../../../assets/icons-table/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import NavPages from "../../../components/NavPages/navPages";
import { api } from '../../../services/api';

import moment from 'moment';

import styles from "../search-moc.module.css"
import ButtonPlus from '../../../components/ButtonPlus';
import { FaFilter } from 'react-icons/fa'

export default function MyApprovals() {
    const [data, setData] = useState([])
    const [filtering, setFiltering] = useState(false);
    const [isAdmin, setIsAdmin] = useState('')
    const [isId, setIsId] = useState('')

    useEffect(async () => {
        await getMoc()
        await getIdentify()
    }, [])

    async function getMoc() {
        let response = await api.get('/moc')
        console.log(response.data)
        setData(response.data)
    }

    async function getIdentify() { // identifica usuario logado e pega seus dados
        let response = await api.get('identify')
        setIsAdmin(response.data.isAdmin)
        setIsId(response.data.id)
    }

    const history = useHistory()


    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "Título do MOC",
            field: "title",
            align: "left",
            width: "80%"
        },
        {
            title: "Código do MOC",
            field: "codMoc",
            align: "left",
            render: (row) => (<div>{row.codMoc}</div>)
        },
        {
            title: "Data de abertura",
            align: "left",
            field: 'openDate',
            render: (row) => (<div>{moment.utc(row.openDate).format('DD/MM/yyyy')}</div>)
        },
        {
            title: "Site",
            align: "left",
            field: 'Sites.name',
            //render: (row) => (<div>{row.Sites.name}</div>)
        },
        {
            title: "Categoria",
            align: "left",
            field: 'Categories.name',
            //render: (row) => (<div>{row.Categories.name}</div>)
        },
        {
            title: "Modelo",
            align: "left",
            field: 'modelMoc',
            lookup: { Completo: 'Completo', Simplificado: 'Simplificado' },
            render: (row) => (<div>{row.modelMoc == null ? '---' : row.modelMoc}</div>)
        },
        {
            title: "Solicitante",
            align: "left",
            field: 'Users.name',
        },
        {
            title: "Fiscal year",
            align: "left",
            field: 'fiscalYear',
            width: "10%",
        },
        {
            title: "Status",
            align: "left",
            width: "80%",
            field: 'Steps.name',
        },
        {
            title: <div onClick={() => { setFiltering(currentFilter => !currentFilter) }}><FaFilter fontSize="medium" /></div>,
            align: "left",
        },

    ]


    async function handleDeleteMoc(key, mocId) {
        try {
            Swal.fire({
                title: `Deletar o ${mocId} ?`,
                icon: 'warning',
                text: 'Após deletar esse MOC, não será possível recuperá-lo. Deseja prosseguir ?',
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                cancelButtonColor: "#636e72",
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Prosseguir',
                allowOutsideClick: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await api.delete(`/moc/${key}`)

                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            text: 'MOC deletado'
                        })
                        getMoc()
                    }
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }

    function onRowClick(rowData, index) {
        console.log(rowData)
        history.push('/buscar-moc/visualizar-moc', { state: rowData.id, capexEdit: rowData.Steps.id == 13 ? true : false, title: rowData.title })
    }

    function onRowClickEdit(record, index) {
        console.log(record)
        history.push('/buscar-moc/editar-moc', { state: record })
    }

    return (
        <>
            <NavPages title="Buscar MOC" goHome={true} />
            <div className="mainDiv">
                <div className={styles.newMoc}>
                    <ButtonPlus onClick={() => history.push("/novo-moc")} titulo='Novo moc' />
                </div>
                <div className={styles.materialTable}>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (<div style={{ height: "0px", }}></div>),
                        }}
                        icons={tableIcons}
                        columns={columns}
                        onRowClick={(event, rowData) => { onRowClick(rowData) }}
                        data={data}
                        title=''
                        actions={[
                            rowData => ({
                                icon: () => (
                                    <Edit fontSize="medium"
                                        className={styles.colorsIcons}
                                    />
                                ),
                                tooltip: 'Editar MOC',
                                width: 200,
                                onClick: (event, rowData) => {
                                    onRowClickEdit(rowData.id)
                                },
                                hidden: ( rowData.stepId == 5 || rowData.stepId == 12 ) || isAdmin != true 
                            }),
                            rowData => ({
                                icon: () => (
                                    <DeleteIcon fontSize="medium"
                                        className={styles.colorsIcons}
                                    />
                                ),
                                tooltip: 'Deletar MOC',
                                width: 200,

                                onClick: (event, rowData) => { handleDeleteMoc(rowData.id, rowData.codMoc) },
                                hidden: isAdmin != true
                            }),

                        ]}

                        localization={{
                            body: {
                                emptyDataSourceMessage: "Nenhum MOC disponível",
                            },
                            header: {
                                actions: ''
                            }
                        }}

                        options={{
                            pageSize: 5,
                            pageSizeOptions: [0],
                            actionsColumnIndex: 9,
                            filtering: filtering,
                            search: false,
                            maxBodyHeight: '30rem',
                            minBodyHeight: '30rem',
                            exportButton: false,
                            //actionsCellStyle:{display:"flex", justifyContent: "center",marginBottom:' -1px'},
                            rowStyle: {
                                fontSize: '0.9rem',

                            }, headerStyle: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',

                            }, headerStyleHover: {
                                backgroundColor: 'var(--dark-blue)',
                                color: '#FFF',
                            },

                        }}
                    />
                </div>
            </div>
        </>
    )
}