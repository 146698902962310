import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Header from "../../components/Header/header";

import SearchMoc from "./search-moc-main";
import ViewMoc from "./view-moc";
import EditMoc from "./search-moc-edit";
import { EditMocProvider } from "../../providers/edit-moc";

export default function NewMocHome() {
  let { path } = useRouteMatch();
  return (
    <>
    <EditMocProvider>
      <Header />
      <div>
        <Switch>
          <Route path={`${path}/`} exact component={SearchMoc} />
          <Route path={`${path}/visualizar-moc`} component={ViewMoc} />
          <Route path={`${path}/editar-moc`} component={EditMoc} />

        </Switch>
      </div>
      </EditMocProvider>
    </>
  )
}