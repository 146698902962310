
import React from 'react'

import styles from "./loadingContent.module.css"

export default function LoadingContent({ isLoading, children }) {
    return (
        <div className={!isLoading ? `${styles.container}` : `${styles.container} ${styles.containerOpacity}`}>
            {isLoading && <div className={styles.overlay}>
                <div className={styles.loaderWheel}></div>
            </div>}
            {children}
        </div>
    )
}