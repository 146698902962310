import { useEffect, useState } from "react"
import { Radio, RadioGroup } from "rsuite"
import { api } from "../../../services/api"
import Title from "../../Title/title"

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"

import styles from "./checklistSupport.module.css"

export default function ChecklistSupport({ id, activeToggle, disableOpacity }) {
    const [check1, setCheck1] = useState('')
    const [check2, setCheck2] = useState('')
    const [check3, setCheck3] = useState('')

    const [activeSupportChecklist, setActiveSupportChecklist] = useState(false)

    useEffect(() => {
        if (activeToggle) {
            setActiveSupportChecklist(true)
        }
    }, [activeToggle])

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let responseChecklist = await api.get(`/supportChecklist/${id}`)

        setCheck1(responseChecklist.data.questionOne)
        setCheck2(responseChecklist.data.questionTwo)
        setCheck3(responseChecklist.data.questionThree)
    }

    return (
        <>
            <div className={styles.basicInfoDiv}>
                <div className={styles.basicInfoTitle}>
                    <Title>Checklist de pré-moc</Title>

                    <div onClick={() => {
                        setActiveSupportChecklist(prevActiveSupportChecklist => !prevActiveSupportChecklist)
                    }
                    }
                        style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {activeSupportChecklist == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>

                {activeSupportChecklist ?
                    <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <div className={`${styles.questionDiv} ${disableOpacity ? '' : styles.opacity}`}>

                            <div className={styles.questionBox}>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>1)</span> <span>Essa mudança envolve atividades de alto risco relacionadas as Regras que Salvam Vidas ou alterações ou revisões que impactem nas Licenças ou Permits do site?</span>
                                </div>

                                <RadioGroup name="radioList" inline value={check1}>
                                    <Radio value={'true'}>Sim</Radio>
                                    <Radio value={'false'}>Não</Radio>
                                    <Radio value={'null'}>Não aplicável</Radio>
                                </RadioGroup>
                            </div>

                            <div className={styles.questionBox}>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>2)</span> <span>Serão contratos terceiros ou prestadores de serviço para executar atividades dentro do site que envolvam atividades relacionadas a: LOTO, Segurança com Eletricidade, Trabalho em Altura, Içamento de Cargas, Espaço Confinados, Operações de Máquinas Pesadas, Aplicação de Produtos Químicos, Asbestos (amianto) ou qualquer risco de impacto direto a vida das pessoas?</span>
                                </div>

                                <RadioGroup name="radioList" inline value={check2}>
                                    <Radio value={'true'}>Sim</Radio>
                                    <Radio value={'false'}>Não</Radio>
                                    <Radio value={'null'}>Não aplicável</Radio>
                                </RadioGroup>
                            </div>

                            <div className={styles.questionBox}>
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>3)</span> <span>Após a finalização desta mudança, será introduzido nas atividades operacionais algum alto risco que se relaciona com as Regras que Salvam Vidas?</span>
                                </div>

                                <RadioGroup name="radioList" inline value={check3}>
                                    <Radio value={'true'}>Sim</Radio>
                                    <Radio value={'false'}>Não</Radio>
                                    <Radio value={'null'}>Não aplicável</Radio>
                                </RadioGroup>
                            </div>
                        </div>


                    </div>

                    :

                    null
                }

            </div>
        </>
    )
}