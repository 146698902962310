import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import styles from "./header.module.css"
import user from "../../assets/home/user.png"
import { api } from '../../services/api'
import goToHome from "../../assets/header/goToHome.svg"


export default function Header() {
    const history = useHistory()

    const [name, setName] = useState('')
    const [isAdmin, setIsAdmin] = useState('')
    const [isApprover, setIsApprover] = useState('')

    async function getIdentify() { // identifica usuario logado e pega seus dados
        let response = await api.get('identify')
        setName(response.data.name)
        setIsAdmin(response.data.isAdmin)
        setIsApprover(response.data.isApprover)
    }

    useEffect(async () => {
        await getIdentify()
    }, [])

    return (
        <div className={styles.header}>
            <div className={styles.headerTitle}>
                <span>Gestão de <span style={{ fontWeight: 'normal' }}>Mudanças</span></span>
            </div>

            <div className={styles.headerNav}>
                <div onClick={() => history.push("/")} className={styles.headerHome}>
                    <img style={{width:'2rem'}} src={goToHome} alt="" />
                </div>

                <div className={styles.verticalLine}></div>

                <div className={styles.headerUser}>
                    <img src={user} alt="" />
                    <div style={{ display: "flex", flexDirection: "column", gap: '0.1rem' }}>
                        <span style={{ color: "#009EBE" }}>{name}</span>
                        <span style={{ color: "#FFFFFF" }}> 
                        {isAdmin == true ?
                            "Administrador":
                            <>
                                {isApprover == true ?
                                "Aprovador"
                                :"Usuário padrão"}
                            </>
                        }</span>
                    </div>
                </div>
            </div>
        </div>

    )
}