import React, { useState, useContext } from 'react';
import pic from "../../../../assets/login.svg";
import styles from "../../login.module.css";
import { api } from "../../../../services/api";
import Swal from "sweetalert2";
import {  AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { useParams } from "react-router-dom";

export default function Login() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const togglePasswordConfirmVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true);
  };

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  let { id } = useParams();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timerProgressBar: true,
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  function validation() {
      let validation = true
    
      if (password == '') {
        setPasswordError('Digite uma senha.')
          validation = false
      }

      if (confirmPassword == '') {
        setConfirmPasswordError('Digite a confirmação de senha.')
          validation = false
      }

      if (password.length < 8) {
          setPasswordError('Senha muito curta.')
          validation = false
      }

      if (password !== confirmPassword)  {
          setConfirmPasswordError('As senhas não coincidem.')
          validation = false
      }
      return validation
   }

  async function onSubmit(event) {
    event.preventDefault();
    try {
      if (validation()) {
        let response = await api.post('/resetpassword', {
          "password": password,
          "id": id
        })

       if (response.status == 200) {
        Swal.fire({
          text: 'Senha alterada com sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#46B8D3',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/login";    
          }
        })
       }
      }
    } catch (error) {
      Swal.fire({
        text: 'Erro ao alterar a senha!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#46B8D3',
         confirmButtonText: 'Ok'
       }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/login";    
        }
      })
    }
  }

return (
  <form onSubmit={onSubmit}>
    <div className={styles.loginBackground}>
      <div className={styles.loginleft}>
        <h1>Gestão de <strong> Mudanças</strong></h1>
        
        <img src={pic} />
      </div>
      <div className={styles.loginright}>
        <h1>Reset de senha</h1>
        <p>Senha:</p>
        <div>
          <input value={password} onChange={(event) => {
            setPasswordError('')
            setPassword(event.target.value)
            }} 
            type={passwordShown ? "text" : "password"} />
            {passwordShown == true?
          <AiFillEyeInvisible color='#000'   onClick={togglePasswordVisiblity}/>:
            <AiFillEye color='#000'  onClick={togglePasswordVisiblity}/>}
        </div>
        {passwordError ? (
          <div className="inputError">{passwordError}</div>
        ) : null}
        
       <p> Confirmação da senha:</p>
       <div>
        <input value={confirmPassword} onChange={(event) => {
          setConfirmPasswordError('')
          setConfirmPassword(event.target.value)
          }} 
          type={passwordConfirmShown ? "text" : "password"}
          />
          {passwordConfirmShown == true?
         <AiFillEyeInvisible color='#000' onClick={togglePasswordConfirmVisiblity}/>:
          <AiFillEye color='#000'  onClick={togglePasswordConfirmVisiblity}/>}
          </div>
        {confirmPasswordError ? (
          <div className="inputError">{confirmPasswordError}</div>
        ) : null}
         <button  style={{marginLeft: "0px"}} type="submit">
            Salvar
        </button>
      </div>
    </div>
  </form>
);
}