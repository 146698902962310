
import React, { useEffect, useState } from "react"
import styles from "./fileUploaderCapex.module.css"
import ReactTooltip from 'react-tooltip';

import AddButton from "../addButton";

import { AiOutlinePaperClip } from 'react-icons/ai'
import { useEditMoc } from "../../providers/edit-moc";

import Swal from "sweetalert2";

import { FaTimes } from 'react-icons/fa'
import Table from "rc-table";
import ViewPic from '../viewPic'

import excel from '../../assets/Excel.ico'
import pdf from '../../assets/PDF.png'
import { FaTimesCircle, FaFilePdf, FaFileExcel } from 'react-icons/fa'

export default function CapexFileUploader({ addedFiles, setAddedFiles }) {
    const [selectedFile, setSelectedFile] = useState(null)

    const [anexos, setAnexos] = useState([])
    const [fotos, setFotos] = useState([])
    const { setIsOpenModalPic } = useEditMoc()
    const { setUploadFotos } = useEditMoc()


    useEffect(async () => {
        setIsOpenModalPic(false)
    }, [])

    useEffect(() => {
        let fotos = []
        let anexos = []
        addedFiles.map(attachment => {
            if (attachment.type == 'image/png' || attachment.type == 'image/jpeg') {
                fotos.push(attachment)
            } else {
                anexos.push(attachment)
            }
        })

        setFotos(fotos)
        setAnexos(anexos)
    }, [addedFiles])


    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    function handleClick() {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    function handleChange(event) {
        const fileUploaded = event.target.files[0];
        event.target.value = '' // reseta o input para poder selecionar o mesmo arquivo múltiplas vezes
        setSelectedFile(fileUploaded)
    };

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    function handleAddFile() {
        if (selectedFile !== null) {

            const file = selectedFile

            if (addedFiles.filter(x => x.name === selectedFile.name).length > 0) {
                Toast.fire({
                    icon: 'error',
                    text: 'Arquivo já adicionado!'
                })
                return
            } else {
                setAddedFiles([...addedFiles, file])
                let fotos = []
                let anexos = []
                addedFiles.map(attachment => {
                    if (attachment.type == 'image/png' || attachment.type == 'image/jpeg') {
                        fotos.push(attachment)
                    } else {
                        anexos.push(attachment)
                    }
                })
                setFotos(fotos)
                setAnexos(anexos)
                setSelectedFile(null)
            }

        }

    }

    function openImg(photo) {
        if(!photo){
            return;
        }
        setIsOpenModalPic(true)
        setUploadFotos(photo)
    }

    function removeFile(el) {
        let newAddedFiles = addedFiles.filter(x => x.name != el)
        setAddedFiles(newAddedFiles)
        setSelectedFile(null)
    }


    const columnsAttachments = [
        {
            title: "Anexos",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.name}</div>)
        }
    ]

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className={styles.uploadDiv}>
                    <div className={styles.uploadInput}>
                        <span>{selectedFile === null || selectedFile === '' || selectedFile === undefined ? "" : selectedFile.name}</span>

                        <div onClick={() => handleClick()} style={{ color: '#009EBE', width: '1rem', fontSize: '1rem', padding: '0.35rem 0.2rem 0rem 0.2rem', marginRight: '0.5rem', cursor: 'pointer' }}>
                            <AiOutlinePaperClip />
                        </div>
                    </div>
                    <div onClick={() => handleAddFile()} >
                        <AddButton />
                    </div>

                    <input
                        type="file"
                        accept="application/pdf,.csv,image/png,image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        ref={hiddenFileInput}
                        onChange={(event) => handleChange(event)}
                        style={{ display: 'none' }}
                    />
                </div>

                <div className={styles.attachmentsDiv}>
                    {anexos.length != 0 || fotos.length != 0 ?
                        <>
                            <div className={styles.attachmentsDiv}>
                                <div className={styles.imgHeader}>
                                    <span>Anexos</span>
                                </div>
                                {anexos.length != 0 ?
                                    <div className={styles.imgDiv}>
                                        {anexos.map(x => {
                                            return (
                                                <div className={styles.imgContainer}>
                                                    {x.type.split('/')[1] == 'pdf' ?
                                                        <a href={x.link}><FaFilePdf data-tip data-for={`pdf${x.id}`} color='#000' size={70} />
                                                            <ReactTooltip place="top" effect="solid" id={`pdf${x.id}`}>
                                                                <span>{x.name}</span>

                                                            </ReactTooltip>
                                                        </a> :
                                                        <a href={x.link}>
                                                            <FaFileExcel data-tip data-for={`excel${x.id}`} color='#000' size={70} />
                                                            <ReactTooltip place="top" effect="solid" id={`excel${x.id}`}>
                                                                <span>{x.name}</span>
                                                            </ReactTooltip>
                                                        </a>}
                                                    <div onClick={() => removeFile(x.name)} className={styles.deleteImgIcon}>
                                                        <FaTimes />
                                                    </div>
                                                </div>
                                            )

                                        })
                                        }

                                    </div> :
                                    <div className={styles.pdfDiv}>
                                        <Table
                                            sticky={true}
                                            showHeader={false}
                                            emptyText="Nenhum anexo disponível"
                                        />
                                    </div>}
                                <div className={styles.imgHeader}>
                                    <span>Imagens</span>
                                </div>
                                {fotos.length != 0 ?
                                    <div className={styles.imgDiv}>
                                        {fotos.map(x => {
                                            return (
                                                <div className={styles.imgContainer}>
                                                    {x.type === 'image/png' && x.id != undefined &&
                                                        <>
                                                            <img className={styles.img} src={x.link} alt='' title={x.name} onClick={() => openImg(x.link)} />
                                                            <div onClick={() => removeFile(x.name)} className={styles.deleteImgIcon}>
                                                                <FaTimes />
                                                            </div>
                                                        </>
                                                    }
                                                    {x.type === 'image/png' && x.id == undefined &&
                                                        <>
                                                            <img className={styles.img} src={URL.createObjectURL(x)} alt='' title={x.name} onClick={() => openImg(x.link)} />
                                                            <div onClick={() => removeFile(x.name)} className={styles.deleteImgIcon}>
                                                                <FaTimes />
                                                            </div>
                                                        </>
                                                    }
                                                    {x.type === 'image/jpeg' && x.id != undefined &&
                                                        <>
                                                            <img className={styles.img} src={x.link} alt='' title={x.name} onClick={() => openImg(x.link)} />
                                                            <div onClick={() => removeFile(x.name)} className={styles.deleteImgIcon}>
                                                                <FaTimes />
                                                            </div>
                                                        </>
                                                    }
                                                    {x.type === 'image/jpeg' && x.id == undefined &&
                                                        <>
                                                            <img className={styles.img} src={URL.createObjectURL(x)} alt='' title={x.name} onClick={() => openImg(x.link)} />
                                                            <div onClick={() => removeFile(x.name)} className={styles.deleteImgIcon}>
                                                                <FaTimes />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })
                                        }

                                    </div> :
                                    <div className={styles.pdfDiv}>
                                        <Table
                                            sticky={true}
                                            showHeader={false}
                                            emptyText="Nenhum anexo disponível"
                                        />
                                    </div>}
                            </div>
                        </>
                        :
                        <div className={styles.pdfDiv}>
                            <Table
                                sticky={true}
                                showHeader={false}
                                emptyText="Nenhum anexo disponível"
                            />
                        </div>}
                    <ViewPic />

                </div>
            </div>
        </>
    )
}