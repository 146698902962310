import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom"

import NavPages from "../../../components/NavPages/navPages";
import Title from "../../../components/Title/title";

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"

import styles from '../new-moc.module.css'
import Label from "../../../components/Label/label";
import Button from "../../../components/Button"
import { Checkbox, Radio, RadioGroup, SelectPicker } from "rsuite";
import Table from "rc-table";

import moment from "moment";

import { api } from "../../../services/api";
import { addAttachment } from '../../../services/api'

import { useNewMoc } from "../../../providers/new-moc";

import Swal from "sweetalert2";
import Spinner from "../../../components/spinner";


export default function Checklist() {
    const [activeDiv, setActiveDiv] = useState(false)

    const { titulo, setTitulo } = useNewMoc()
    const { solicitante, setSolicitante } = useNewMoc()
    const { data, setData } = useNewMoc()
    const { custo, setCusto } = useNewMoc()
    const { fiscal, setFiscal } = useNewMoc()
    const { selectedSiteName, setSelectedSiteName } = useNewMoc()
    const { selectedCategoriaName, setSelectedCategoriaName } = useNewMoc()
    const { selectedTipoName, setSelectedTipoName } = useNewMoc()
    const { site, setSite } = useNewMoc()
    const { categoria, setCategoria } = useNewMoc()
    const { tipo, setTipo } = useNewMoc()
    const { descricao, setDescricao } = useNewMoc()
    const { anexos, setAnexos } = useNewMoc()
    const { fotos, setFotos } = useNewMoc()
    const { hse, setHse } = useNewMoc()
    const {checkAnswers, setCheckAnswers} = useNewMoc()
    const initialErrorState = {
        check1: '',
        check2: '',
        check3: '',
    };
    const [checkErrors, setCheckErrors] = useState({initialErrorState});

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)


    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    function validation() {
        let isValid = true;
      
        ['check1', 'check2', 'check3'].forEach((check) => {
          if (checkAnswers[check] === '') {
            setCheckErrors((prevErrors) => ({
              ...prevErrors,
              [check]: 'Selecione uma opção!',
            }));
            isValid = false;
          }
        });
      
        return isValid;
    }
    
    async function handleSubmit() {
        if (validation()) {
            try {
                setIsLoadingSubmit(true)

                let responseMoc = await api.post('/moc',
                    {
                        "idSolicitante": solicitante,
                        "idSite": site,
                        "idCategory": categoria,
                        "idType": tipo,
                        "title": titulo,
                        "openDate": data,
                        "initialBudget": custo,
                        "fiscalYear": fiscal,
                        "description": descricao,
                        "hseSupport": hse,
                        "isApproved": null,
                        "justification": null,
                        "needCapexApproval": null
                    }
                )

                if (responseMoc.status == 200) {

                    if (responseMoc.status == 200) {
                        if (anexos.length != 0) {
                            anexos.map(async (anexo) => {

                                let responseUpload = await addAttachment('mocAttactments/upload', {
                                    "mocId": responseMoc.data.id,
                                    "file": anexo
                                })

                            })
                        }

                        if (fotos.length != 0) {
                            fotos.map(async (image) => {

                                let responseUpload = await addAttachment('mocAttactments/upload', {
                                    "mocId": responseMoc.data.id,
                                    "file": image
                                })
                            })
                        }

                        let response = await api.post('/supportChecklist',
                            {
                                "mocId": responseMoc.data.id,
                                "questionOne": checkAnswers.check1,
                                "questionTwo": checkAnswers.check2,
                                "questionThree": checkAnswers.check3
                            }
                        )


                        if (response.status == 200) {
                            let modelo
                            if (checkAnswers.check1 === 'true' || checkAnswers.check2 === 'true' || checkAnswers.check3 === 'true' ) {
                                modelo = 'Completo'
                            } else {
                                modelo = 'Simplificado'
                            }

                            Swal.fire({
                                title: "MOC criado com sucesso!",
                                html: `<span>Modelo sugerido: ${modelo}</span>` + "</br>" + "<span>Aguarde a validação do HSE para seguir com seu MOC.</span>",
                                icon: "success",
                                showCancelButton: false,
                                confirmButtonColor: "#009EBE",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = "/";
                                }
                            })
                            setIsLoadingSubmit(false)

                        }
                    }
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    text: `${error}`
                })
                setIsLoadingSubmit(false)

            }
        }
    }


    const columnsAttachments = [
        {
            title: "Anexos",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 1000,
            render: (row) => (<div>{row.name}</div>)
        }
    ]

    return (
        <>
        {isLoadingSubmit == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}

            <NavPages title="Novo MOC" />


            <div className="mainDiv">

                <div className={styles.basicInfoDiv}>

                    <div className={styles.basicInfoTitle}>
                        <Title>Informações básicas</Title>

                        <div onClick={() => setActiveDiv(prevActiveDiv => !prevActiveDiv)} style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            {activeDiv == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                        </div>
                    </div>

                    {activeDiv ?
                        <div style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', pointerEvents: 'none', opacity: '0.8' }}>
                            <div className={styles.inputDiv}>
                                <div style={{ width: '100%' }} className="inputContainer">
                                    <Label htmlFor="">Título do MOC</Label>
                                    <input value={titulo} disabled type="text" />
                                </div>
                                <div style={{ width: '25%' }} className="inputContainer">
                                    <Label htmlFor="">Solicitante</Label>
                                    <input disabled type="text" />
                                </div>
                                <div style={{ width: '25%' }} className="inputContainer">
                                    <Label htmlFor="">Data de abertura</Label>
                                    <input value={data} disabled type="date" />
                                </div>
                            </div>

                            <div className={styles.inputDiv}>
                                <div style={{ width: '40%' }} className="inputContainer">
                                    <Label htmlFor="">Custo previsto</Label>
                                    <input value={custo} disabled type="text" />
                                </div>
                                <div style={{ width: '40%' }} className="inputContainer">
                                    <Label htmlFor="">Fiscal year de implementação</Label>
                                    <input value={fiscal} disabled type="text" />
                                </div>
                                <div style={{ width: '25%' }} className="inputContainer">
                                    <Label htmlFor="">Site</Label>
                                    <SelectPicker
                                        readOnly
                                        appearance="default"
                                        style={{ width: '100%' }}
                                        placeholder={selectedSiteName}
                                        value={selectedSiteName}
                                    />

                                </div>
                                <div style={{ width: '25%' }} className="inputContainer">
                                    <Label htmlFor="">Categoria</Label>
                                    <SelectPicker
                                        readOnly
                                        appearance="default"
                                        style={{ width: '100%' }}
                                        placeholder={selectedCategoriaName}
                                        value={selectedCategoriaName}
                                    />

                                </div>
                                <div style={{ width: '20%' }} className="inputContainer">
                                    <Label htmlFor="">Tipo</Label>
                                    <SelectPicker
                                        readOnly
                                        appearance="default"
                                        style={{ width: '100%' }}
                                        placeholder={selectedTipoName}
                                        value={selectedTipoName}
                                    />

                                </div>
                            </div>

                            <div className="inputContainer" style={{ width: '100%', marginTop: '1%' }} >
                                <Label htmlFor="">Descrição da mudança:</Label>
                                <textarea value={descricao} disabled name="" id="" cols="30" rows="5"></textarea>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1rem' }}>
                                    <Checkbox value={hse} checked={hse} disabled >Desejo apoio do HSE no preenchimento dos checklists</Checkbox>
                                </div>
                            </div>
                        </div>
                        :
                        null

                    }

                </div>

                <div className={styles.questionDiv}>
                    <Title>Informações complementares - Checklist de pré-moc</Title>


                    <div className={styles.questionBox}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>1)</span> <span>Essa mudança envolve atividades de alto risco relacionadas as Regras que Salvam Vidas ou alterações ou revisões que impactem nas Licenças ou Permits do site?</span>
                        </div>

                        <RadioGroup name="radioList" inline value={checkAnswers.check1} onChange={(value) => {
                            setCheckErrors(initialErrorState)
                            setCheckAnswers(prev=> ({...prev, check1: value}))
                        }}>
                            <Radio value={'true'}>Sim</Radio>
                            <Radio value={'false'}>Não</Radio>
                            <Radio value={'null'}>Não aplicável</Radio>
                        </RadioGroup>

                        {checkErrors.check1 ? (
                            <div className="inputError">{checkErrors.check1}</div>
                        ) : null}
                    </div>

                    <div className={styles.questionBox}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>2)</span> <span>Serão contratos terceiros ou prestadores de serviço para executar atividades dentro do site que envolvam atividades relacionadas a: LOTO, Segurança com Eletricidade, Trabalho em Altura, Içamento de Cargas, Espaço Confinados, Operações de Máquinas Pesadas, Aplicação de Produtos Químicos, Asbestos (amianto) ou qualquer risco de impacto direto a vida das pessoas?</span>
                        </div>

                        <RadioGroup name="radioList" inline value={checkAnswers.check2} onChange={(value) => {
                            setCheckErrors(initialErrorState)
                            setCheckAnswers(prev=> ({...prev, check2: value}))
                        }}>
                            <Radio value={'true'}>Sim</Radio>
                            <Radio value={'false'}>Não</Radio>
                            <Radio value={'null'}>Não aplicável</Radio>
                        </RadioGroup>

                        {checkErrors.check2 ? (
                            <div className="inputError">{checkErrors.check2}</div>
                        ) : null}
                    </div>

                    <div className={styles.questionBox}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>3)</span> <span>Após a finalização desta mudança, será introduzido nas atividades operacionais algum alto risco que se relaciona com as Regras que Salvam Vidas?</span>
                        </div>

                        <RadioGroup name="radioList" inline value={checkAnswers.check3} onChange={(value) => {
                            setCheckErrors(initialErrorState)
                            setCheckAnswers(prev=> ({...prev, check3: value}))
                        }}>
                            <Radio value={'true'}>Sim</Radio>
                            <Radio value={'false'}>Não</Radio>
                            <Radio value={'null'}>Não aplicável</Radio>
                        </RadioGroup>

                        {checkErrors.check3 ? (
                            <div className="inputError">{checkErrors.check3}</div>
                        ) : null}
                    </div>

                 
                </div>
               

                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '4%' }}>
                    <Button onClick={goBack}>Anterior</Button>
                    <Button onClick={handleSubmit}>Submeter</Button>
                </div>

            </div>

        </>
    )
}
