import Table from "rc-table"
import { useEffect, useState } from "react"
import { AutoComplete } from "rsuite"
import Swal from "sweetalert2";

import { addAttachment, api } from "../../services/api"
import AddButton from "../addButton"
import Label from "../Label/label"

import FileUploader from "../fileUploader"
import Title from "../Title/title"
import Button from "../Button"

import styles from "./newCapex.module.css"
import deleteIcon from '../../assets/delete.svg'
import CapexFileUploader from "../fileUploaderCapex";

export default function NewCapex({ mocId }) {

    const [description, setDescription] = useState('')
    const [cost, setCost] = useState('')
    const [capexApprover, setCapexApprover] = useState(null)
    const [comments, setComments] = useState('')
    const [attachments, setAttachments] = useState(null)

    const [descriptionError, setDescriptionError] = useState('')
    const [costError, setCostError] = useState('')
    const [capexApproverError, setCapexApproverError] = useState('')
    const [commentsError, setCommentsError] = useState('')
    const [attachmentsError, setAttachmentsError] = useState('')
    const [addedItemsError, setAddedItemsError] = useState('')

    const [addedItems, setAddedItems] = useState([])
    const [users, setUsers] = useState([])
    const [userData, setUserData] = useState([])
    const [capexApproverId, setCapexApproverId] = useState('')

    const [addedFiles, setAddedFiles] = useState([])

    const [moc, setMoc] = useState({})

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let responseUsers = await api.get('users')

        setUserData(responseUsers.data)

        let usersArray = []
        responseUsers.data.map(user => {
            usersArray.push({ label: user.name, value: user.name })
        })

        setUsers(usersArray)

        let responseMoc = await api.get(`moc/${mocId}`)
        setMoc(responseMoc.data)
    }


    function handleCapexApprover(value) {
        let filtered = userData.filter(x => x.name == value)

        if (filtered.length != 0) {
            setCapexApproverId(filtered[0].id)
        }

        setCapexApprover(value)
    }

    function validationItem() {
        let validation = true

        if (description.trim() == '') {
            setDescriptionError('Digite uma descrição')
            validation = false
        }

        if (cost.trim() == '') {
            setCostError('Digite um custo')
            validation = false
        }
        return validation
    }

    function addItem() {
        if (validationItem()) {
            let addedItemsArray = [...addedItems]

            addedItemsArray.push({ description: description, cost: cost })

            setAddedItems(addedItemsArray)
            setAddedItemsError('')
            setDescription('')
            setCost('')
        }
    }

    function removeItem(el) {
        let newAddedItems = addedItems.filter(x => x.description != el)
        setAddedItems(newAddedItems)
    }

    function totalCost() {
        let totalCost = 0

        addedItems.map(item => {
            totalCost = totalCost + Number(item.cost)
        })

        return totalCost
    }



    function validation() {
        let validation = true

        if (addedItems.length == 0) {
            setAddedItemsError('Adicione pelo menos um item')
            validation = false
        }

        if (comments.trim() == '') {
            setCommentsError('Digite um comentário')
            validation = false
        }

        if (capexApprover == null) {
            setCapexApproverError('Selecione um aprovador Capex')
            validation = false
        }

        return validation
    }

    async function submitCapex() {

        if (validation()) {
            try {
                let response = await api.post('capex', {
                    "mocId": mocId,
                    "capexApprover": capexApproverId,
                    "commentary": comments
                })

                if (response.status == 200) {
                    let responseDescription = []
                    await Promise.all(addedItems.map(async (item) => {
                        let responseDesc = await api.post('capexdescriptions', {
                            "capexId": response.data.id,
                            "expense": item.cost,
                            "description": item.description
                        })

                        responseDescription.push(responseDesc)

                        return responseDescription
                    }))
                    if (responseDescription[0].status == 200) {
                        if (addedFiles.length != 0) {
                            addedFiles.map(async file => {

                                let responseUpload = await addAttachment('capexAttactments/upload', {
                                    "capexId": response.data.id,
                                    "file": file
                                })
                            })
                        }
                        Swal.fire({
                            title: 'Capex criado com sucesso!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: "#009EBE",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = "/";
                            }
                        })

                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: `${responseDescription.data.errors[0].msg}`
                        })
                    }

                }


            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: `${error.response.data.errors[0].msg}`
                })
            }
        }
    }


    const columns = [
        {
            title: "",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 550,
            render: (row) => (<div>{row.description}</div>)
        },
        {
            title: "",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 180,
            render: (row) => (<div>{`R$ ${row.cost}`}</div>)
        },
        {
            title: "",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 5,
            render: (row) => (<div onClick={() => removeItem(row.description)} style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}><img style={{ display: 'block', width: '1.2rem', height: '1.2rem' }} src={deleteIcon} alt='' /></div>)
        },
    ]

    const columnsLog = [
        {
            title: "Aprovador:",
            dataIndex: "",
            key: "title",
            align: "left",
            width: 250,
            render: (row) => (<div></div>)
        },
        {
            title: "Avaliação:",
            dataIndex: "",
            key: "cod",
            align: "left",
            width: 150,
            render: (row) => (<div></div>)
        },
        {
            title: "Data:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div></div>)
        },
        {
            title: "Comentário:",
            dataIndex: "",
            key: "data",
            align: "left",
            width: 150,
            render: (row) => (<div></div>)
        },
    ]

    return (
        <div style={{ padding: '1rem 2rem', boxShadow: '0px 0px 6px #00000029', borderRadius: '4px' }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                    <div className="flex">
                        <div style={{ width: '100%' }} className="inputContainer">
                            <Label htmlFor="">Descrição:</Label>
                            <input type="text"
                                value={description}
                                onChange={(event) => {
                                    setDescriptionError('')
                                    setDescription(event.target.value)
                                }}
                            />
                            {descriptionError ?
                                <div className="inputError">{descriptionError}</div>
                                : null}
                        </div>
                        <div style={{ width: '40%' }} className="inputContainer">
                            <Label htmlFor="">Custo:</Label>
                            <div className="flex">
                                <input type="number"
                                    value={cost}
                                    onChange={(event) => {
                                        setCostError('')
                                        setCost(event.target.value)
                                    }}
                                />
                                <AddButton onClick={() => addItem()}></AddButton>
                            </div >
                            {costError ?
                                <div className="inputError">{costError}</div>
                                : null}
                        </div>
                    </div>

                    {addedItemsError ?
                        <div className="inputError">{addedItemsError}</div>
                        : null}

                    {
                        addedItems.length != 0 ?
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                                <Table
                                    data={addedItems}
                                    sticky={true}
                                    columns={columns}
                                    showHeader={false}
                                    emptyText="Nenhuma ação disponível"
                                />
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6rem' }}>
                                    <div className={styles.tableRow}>
                                        <div style={{ width: '100%' }}>Total:</div>
                                        <div style={{ width: '38%' }}>R$ {totalCost()}</div>
                                    </div>
                                    <div className={styles.tableRow}>
                                        <div style={{ width: '100%' }}>Estimativa inicial:</div>
                                        <div style={{ width: '38%' }}>R$ {moc.initialBudget}</div>
                                    </div>
                                </div>
                            </div>

                            :

                            null
                    }
                </div>






                <div style={{ width: '100%' }} className="inputContainer">
                    <Label htmlFor="">Comentários:</Label>
                    <textarea
                        value={comments}
                        onChange={(event) => {
                            setCommentsError('')
                            setComments(event.target.value)
                        }}
                        rows='6'
                    />
                    {commentsError ?
                        <div className="inputError">{commentsError}</div>
                        : null}
                </div>

                <div style={{ width: '100%' }} className="inputContainer">
                    <Label htmlFor="">Anexos:</Label>
                    <CapexFileUploader
                        addedFiles={addedFiles}
                        setAddedFiles={setAddedFiles}
                    />
                </div>

                <div style={{ width: '100%' }} className="inputContainer">
                    <Label htmlFor="">Aprovador CAPEX:</Label>
                    <AutoComplete
                        data={users}
                        value={capexApprover}
                        onChange={(value) => {
                            setCapexApproverError('')
                            handleCapexApprover(value)
                        }}
                    />
                    {capexApproverError ?
                        <div className="inputError">{capexApproverError}</div>
                        : null}
                </div>

                <div>
                    <Title>LOG de aprovações de CAPEX</Title>

                    <Table
                        sticky={true}
                        columns={columnsLog}
                        showHeader={true}
                        emptyText="Nenhum log disponível"
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => submitCapex()}>Submeter</Button>
                </div>
            </div>



        </div>
    )
}