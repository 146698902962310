
import React, { useEffect, useState } from "react";
import { api } from "../../services/api"
import Swal from "sweetalert2";
import moment from "moment";

import Modal from 'react-modal';
import { AutoComplete, SelectPicker } from "rsuite";
import { RiCloseFill } from 'react-icons/ri';
import Label from "../Label/label";
import Button from "../Button";

import styles from "./style.module.css";
import Spinner from "../spinner";
const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


export default function ModalActions(props) {

  const [actionTitle, setActionTitle] = useState('')
  const [responsible, setResponsible] = useState('')
  const [responsibleId, setResponsibleId] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [selectedStage, setSelectedStage] = useState(null)
  const [checklistSelected, setChecklistSelected] = useState('')
  const [questionSelectedId, setQuestionSelectedId] = useState('')

  const [actionTitleError, setActionTitleError] = useState('')
  const [responsibleError, setResponsibleError] = useState(null)
  const [expirationDateError, setExpirationDateError] = useState('')
  const [addStageError, setAddStageError] = useState('')
  const [checklistError, setChecklistError] = useState('')
  const [questionError, setQuestionError] = useState('')
  const [isLoadingSubmitAction, setIsLoadingSubmitAction] = useState(false)

  const [questionPriority, setQuestionPriority] = useState('')
  const [questionId, setQuestionId] = useState('')


  const [userData, setUserData] = useState([])
  const [users, setUsers] = useState([])


  const [checklistData, setChecklistData] = useState([])
  const [checklists, setChecklist] = useState([])

  const [questionData, setQuestionData] = useState([])
  const [questions, setQuestions] = useState([])


  function handleCloseActionModal() {
    setActionTitle('')
    setResponsible('')
    setResponsibleId('')
    setExpirationDate('')
    setSelectedStage('')
    setChecklistSelected('')
    setQuestionSelectedId('')
    setActionTitleError([])
    setResponsibleError([])
    setExpirationDateError([])
    setAddStageError([])
    setIsLoadingSubmitAction([])
    setQuestionPriority([])
    setQuestionId('')
    setUserData([])
    setUsers([])
    setChecklistData([])
    setChecklist([])
    setQuestionError([])
    setChecklistError([])
    setQuestionData([])
    setQuestions([])
    props.onRequestClose(false)
  }


  useEffect(async () => {
    await getDataChecklists()
  }, [props.open])

  async function getDataChecklists() {
    const responseUsers = await api.get('users')
    const userArray = []
    responseUsers.data.map(user => {
      userArray.push({ label: user.name, value: user.name })
    })

    setUsers(userArray)
    setUserData(responseUsers.data)


    const responseChecklist = await api.get('checklists')
    const checklistArray = []
    responseChecklist.data.map(user => {
      checklistArray.push({ label: user.name, value: user.name })
    })

    setChecklist(checklistArray)
    setChecklistData(responseChecklist.data)

  }

  function handleResponsible(value) {
    const filtered = userData.filter(x => x.name == value)

    if (filtered.length != 0) {
      setResponsibleId(filtered[0].id)
    }

    setResponsible(value)
  }

  async function handleChecklist(value) {
    const filtered = checklistData.filter(x => x.name.includes(value));
    setChecklistSelected(value)
    if (filtered[0]) {
      const responseQuestions = await api.get(`questions/${filtered[0].id}?isActive=true`)
      const questionsArray = []
      responseQuestions.data.map(question => {
        questionsArray.push({ label: question.name, value: question.name, priority: question.priority })
      })
      setQuestions(questionsArray)
      setQuestionData(responseQuestions.data)
    }
  }

  async function handleQuestion(value) {
    const filtered = questionData.filter(x => x.name.includes(value))
    setQuestionSelectedId(value)
    setQuestionId(filtered[0].id)
    setQuestionPriority(filtered[0].priority)
  }

  let stages

  if (props.stageId == 1) {
    stages = [
      { value: 'Pré-Projeto', label: 'Pré-Projeto' },
      { value: 'Detalhamento', label: 'Detalhamento' },
      { value: 'Comissionamento', label: 'Comissionamento' },
    ]
  }

  if (props.stageId == 2) {
    stages = [
      { value: 'Detalhamento', label: 'Detalhamento' },
      { value: 'Comissionamento', label: 'Comissionamento' },
    ]
  }

  if (props.stageId == 3) {
    stages = [
      { value: 'Comissionamento', label: 'Comissionamento' },
    ]
  }


  function modalValidation() {
    let validation = true

    if (actionTitle.trim() === '') {
      validation = false
      setActionTitleError('Digite um título para a ação')
    }

    if (questionId === '') {
      validation = false
      setQuestionError('Selecione uma questão válida.')
    }

    if (checklistSelected === '') {
      validation = false
      setChecklistError('Selecione um checklist válido.')
    }


    if (!responsibleId) {
      validation = false
      setResponsibleError('Selecione um usuário válido')
    }

    if (expirationDate.trim() === '') {
      validation = false
      setExpirationDateError('Digite uma data')
    }

    if (moment(expirationDate).isBefore()) {
      validation = false
      setExpirationDateError('Digite uma data futura')
    }

    if (props.stageId != 4) {
      if (selectedStage == null) {
        setAddStageError('Selecione em qual etapa a ação se encerra.')
        validation = false
      }
    }


    return validation
  }

  async function submitAction() {
    if (modalValidation()) {
      try {
        setIsLoadingSubmitAction(true)

        let stage

        if (selectedStage == 'Pré-Projeto') {
          stage = 1
        }

        if (selectedStage == 'Detalhamento') {
          stage = 2
        }

        if (selectedStage == 'Comissionamento') {
          stage = 3
        }

        if (props.stageId == 4) {
          stage = 4
        }

        let response = await api.post('actions', {
          "name": actionTitle,
          "responsible": responsibleId,
          "validity": expirationDate,
          "stageId": stage,
          "mocId": props.id,
          "status": 'Pendente',
          "priority": questionPriority,
          "questionId": questionId,
          "checklistName": checklistSelected
        }
        )
        if (response.status === 200) {
          Toast.fire({
            icon: 'success',
            title: 'Ação criada com sucesso'
          })

          setActionTitle('')
          setResponsible('')
          setResponsibleId('')
          setExpirationDate('')
          setSelectedStage('')
          setChecklistSelected('')
          setQuestionSelectedId('')
          setActionTitleError([])
          setResponsibleError([])
          setExpirationDateError([])
          setAddStageError([])
          setIsLoadingSubmitAction([])
          setQuestionPriority([])
          setQuestionId('')
          setUserData([])
          setUsers([])
          setQuestionError([])
          setChecklistError([])
          setChecklistData([])
          setChecklist([])
          setQuestionData([])
          setQuestions([])

          handleCloseActionModal()
          await props.func()
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: `${error.response.data.errors[0].msg}`
        })
      }
      finally {
        setIsLoadingSubmitAction(false)
      }
    }
  }

  return (
    <>

      <Modal isOpen={props.open} onRequestClose={handleCloseActionModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(44, 42, 42, 0.707)',
          },
          content: {
            position: 'absolute',
            width: '50rem',
            height: '25rem',
            top: '50%',
            transform: 'translateY(-50%)',
            marginLeft: 'auto',
            marginRight: 'auto',
            border: 'none',
            background: '#fff',
            overflow: 'auto',
            borderRadius: 'none',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: 'none',
          }
        }}>
        {isLoadingSubmitAction == true ? (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div></div>)}

        <div className="headerModal">
          <h3>Adicionar ação</h3>
          <h2 onClick={handleCloseActionModal}><RiCloseFill /></h2>
        </div>


        <div style={{ padding: '2rem 2rem 2rem 2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div style={{ width: '100%' }} className="inputContainer">
              <Label htmlFor="">Ação:</Label>
              <input type="text"
                value={actionTitle}
                onChange={(event) => {
                  setActionTitleError('')
                  setActionTitle(event.target.value)
                }}
              />
              {actionTitleError ?
                <div className="inputError">{actionTitleError}</div>
                : null}
            </div>
            <div style={{ display: 'flex', gap: '1rem', }}>
              <div style={{ width: '100%' }} className="inputContainer">
                <Label htmlFor="">Checklist:</Label>
                <AutoComplete
                  style={{ width: '100%' }}
                  data={checklists}
                  value={checklistSelected}
                  onChange={(label, value) => {
                    setChecklistError('')
                    handleChecklist(label)
                  }}
                />
                {checklistError ?
                  <div className="inputError">{checklistError}</div>
                  : null}
              </div>

              <div style={{ width: '100%' }} className="inputContainer">
                <Label htmlFor="">Questão:</Label>
                <AutoComplete
                  style={{ width: '100%' }}
                  data={questions}
                  value={questionSelectedId}
                  onChange={(label, value) => {
                    setQuestionError('')
                    handleQuestion(label)
                  }}
                />
                {questionError ?
                  <div className="inputError">{questionError}</div>
                  : null}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '1rem', }}>
              <div style={{ width: '100%' }} className="inputContainer">
                <Label htmlFor="">Responsável:</Label>
                <AutoComplete
                  style={{ width: '100%' }}
                  data={users}
                  value={responsible}
                  onChange={(label, value) => {
                    setResponsibleError('')
                    setResponsibleId('')
                    handleResponsible(label)
                  }}
                />
                {responsibleError ?
                  <div className="inputError">{responsibleError}</div>
                  : null}
              </div>

              <div style={{ width: '100%' }} className="inputContainer">
                <Label htmlFor="">Validade:</Label>
                <input type="date"
                  value={expirationDate}
                  onChange={(event) => {
                    setExpirationDateError('')
                    setExpirationDate(event.target.value)
                  }}
                />
                {expirationDateError ?
                  <div className="inputError">{expirationDateError}</div>
                  : null}
              </div>

              {props.stageId != 4 &&
                <div style={{ width: '100%' }} className="inputContainer">
                  <Label htmlFor="">Ação encerra em:</Label>
                  <SelectPicker
                    appearance="default"
                    style={{ width: '100%' }}
                    placeholder="&nbsp;"
                    data={stages}
                    value={selectedStage}
                    onChange={(value) => {
                      setAddStageError('')
                      setSelectedStage(value)
                    }}
                    searchable={false}
                    cleanable={false}
                  />
                  {addStageError ?
                    <div className="inputError">{addStageError}</div>
                    : null}
                </div>
              }

            </div>
          </div>


        </div>
        <div className={isLoadingSubmitAction ? styles.disable : ''} style={{ position: 'absolute', bottom: '1rem', right: '2rem' }}>
          <Button onClick={() => submitAction()}>Submeter</Button>
        </div>
      </Modal>
    </>
  )
}
