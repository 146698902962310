import { useState, useEffect } from 'react'

import styles from './checklists.module.css'

import checklistsIconBlue from '../../../assets/configuration/checklistsBlue.svg'
import TitleConfigs from '../../../components/TitleConfigs'
import ButtonPlus from '../../../components/ButtonPlus'
import { RiCloseFill } from 'react-icons/ri';
import Table from 'rc-table'
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from "react-icons/fa";

import Modal from 'react-modal'
import Label from '../../../components/Label/label'
import { CheckboxGroup, Radio, RadioGroup, SelectPicker } from 'rsuite'
import AddButton from '../../../components/addButton'
import Button from '../../../components/Button'
import { api } from '../../../services/api'
import Swal from 'sweetalert2'

export default function Checklists() {
    const [modalOpen, setModalOpen] = useState(false)

    const [checklists, setChecklists] = useState([])
    const [questions, setQuestions] = useState([])

    const [checklist, setChecklist] = useState('')
    const [question, setQuestion] = useState('')
    const [addedQuestions, setAddedQuestions] = useState([])

    const [selectedPriority, setSelectedPriority] = useState(null)

    const [selectedStep, setSelectedStep] = useState(null)

    const [checks, setChecks] = useState(false)

    const [selectButton, setSelectButton] = useState(null)

    const [preProjectType, setPreProjectType] = useState('Simplificado')

    const [questionError, setQuestionError] = useState('')
    const [priorityError, setPriorityError] = useState('')
    const [checklistTitleError, setChecklistTitleError] = useState('')
    const [stepError, setStepError] = useState('')
    const [selectButtonError, setSelectButonError] = useState(null)


    const [editId, setEditId] = useState('Id');
    const [inEdit, setInEdit] = useState([]);
    const [nameEdit, setNameEdit] = useState('');
    const [nameEditBefore, setNameEditBefore] = useState('');

    useEffect(async () => {
        await getData()
    }, [inEdit])

    async function getData() {
        let responseChecklists = await api.get('checklists')
        setChecklists(responseChecklists.data)

        let responseQuestions = await api.get('questions')
        setQuestions(responseQuestions.data)
    }


    const prioridades = [
        { label: "Baixa", value: "Baixa" },
        { label: "Média", value: "Média" },
        { label: "Alta", value: "Alta" }
    ]

    const etapas = [
        { label: "Pré-Projeto", value: "Pré-Projeto" },
        { label: "Detalhamento", value: "Detalhamento" },
        { label: "Comissionamento", value: "Comissionamento" }
    ]

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    function handleCloseModal() {
        setModalOpen(false)
        cleanStates()
    }

    const columns = [
        {
            title: "Checklist",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 275,
            render: (row) => (<div>{row.name}</div>)
        },
        {
            title: "Número de perguntas",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 550,
            render: (row) => {
                let numberOfQuestions = questions.filter(x => x.checklistId === row.id).length

                return (
                    <div>{numberOfQuestions}</div>
                )

            }
        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "right",
            width: 5,
            render: (value, row, id) => {
                return (
                    <div className='iconTableDiv'>
                        <img className="actionBtn" onClick={(event) => onEdit(row.id)} style={{ width: '1rem', height: '1rem', cursor: 'pointer' }} src={editIcon} alt='' />
                        <img className="actionBtn" onClick={() => handleRemoveChecklist(row.id)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>)
            }
        },
    ]

    const [previousAddedQuestions, setPreviousAddedQuestions] = useState([])
    const [previousChecklist, setPreviousChecklist] = useState('')
    const [previousStage, setPreviousStage] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [checklistId, setChecklistId] = useState('')


    async function onEditQuestion(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        for (const input of tdInputs) {
            input.disabled = false
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId(id)

        api.get(`question/${id}`).then(response => {

            setNameEdit(response.data.name)
            setNameEditBefore(response.data.name)
        })
    }


    async function submitEditQuestion(evt, question) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        if (nameEdit.trim() === '') {
            Toast.fire({
                icon: 'error',
                title: "Preencha o campo editável",
            })
            return false
        }

        if (nameEditBefore == nameEdit) {
            onCancelEdit(evt)
            return
        }

        try {
            Swal.fire({
                title: 'Deseja editar essa pergunta?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                cancelButtonColor: "#636e72",
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await api.put(`/questions/${question.id}`,
                        {
                            "name": nameEdit,
                            "priority": question.priority
                        })

                    if (response.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: "Pergunta atualizada!",
                        })
                        for (const input of tdInputs) {
                            input.disabled = true
                        }

                        for (const btn of tdActionButtons) {
                            btn.classList.toggle("displayNone")
                        }
                        setEditId('Id')
                        setInEdit({ name: '' })

                        const responseQuestions = await api.get(
                            `questions/${checklistId}?isActive=true`
                        )

                        const arrayQuestions = responseQuestions.data.map(question => ({
                            question: question.name,
                            priority: question.priority,
                            id: question.id
                        }));

                        setAddedQuestions(arrayQuestions);
                        setPreviousAddedQuestions(arrayQuestions)
                    }
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }

    async function onEdit(id) {
        setChecklistId(id)

        setIsEdit(true)
        setModalOpen(true)

        let response = await api.get(`checklists/${id}`)

        setChecklist(response.data.name)
        setPreviousChecklist(response.data.name)
        setPreviousStage({ name: response.data.Stages.name, id: response.data.Stages.id })

        if (response.data.Stages.id == 4) {
            setSelectButton('Simplificado')
        } else {
            setSelectButton('Completo')

            setSelectedStep(response.data.Stages.name)
        }

        const responseQuestions = await api.get(`questions/${id}?isActive=true`)

        let arrayQuestions = []

        responseQuestions.data.map(question => {
            arrayQuestions.push({ question: question.name, priority: question.priority, id: question.id })
        })

        setAddedQuestions(arrayQuestions)
        setPreviousAddedQuestions(arrayQuestions)
    }

    function isSameQuestions() {
        let validation = true

        addedQuestions.map(question => {
            if (previousAddedQuestions.filter(x => x.id == question.id).length == 0) {
                validation = false
            }
        })

        previousAddedQuestions.map(question => {
            if (addedQuestions.filter(x => x.id == question.id).length == 0) {
                validation = false
            }
        })

        return validation
    }

    function isSameChecklist() {
        let validation = true

        if (selectButton == 'Simplificado') {
            if (previousStage.id != 4) {
                validation = false
            }
        }

        if (selectButton == 'Completo') {
            if (previousStage.name != selectedStep) {
                validation = false
            }
        }

        if (previousChecklist != checklist) {
            validation = false
        }

        return validation
    }

    async function submitEdit() {
        if (validationSubmit()) {
            try {
                if (isSameChecklist() && isSameQuestions()) {
                    handleCloseModal()
                    return
                }

                let stageId

                if (selectedStep == "Pré-Projeto") {
                    stageId = 1
                }

                if (selectedStep == "Detalhamento") {
                    stageId = 2
                }

                if (selectedStep == "Comissionamento") {
                    stageId = 3
                }

                if (selectButton == "Simplificado") {
                    stageId = 4
                }

                if (!isSameChecklist()) {
                    let responseChecklist = await api.put(`checklists/${checklistId}`, {
                        "name": checklist,
                        "stageId": stageId
                    })
                }

                if (!isSameQuestions()) {
                    addedQuestions.map(async (question) => {
                        if (previousAddedQuestions.filter(x => x.id == question.id).length == 0) {
                            let responseQuestion = await api.post('/questions', {
                                "name": question.question,
                                "priority": question.priority,
                                "checklistId": checklistId
                            })
                        }
                    })

                    previousAddedQuestions.map(async (question) => {
                        if (addedQuestions.filter(x => x.id == question.id).length == 0) {
                            let responseQuestion = await api.delete(`questions/${question.id}`)
                        }
                    })
                }

                Swal.fire({
                    title: 'Checklist editado',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: "#009EBE",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        handleCloseModal()
                        await getData()
                    }
                })


            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    text: `${error.response.data.errors[0].msg}`
                })
            }
        }
    }


    async function handleRemoveChecklist(id) {
        Swal.fire({
            title: 'Deletar checklist?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#009EBE",
            cancelButtonColor: "#636e72",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let responseChecklist = await api.delete(`checklists/${id}`)
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.response.data.errors[0].msg}`
                    })
                }

                await getData()
            }
        })
    }

    const columnsQuestions = [
        {
            title: "",
            dataIndex: "question",
            key: "file",
            align: "left",
            width: 1000,
            render: (value, row) => Number(editId) === Number(row.id) ? <input className='inputEdit' style={{ margin: "-0.27rem", boxShadow: "none" }} type="text" value={nameEdit} onChange={event => setNameEdit(event.target.value)} /> : <p>{value}</p>

        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 50,
            render: (value, row) => (<div className='iconTableDiv'>
                <FaCheck className="actionBtn displayNone" onClick={(event) => submitEditQuestion(event, row)} style={{ cursor: "pointer", width: '0.9rem', height: '0.9rem' }} />
                <FaTimes className="actionBtn displayNone" onClick={onCancelEdit} style={{ cursor: "pointer", width: '0.9rem', height: '0.9rem' }} />
                <img className="actionBtn" onClick={(event) => onEditQuestion(event, row.id)} style={{ width: '0.9rem', height: '0.9rem', cursor: 'pointer' }} src={editIcon} alt='' />
                <img className="actionBtn" onClick={() => handleRemoveQuestion(row.question)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>),

        }
    ]

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId('Id')
        setInEdit({ name: '' })

    }


    function handleRemoveQuestion(el) {
        let newAddedQuestion = addedQuestions.filter(x => x.question != el)
        setAddedQuestions(newAddedQuestion)
    }

    function validation() {
        let validation = true

        if (question === '') {
            validation = false
            setQuestionError('Digite uma pergunta')
        }

        if (addedQuestions.filter(x => x.question === question).length != 0) {
            validation = false
            setQuestionError('Pergunta já adicionada')
        }
        if (selectedPriority == null) {
            validation = false
            setPriorityError('Selecione uma prioridade')
        }

        return validation
    }

    function validationSubmit() {
        let validation = true

        if (addedQuestions.length == 0) {
            setQuestionError('Adicione pelo menos uma pergunta')
            validation = false
        }

        if (checklist.trim() == '') {
            setChecklistTitleError('Digite um nome para o checklist')
            validation = false
        }

        if (selectButton == null) {
            validation = false
            setSelectButonError('Selecione uma etapa')
        }

        if (selectButton != 'Simplificado' && selectedStep == null) {
            validation = false
            setStepError('Selecione uma etapa')
        }

        return validation
    }

    function cleanStates() {
        setQuestion('')
        setChecklist('')
        setAddedQuestions([])
        setSelectedPriority(null)
        setChecks(false)
        setPreProjectType('Simplificado')
        setSelectedStep(null)
        setSelectButton(null)

        setChecklistTitleError('')
        setQuestionError('')
        setPriorityError('')
        setSelectButonError('')
        setStepError('')
    }

    function handleAddQuestion() {
        if (validation()) {
            let arrayQuestions = [...addedQuestions]

            arrayQuestions.push({ question: question, priority: selectedPriority })

            setAddedQuestions(arrayQuestions)
            setQuestion('')
            setSelectedPriority(null)
        }
    }

    function handleStepChange(value) {
        setSelectedStep(value)
    }

    async function submit() {
        if (validationSubmit()) {
            try {
                let stageId

                if (selectButton == "Simplificado") {
                    stageId = 4
                }

                if (selectedStep == "Pré-Projeto") {
                    stageId = 1
                }

                if (selectedStep == "Detalhamento") {
                    stageId = 2
                }

                if (selectedStep == "Comissionamento") {
                    stageId = 3
                }

                let responseChecklist = await api.post('checklists', {
                    name: checklist,
                    stageId: stageId
                })

                if (responseChecklist.status == 200) {

                    addedQuestions.map(async x => {
                        let responseQuestions = await api.post('questions', {
                            name: x.question,
                            priority: x.priority,
                            checklistId: responseChecklist.data.id
                        })
                    })
                }

                Swal.fire({
                    title: 'Checklist criado com sucesso',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: "#009EBE",
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        setModalOpen(false)
                        cleanStates()
                        getData()
                    }
                })

            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: `${error.response.data.errors[0].msg}`
                })
            }
        }
    }


    return (
        <>
            <TitleConfigs titulo='Checklists' icon={checklistsIconBlue} tamanho={{ width: "1.3rem" }} />

            <div className={styles.mainDiv}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonPlus onClick={() => {
                        setIsEdit(false)
                        setModalOpen(true)
                    }}
                        titulo='Novo Checklist' />
                </div>

                <Table
                    sticky={true}
                    columns={columns}
                    data={checklists}
                    showHeader={true}
                    emptyText="Nenhum checklist cadastrado"
                />
            </div>

            <Modal isOpen={modalOpen} onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(44, 42, 42, 0.707)'
                    },
                    content: {
                        position: 'absolute',
                        width: '65rem',
                        height: '55rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: 'none',
                        background: '#fff',
                        overflow: 'hidden',
                        borderRadius: 'none',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        padding: 'none',
                    }
                }}>
                <div className="headerModal">
                    <h3>Novo checklist</h3>
                    <h2 onClick={handleCloseModal}><RiCloseFill /></h2>
                </div>


                <div className="modalContainer">
                    <div className='modalFirstDiv'>

                        <div style={{ width: '100%' }} className="inputContainer">
                            <Label htmlFor="">Título do checklist</Label>
                            <input value={checklist} onChange={(event) => {
                                setChecklistTitleError('')
                                setChecklist(event.target.value)
                            }}
                                type="text" />
                            {checklistTitleError ? (
                                <div className="inputError">{checklistTitleError}</div>
                            ) : null}
                        </div>
                        <div style={{ width: '100%', marginTop: '1%', fontSize: '0.85rem' }} className="inputContainer">
                            <RadioGroup value={selectButton}
                                onChange={(value) => {
                                    setSelectButonError('')
                                    setSelectButton(value)
                                }}
                                name="radioList" inline>
                                <Radio value={'Simplificado'}>Simplificado</Radio>
                                <Radio value={'Completo'}>Completo</Radio>
                            </RadioGroup>
                            {selectButtonError ? (
                                <div className="inputError">{selectButtonError}</div>
                            ) : null}
                        </div>

                        {selectButton == "Completo" ?
                            <div style={{ width: '100%', marginTop: '1%' }} className="inputContainer">
                                <Label htmlFor="">Etapa</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    placeholder="&nbsp;"
                                    searchable={false}
                                    data={etapas}
                                    value={selectedStep}
                                    cleanable={false}
                                    onChange={(value) => {
                                        setStepError('')
                                        handleStepChange(value)
                                    }}
                                />
                                {stepError ? (
                                    <div className="inputError">{stepError}</div>
                                ) : null}
                            </div>
                            : null}

                        <div style={{ display: 'flex', gap: '0.5rem', marginTop: '1%' }}>
                            <div style={{ width: '100%' }} className="inputContainer">
                                <Label htmlFor="">Pergunta</Label>
                                <input value={question} onChange={(event) => {
                                    setQuestionError('')
                                    setQuestion(event.target.value)
                                }}
                                    type="text" />
                                {questionError ? (
                                    <div className="inputError">{questionError}</div>
                                ) : null}
                            </div>
                            <div style={{ width: '50%' }} className="inputContainer">
                                <Label htmlFor="">Prioridade</Label>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                                    <SelectPicker
                                        appearance="default"
                                        style={{ width: '100%' }}
                                        placeholder="&nbsp;"
                                        searchable={false}
                                        data={prioridades}
                                        value={selectedPriority}
                                        onChange={(value) => {
                                            setPriorityError('')
                                            setSelectedPriority(value)
                                        }}
                                    />
                                    <AddButton onClick={() => handleAddQuestion()} />
                                </div>
                                {priorityError ? (
                                    <div className="inputError">{priorityError}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {addedQuestions.length != 0 ?
                        <div className="modalTableOverflowDiv">
                            <Table
                                sticky={true}
                                columns={columnsQuestions}
                                data={addedQuestions}
                                showHeader={false}
                                emptyText=""
                            />
                        </div>
                        : null
                    }

                    <div className='modalFooter'>
                        <Button onClick={isEdit ? () => submitEdit() : () => submit()}>Ok</Button>
                    </div>


                </div>

            </Modal>
        </>
    )
}