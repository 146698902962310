
import React, { useEffect, useState } from "react";
import { api, addAttachment } from "../../services/api"
import Swal from "sweetalert2";
import moment from "moment";

import Modal from 'react-modal';
import { RiCloseFill } from 'react-icons/ri';
import Label from "../Label/label";
import Button from "../Button";

import styles from "./style.module.css";
import Spinner from "../spinner";
import FileUploader from "../fileUploaderEdit";
const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


export default function ModalAttachAction(props) {

  const [actionAttach, setActionAttach] = useState([])
  const [comment, setComment] = useState('')

  const [isLoadingSubmitAction, setIsLoadingSubmitAction] = useState(false)
  const [previousAddedFiles, setPreviousAddedFiles] = useState([])

  function handleCloseActionModal() {
    props.onRequestClose(false)
    setActionAttach([])
    setComment('')
  }

  async function getData() {
    setIsLoadingSubmitAction(true)

    const responseCommentAction = await api.get(`/actions/${props.id}`)
    setComment(responseCommentAction.data.comment)

    let responseAddedItens = await api.get(`/actionsAttachments/${props.id}`)
    setPreviousAddedFiles(responseAddedItens.data)
    setActionAttach(responseAddedItens.data)
    setIsLoadingSubmitAction(false)

  }

  async function handleSubmit() {
    setIsLoadingSubmitAction(true)
    if (actionAttach.length != 0) {
      actionAttach.map(async (anexo) => {
        await addAttachment('actionsAttachments/upload', {
          "actionId": props.id,
          "file": anexo
        })
      })
    }
    previousAddedFiles.map(async previousFile => {
      if (actionAttach.filter(x => x.id == previousFile.id).length == 0) {
        await api.delete(`/actionsAttachments/${previousFile.id}`)
      }
    })
    if (comment) {
      await api.put(`/actionsComment/${props.id}`,
        {
          "comment": comment,
        })
    }
    Swal.fire({
      title: "Evidências adicionadas com sucesso!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#009EBE",
      confirmButtonText: "Ok",
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRequestClose(false)
        setActionAttach([])
        setComment('')
      }
    })
    setIsLoadingSubmitAction(false)
  }

  useEffect(async () => {
    setComment('')
    await getData()
    props.setLoading(false)
  }, [props.open])

  useEffect(() => {
    return () => {
      setActionAttach([])
      setComment('')
    };
  }, []); 

  return (
    <>

      <Modal isOpen={props.open} onRequestClose={handleCloseActionModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(44, 42, 42, 0.707)',
          },
          content: {
            position: 'absolute',
            width: '50%',
            height:'90%',
            top: '50%',
            transform: 'translateY(-50%)',
            marginLeft: 'auto',
            marginRight: 'auto',
            border: 'none',
            background: '#fff',
            overflow: 'auto',
            borderRadius: 'none',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: 'none',
          }
        }}>
        {isLoadingSubmitAction == true ? (
          <div className="spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div></div>)}

        <div className="headerModal">
          <h3>Adicionar Evidências </h3>
          <h2 onClick={handleCloseActionModal}><RiCloseFill /></h2>
        </div>

      <div className={styles.MainDiv}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Label>Comentário</Label>
            <textarea onChange={(e) => setComment(e.target.value)} value={comment} className={styles.attachTextArea}></textarea>
            <Label>Anexos</Label>
            <div className={styles.attachmentsDiv}>

              <div className={styles.pdfDiv}>
                <FileUploader
                  type="pdf"
                  isOptional={true}
                  addedFiles={actionAttach}
                  setAddedFiles={setActionAttach}
                />
              </div>
            </div>
          </div>
        
        </div>
        <div className={isLoadingSubmitAction ? styles.disable : styles.buttonSubmit}>
            <Button onClick={() => handleSubmit()}>Submeter</Button>
          </div>
          </div>
      </Modal>
    </>
  )
}
