import { useEffect, useState } from "react"
import { api } from "../../../services/api"

import Title from "../../Title/title"

import { IoIosArrowUp } from "react-icons/io"
import { IoIosArrowDown } from "react-icons/io"

import styles from './hseApproval.module.css'
import Label from "../../Label/label"
import { Checkbox, Radio, RadioGroup, SelectPicker } from "rsuite"

export default function HseApproval({ id, activeToggle, disableOpacity }) {
    const [activeHseApproval, setActiveHseApproval] = useState(false)

    const [aprovar, setAprovar] = useState(true)
    const [justificativa, setJustificativa] = useState('')
    const [modeloMoc, setModeloMoc] = useState('')
    const [participar, setParticipar] = useState(false)

    useEffect(() => {
        if (activeToggle) {
            setActiveHseApproval(true)
        }
    }, [activeToggle])

    useEffect(async () => {
        await getData()
    })
    async function getData() {
        let responseApprovals = await api.get(`/approval/moc/${id}`)
        setAprovar(responseApprovals.data.isApproved)
        setJustificativa(responseApprovals.data.justification)
        setParticipar(responseApprovals.data.support)

        let responseMoc = await api.get(`moc/${id}`)
        setModeloMoc(responseMoc.data.modelMoc)
    }

    const selectData = [
        { label: 'Completo', value: "Completo" },
        { label: 'Simplificado', value: "Simplificado" }
    ]


    return (
        <>
            <div className={styles.basicInfoDiv}>

                <div className={styles.basicInfoTitle}>
                    <Title>Aprovação de HSE</Title>

                    <div onClick={() => {
                        setActiveHseApproval(prevActiveHseApproval => !prevActiveHseApproval)
                    }

                    } style={{ color: '#009EBE', fontSize: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        {activeHseApproval == false ? <IoIosArrowDown /> : <IoIosArrowUp />}
                    </div>
                </div>

                {activeHseApproval ?
                    <div className={`${styles.disableDiv} ${disableOpacity ? '' : styles.opacity}`} style={{ borderTop: '1px solid #009EBE', marginTop: '0.25rem', paddingBottom: '1rem' }}>
                        <div style={{ width: '35rem' }} className={styles.formDiv}>
                            <div>
                                <Label>Aprova a criação do MOC?</Label>
                                <RadioGroup name="radioList" inline value={aprovar}>
                                    <Radio value={true}>Aprovar</Radio>
                                    <Radio value={false}>Rejeitar</Radio>
                                </RadioGroup>
                            </div>

                            <div style={{ width: '30%' }} className="inputContainer">
                                <Label htmlFor="">Modelo do MOC</Label>
                                <SelectPicker
                                    appearance="default"
                                    style={{ width: '100%' }}
                                    placeholder="&nbsp;"
                                    data={selectData}
                                    value={modeloMoc}
                                />
                            </div>
                        </div>

                        <div className={styles.formDiv}>
                            <div className="inputContainer" style={{ width: '100%' }}>
                                <Label>Justificativas/Comentários</Label>
                                <textarea name="" id="" cols="30" rows="5" value={justificativa}></textarea>
                            </div>
                        </div>

                        <Checkbox
                            value={participar}
                        >Desejo participar do preenchimento dos checklists e plano de ações</Checkbox>
                    </div>

                    : null
                }
            </div>
        </>
    )
}