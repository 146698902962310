import { useState, useEffect } from 'react'

import TitleConfigs from '../../../components/TitleConfigs'
import styles from './types.module.css'

import typeIconBlue from '../../../assets/configuration/tiposBlue.svg'
import Label from '../../../components/Label/label'
import AddButton from '../../../components/addButton'
import { api } from '../../../services/api'
import Swal from "sweetalert2";
import Table from 'rc-table'
import deleteIcon from '../../../assets/delete.svg'
import editIcon from '../../../assets/edit.svg'
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from "react-icons/fa";


export default function Types({mocs}) {
    const [type, setType] = useState('')
    const [types, setTypes] = useState([])

    const [typeError, setTypeError] = useState('')

    useEffect(async () => {
        await getData()
    }, [])

    async function getData() {
        let response = await api.get('/types')
        setTypes(response.data)
    }



    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const columns = [
        {
            title: "",
            dataIndex: "name",
            key: "file",
            align: "left",
            width: 1000,
            render: (value, row) => Number(editId) === Number(row.id) ? <input className='inputEdit' style={{ margin: "-0.27rem", boxShadow: "none" }} type="text" value={typeNameEdit} onChange={event => setTypeNameEdit(event.target.value)} /> : <p>{value}</p>
        },
        {
            title: "",
            dataIndex: "",
            key: "file",
            align: "left",
            width: 50,
            render: (value, row) => (<div className='iconTableDiv'>
                <FaCheck className="actionBtn displayNone" onClick={(event) => submitEdit(event, row.id)} style={{ cursor: "pointer", width:'0.9rem', height:'0.9rem' }} />
                <FaTimes className="actionBtn displayNone" onClick={onCancelEdit} style={{ cursor: "pointer", width:'0.9rem', height:'0.9rem' }} />
                <img className="actionBtn" onClick={(event) => onEdit(event, row.id)} style={{ width: '0.9rem', height: '0.9rem', cursor: 'pointer' }} src={editIcon} alt='' />
                <img className="actionBtn" onClick={() => handleRemoveType(row.id)} style={{ width: '1.2rem', height: '1.2rem', cursor: 'pointer' }} src={deleteIcon} alt='' /></div>),
        },
    ]


    const [editId, setEditId] = useState('Id');
    const [inEdit, setInEdit] = useState([]);
    const [typeNameEdit, setTypeNameEdit] = useState('');
    const [typeNameEditBefore, setTypeNameEditBefore] = useState('');

    async function onEdit(evt, id) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        for (const input of tdInputs) {
            input.disabled = false
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId(id)

        api.get(`types/${id}`).then(response => {

            setTypeNameEdit(response.data.name)
            setTypeNameEditBefore(response.data.name)
        })
    }

    function onCancelEdit(evt) {
        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')

        for (const input of tdInputs) {
            input.disabled = true
        }

        for (const btn of tdActionButtons) {
            btn.classList.toggle("displayNone")
        }

        setEditId('Id')
        setInEdit({ name: '' })
    }

    async function submitEdit(evt, id) {

        evt.preventDefault();
        const element = evt.target.closest('tr')
        const tdInputs = element.getElementsByTagName('input')
        const tdActionButtons = element.getElementsByClassName('actionBtn')


        if (typeNameEdit.trim() === '') {
            Toast.fire({
                icon: 'error',
                title: "Preencha o campo editável",
            })
            return false
        }

        if (typeNameEditBefore == typeNameEdit) {
            onCancelEdit(evt)
            return
        }

        try {

            let response = await api.put(`/types/${id}`,
                {
                    "name": typeNameEdit,
                })

            if (response.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: "Registro atualizado!",
                })
                for (const input of tdInputs) {
                    input.disabled = true
                }

                for (const btn of tdActionButtons) {
                    btn.classList.toggle("displayNone")
                }

                setEditId('Id')
                setInEdit({ name: '' })
                getData()
            } 
        } catch (error) {
            Toast.fire({
                icon: 'error',
                text: `${error.response.data.errors[0].msg}`
            })
        }
    }

    async function handleRemoveType(id) {
        const isTypeInUse = mocs.some(({Types})=>Types.id === id);
        if(isTypeInUse){
            Toast.fire({
                icon: 'error',
                text: 'Não é possível excluir, pois existem MOCs registrados associados a este tipo.',
                timer: 5000
            })
            return;
        }
        try {
            Swal.fire({
                title: 'Deletar tipo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#009EBE",
                cancelButtonColor: "#636e72",
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await api.delete(`/types/${id}`)

                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Tipo deletado'
                        })
                        getData()
                    }
                }
            })
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data.errors[0].msg}`
            })
        }
    }


    function validation() {
        let validation = true
        if (type === '') {
            validation = false
            setTypeError('Digite um tipo')
        }

        return validation
    }

    async function addType() {
        if (validation()) {
            try {
                let response = await api.post('types', {
                    "name": type
                })

                if (response.status == 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Tipo cadastrado'
                    })

                    setType('')
                    getData()
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: `${error.response.data.errors[0].msg}`
                })
            }

        }


    }

    return (
        <>
            <TitleConfigs titulo='Tipos' icon={typeIconBlue} tamanho={{ width: "1.3rem" }} />

            <div className={styles.mainDiv}>
                <div style={{ width: '30rem' }} className="inputContainer">
                    <Label htmlFor="">Tipo</Label>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <input value={type} onChange={(event) => {
                            setTypeError('')
                            setType(event.target.value)
                        }}
                            type="text" />
                        <AddButton onClick={() => addType()} />
                    </div>
                    {typeError ? (
                        <div className="inputError">{typeError}</div>
                    ) : null}
                </div>
                <div style={{ width: '30rem' }}>
                    {types.length !== 0 ?

                        <Table
                            sticky={true}
                            columns={columns}
                            data={types}
                            showHeader={false}
                        />
                        :
                        null
                    }
                </div>
            </div>

        </>
    )
}