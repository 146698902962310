import { Route, Switch, useRouteMatch } from "react-router-dom";
import Header from "../../components/Header/header";
import Actions from "./my-actions-main";
import ActionsView from "./my-actions-view";


export default function ActionsHome() {
    let { path } = useRouteMatch();

    return (
        <>
            <Header />

            <div>
                <Switch>
                    <Route path={`${path}/`} exact component={Actions} />
                    <Route path={`${path}/responsavel`} exact component={ActionsView} />

                </Switch>
            </div>


        </>
    )
}