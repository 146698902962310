function handleValidation({
  titulo: [titulo, setTituloError],
  data: [data, setDataError],
  custo: [custo, setCustoError],
  fiscal: [fiscal, setFiscalError],
  site: [site, setSiteError],
  categoria: [categoria, setCategoriaError],
  tipo: [tipo, setTipoError],
  descricao: [descricao, setDescricaoError],
}) {
  let validation = true;

  if (titulo === "") {
    setTituloError("Digite um título");
    validation = false;
  }

  if (data === "") {
    setDataError("Selecione uma data");
    validation = false;
  }

  if (custo === "") {
    setCustoError("Digite um custo");
    validation = false;
  }

  if (fiscal === "") {
    setFiscalError("Digite um ano");
    validation = false;
  }

  if (site === "") {
    setSiteError("Selecione um site");
    validation = false;
  }

  if (categoria === "") {
    setCategoriaError("Selecione uma categoria");
    validation = false;
  }

  if (tipo === "") {
    setTipoError("Selecione um tipo");
    validation = false;
  }

  if (descricao === "") {
    setDescricaoError("Digite uma descrição");
    validation = false;
  }

  return validation;
}
export default handleValidation;
