import React, { useState } from "react";

import { RiCloseFill } from 'react-icons/ri'
import Modal from 'react-modal';

import { useEditMoc } from "../../providers/edit-moc";

export default function ViewPic(props) {

    const {isOpenModalPic, setIsOpenModalPic} = useEditMoc()
    const {uploadfotos} = useEditMoc()

    function handleCloseModal() {
        setIsOpenModalPic(false)
    }

    return (
        <>
            <Modal isOpen={isOpenModalPic} onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        // backgroundColor: 'rgba(44, 42, 42, 0.707)'
                    },
                    content: {
                        position: 'absolute',
                        width: 'fit-content',
                        height: 'fit-content',                       
                        top: '15%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: 'none',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '5px',
                        outline: 'none',
                        padding: 'none'
                    }
                }}>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <h2 onClick={handleCloseModal} style={{ position: 'absolute', margin: '1rem', fontSize: '150%', cursor: 'pointer' }}><RiCloseFill /></h2>
                </div>

                <img src={uploadfotos} style={{ maxHeight: '70vh',maxWidth: '80vw'}}/>
            </Modal>
        </>
    )
}