import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useState, useEffect } from 'react'

import Header from "../../components/Header/header";

import TableMoc from "./my-validations-main";
import SearchMoc from "./my-validations-view/index";

import { BsFillExclamationTriangleFill} from 'react-icons/bs';

import { api } from '../../services/api'

export default function MyValidations() {
  let { path } = useRouteMatch();
  const [isAdmin, setIsAdmin] = useState(true)
  const [isApprover, setIsApprover] = useState(true)

  async function getIdentify() { // identifica usuario logado e pega seus dados
      let response = await api.get('identify')
      setIsAdmin(response.data.isAdmin)
      setIsApprover(response.data.isApprover)
    
  }

  useEffect(async () => {
      await getIdentify()

  }, [])

  return (
    <>
      {isAdmin || isApprover?
      <>
      <Header />
      <div>
        <Switch>
          <Route path={`${path}/`} exact component={TableMoc} />
          <Route path={`${path}/moc`} exact component={SearchMoc} />
        </Switch>
      </div>
      </>:
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "2rem"}}>
            <BsFillExclamationTriangleFill color="red" size={30}/> <p style={{marginLeft: "1rem"}} > Acesso negado</p>
        </div>}
    </>
  )
}